import { Button } from "@mui/material";
import { CANCEL } from "../../constant/InputFormConstants";

interface Props {
    resetAll: any,
    setSelectedUploadSize: any,
    previousUploadSize: any
}

const CancelButton = (props: Props) => {
    return (
        <Button
            color="primary"
            variant="outlined"
            size="small"
            data-testid="CancelButton"
            sx={{
                color: "#006FA6",
                borderColor: "#006FA6",
                textTransform: "capitalize",
            }}
            onClick={() => {
                props.resetAll();
                props.setSelectedUploadSize(props.previousUploadSize);
            }}
        >
            {CANCEL}
        </Button>
    )
}

export default CancelButton