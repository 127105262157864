import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";

const PageIncrementButton = (props: any) => {
    const csvFileDataWithStatus = useSelector(
        (state: any) => state.updatePineData.csvFileDataWithStatus
    );
    const endValueFromStore = useSelector(
        (state: any) => state.updatePineData.multipleEndValue
    );
    const totalValueFromStore = useSelector(
        (state: any) => state.updatePineData.multipleTotalValue
    );
    return (
        <IconButton
            sx={{ padding: "0px" }}
            data-testid="PageIncrementButton"
            disabled={
                props.isMissingBtn
                    ? endValueFromStore === totalValueFromStore
                    : endValueFromStore === csvFileDataWithStatus.length
            }
            onClick={() => {
                props.currentPageIncrement();
            }}
        >
            <ArrowForwardIosIcon
                sx={{ height: "10px", cursor: "pointer" }}
                fontSize="small"
            />
        </IconButton>
    )
}

export default PageIncrementButton