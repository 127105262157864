/* eslint-disable no-restricted-globals */
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Box, Divider } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { HelpOutline, Help } from "@mui/icons-material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiEndpoint, auth, getEnvDetails, pinePlusEndpoint } from "../../../constants/appConstant";
import { updatePineDataActions } from "../../../store/uploadPineSlice";
import {
  redirect,
  isUserProfileValid
} from "../../../utility/commonFunction";
import { initializeGTM, triggerCustomEventsGTM } from "../../../utility/ga";

const setSessionStorage = (authenticated: boolean) => {
   // Checking browser support and setting session storage
   if (typeof (Storage) !== 'undefined') {
    window.localStorage.setItem('authenticated', authenticated ? 'true' : 'false')
  }
}

export default function Navbar(props: any) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [ENV, setENV] = useState<any | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const selectedLOBValue: any = useSelector(
    (state: any) => state?.updatePineData?.selectedLOBValue
  );
  const showLOB: any = useSelector((state: any) => state?.updatePineData?.showLOB);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = () => {
    navigate('/pine');
    dispatch(updatePineDataActions.resetAll());
    fetchUserDetails();
  }

  const signoutHandler = () => {
    handleClose();
    handleLogOut();
  };

  const secureLink = (link: any) => {
    if (link.indexOf('https') < 0) {
      return link.replace('http', 'https')
    }
    return link
  }

  const handleLogOut = () => {
    const IES_URL = `${ENV?.LOGOUT_URL}`
    const httpsRedirectLink = secureLink(window.location.href)
    const encodedAppUrl = encodeURIComponent(httpsRedirectLink)
    const completeRedirectURL = `${auth.MYCLOUD_URL}${encodedAppUrl}`
    const redirectEncoded = encodeURIComponent(completeRedirectURL)
    window.localStorage.clear()
    window.location.assign(
      `${IES_URL}${redirectEncoded}&azurelogout=true`
    )
  }

  const fetchUserDetails = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${apiEndpoint}/aes-login-user-details`,
      headers: {
        "Content-Type": "application/json"
      }
    };

    
    axios.request(config)
      .then((response) => {
        let responseData: any = response?.data;
        if(isUserProfileValid(responseData)){
          setUserEmail(String(responseData["mail"]).toLowerCase());
          setSessionStorage(true)
          props.setAuthenticated(true)
          dispatch(
            updatePineDataActions.updateloggedInUserEmail({
              loggedInUserEmail: responseData["mail"].toLowerCase(),
            })
          )
          dispatch(
            updatePineDataActions.updateIsAuthenticated({
              "isAuthenticated": true
            })
          );
        } else {
          setSessionStorage(false)
          props.setAuthenticated(false)
        }
        initializeGTM();
      })
      .catch(function (error) {
        dispatch(
          updatePineDataActions.updateIsAuthenticated({
            "isAuthenticated": false
          })
        );
        if (error?.response?.status === 401) {
          redirect();          
        } else if (error?.response?.status === 403) {
          setSessionStorage(false)
          props.setAuthenticated(false)
        }
        console.log("err occured while fetching users data : ", error);
      });
  };

  const fetchEnvDetails = () => {
    let config = {
      mode: "no-cors",
      method: "get",
      url: getEnvDetails,
    };

    axios(config)
      .then(function (response) {
        let responseData: any = response?.data;
        setENV(responseData)
      })
      .catch(function (error) {
        console.log("Err from env API : ", error);
      });
  };

  useEffect(() => {
    fetchUserDetails();
    fetchEnvDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userEmail?.includes('orangelogic') || userEmail?.includes('serv.')) {
      triggerCustomEventsGTM({
        event: 'service-account-logged-in',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);

  const toggleHelpMenu = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const HelpMenuList = (
    <Box sx={{ width: 300 }} role="presentation" onClick={toggleHelpMenu(false)}>
      <List>
        <ListItem key={'Pine Bulk Upload Guide'} disablePadding>
          <ListItemButton sx={{ color: "#006684" }} component='a' target='_blank' href='https://pace.pearson.com/viewers/preview/#/book/urn:pearson:entity:ad9db5d6-08a5-49e6-8cf4-c985f0ab1489/version/urn:pearson:distributable:d7b54486-ed36-4a5b-89bd-266cfda19b5e/page/urn:pearson:entity:586e6195-2c93-45e9-be43-0fa3362755a3'>
            <ListItemIcon sx={{ color: "#006684" }}>
              <Help />
            </ListItemIcon>
            <ListItemText primary={'Pine Bulk Upload Guide'} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem key={'Pine User Guide'} disablePadding>
          <ListItemButton sx={{ color: "#006684" }} component='a' target='_blank' href='https://pace.pearson.com/viewers/preview/#/book/urn:pearson:entity:ad9db5d6-08a5-49e6-8cf4-c985f0ab1489/version/urn:pearson:distributable:d7b54486-ed36-4a5b-89bd-266cfda19b5e'>
            <ListItemIcon sx={{ color: "#006684" }}>
              <Help />
            </ListItemIcon>
            <ListItemText primary={'Pine User Guide'} />
          </ListItemButton>
        </ListItem>
      </List>

    </Box>
  );
  return (
      <AppBar
      position="sticky"
      sx={{ height: "50px", paddingX: "24px", backgroundColor: "#FBFCFE" }}
      data-testid="Navbar"
    >
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6} sm={9} md={10} container columnGap={6}>
          <Grid item>
            <Typography
              component="div"
              sx={{
                color: "#4B4B4B",
                fontSize: "18px",
                fontWeight: "400",
              }}
            >
              Pine Bulk Upload Tool
            </Typography>
          </Grid>
          {showLOB &&
            <Grid
              item
              xs={6}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                component="div"
                sx={{
                  color: "gray",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                Line of Business:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    fontSize: "12px",
                  }}
                >
                  {selectedLOBValue}
                </span>
              </Typography>
              <Button sx={{ marginLeft: '20px' }} size="small" onClick={handleChange} data-testid="ChangeBtn">Change</Button>
            </Grid>}
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Grid
            flexDirection="row"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            {" "}
            <a
              rel="noopener noreferrer"
              href={pinePlusEndpoint}
              style={{ textDecoration: "none" }}
            >
              <Typography
                component="div"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  color: "#006684",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                Go To Pine
              </Typography>
            </a>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              sx={{ color: "#006684" }}
              data-testid="handleMenuBtn"
            >
              <AccountCircle />
            </IconButton>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleHelpMenu(true)}
              sx={{ color: "#006684" }}
              data-testid="toggleDrawerBtn"
            >
              <HelpOutline />
            </IconButton>
          </Grid>
        </Grid>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          data-testid="handleCloseBtn"
        >
          <MenuItem onClick={handleClose} divider>
            <PersonIcon sx={{ paddingRight: 1 }} />
            <Typography
              component="div"
              sx={{
                color: "#191C1E",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {userEmail}
            </Typography>
          </MenuItem>
          <MenuItem data-testid="signoutHandlerBtn" onClick={signoutHandler}>
            <LogoutIcon sx={{ paddingRight: 1 }} />
            <Typography
              component="div"
              sx={{
                color: "#191C1E",
                fontSize: "14px",
                fontWeight: "400",
              }}
              onClick={signoutHandler}
            >
              Sign Out
            </Typography>
          </MenuItem>
        </Menu>
        <Drawer open={open} onClose={toggleHelpMenu(false)} anchor='right'>
            {HelpMenuList}
        </Drawer>
      </Grid>
    </AppBar>
  );
}

