import { Grid } from "@mui/material";
import ApplyFilterButton from "../atoms/Buttons/ApplyFilterButton";
import ClearAllButton from "../atoms/Buttons/ClearAllButton";

interface Props {
    getFilteredResponse: () => void,
    clearAllStates: () => void
}

const ButtonsWithGrid = (props: Props) => {
    return (
        <Grid
            item
            md={4}
            columnGap={1}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            data-testid="ButtonsWithGrid"
          >
            <ApplyFilterButton
              getFilteredResponse={props.getFilteredResponse}
            />
            <ClearAllButton
              clearAllStates={props.clearAllStates}
            />
          </Grid>
    )
}

export default ButtonsWithGrid