import { Grid } from "@mui/material"
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { useSelector } from "react-redux";
import UploadInfoText from "./Text/UploadInfoText";

const UploadIcon = () => {
    const linkedPineFolder = useSelector(
        (state: any) => state.updatePineData.linkedPineFolder
      );

    return (
        <Grid
            item
            xs={12}
            sx={{
              borderRadius: "10px",
              padding: "2%",
              backgroundColor: "white",
            }}
          >
            <UploadInfoText/>
            <Grid container direction="row" sx={{ fontSize: "12px" }}>
              <Grid item data-testid = 'UploadIcon'>
                <FolderOpenIcon fontSize="small" />
              </Grid>
              <Grid
                item
                sx={{ padding: "0.4%", marginLeft: "1%", color: "#006684" }}
              >
                {linkedPineFolder}
              </Grid>
            </Grid>
          </Grid>
    )
}

export default UploadIcon