import { useEffect, useState } from 'react';
// import { getAuthToken } from '../../../utility/commonFunction';
import Footer from '../Footer/footer';
import Main from '../Main/main';
import Navbar from '../Navbar/Navbar';
import UnauthorizedPage from '../../UnauthorizedPage/UnauthorizedPage';
import { CircularProgress, Box } from '@mui/material';
import '../../../styles/index.css';

const getSessionStorage = () => {
    const authenticated = window.localStorage.getItem('authenticated')

    if(!authenticated) return { authenticated: undefined }

    return { authenticated: authenticated === 'true' ? true : false }
}

const Parent = () => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState<any>(undefined);
    // useSelector((state: any) => state?.updatePineData?.isAuthenticated);
    // useEffect(() => {l
    //     (async () => { await getAuthToken() })();
    // }, [])
    
    useEffect(() => {
        const isAuthenticated: any = getSessionStorage().authenticated;
        setIsAuthenticated(isAuthenticated);
        setIsLoaded(true);
    }, [])
    
    return (
        <>
            {!isLoaded && (
                <>
                    <Box className="loader-overlay-whitebackground" >
                        <CircularProgress className="loader" size={100} disableShrink />
                    </Box>
                </>
            )}
            {(isAuthenticated === undefined || isAuthenticated === true) && (
                <>
                    <Navbar setAuthenticated={setIsAuthenticated}/>
                    <Main />
                    <Footer />
                </>
            )}
            {isAuthenticated === false && (<UnauthorizedPage />)}
        </>
    );
}

export default Parent;