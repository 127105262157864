import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { updatePineDataActions } from "../../../../store/uploadPineSlice";

interface Props {
    uploadDataToS3: any,
    okayButtonClass: any,
    effectiveProgress: any
}

const UploadButton = (props: Props) => {
    const dispatch = useDispatch()

    return (
        <Button
            variant="contained"
            size="small"
            data-testid="UploadButton"
            onClick={() => {
                props.uploadDataToS3();
                dispatch(
                    updatePineDataActions.setBackdrop({ backdrop: true })
                );
            }}
            className={props.okayButtonClass}
            sx={{ backgroundColor: "#006FA6", textTransform: "capitalize" }}
            disabled={
                0 < Math.ceil(props.effectiveProgress) &&
                    Math.ceil(props.effectiveProgress) <= 100
                    ? true
                    : false
            }
        >
            {"Upload to S3"}
        </Button>
    )
}

export default UploadButton