import { Grid, Typography } from "@mui/material"
import { UPLOAD_SUMMARY } from "../../constants/UploadMultipleAssetsContants"

const UploadSummaryText = () => {
    return (
        <Grid item data-testid="UploadSummaryText">
            <Typography gutterBottom sx={{ fontSize: "16px" }}>
                {UPLOAD_SUMMARY}
            </Typography>
        </Grid>
    )
}

export default UploadSummaryText