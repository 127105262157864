export const titleStyle = {
  color: '#007fa3',
  paddingLeft: '10px',
  
}

export const avatarStyle = {
  backgroundColor: '#007FA3',
  height: '35px',
  width: '35px',
  margin: '5px 10px',
  color: 'white'
}

export const logoutBoxStyle = {
  height: '180px',
  width: '255px',
  marginRight: '2.5%',
}

export const logoutBtnStyle = {
  backgroundColor: '#007FA3',
  color: 'white'
}

export const dividerStyle = {
  padding: '1px',
  backgroundColor: '#007fa3',
}

export const boxStyle = {
  padding: '10px',
  border: 20,
  borderColor: '#eeeee8',
  minHeight: '400px',
}

export const tabTextStyle = {
  color: '#007fa3',
}

export const popupTextStyle = {
  marginLeft: '10%'
}

export const inputStyle = {
  marginTop: '5px',
  paddingLeft: '5px',
  borderWidth: '2px',
  borderStyle: 'inset',
  borderColor: '#eeeee8',
  borderRadius: '3px'
}

export const uploadButtonStyle = {
  marginBottom: '5px',
  marginTop: '20px',
  background: 'white',
  color: 'black',
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "black",
  borderRadius: "5px",
}

export const goBackButtonStyle = {
  minWidth: '95px',
  marginLeft: '75%',
  background: 'white',
  color: 'black',
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "black",
  borderRadius: "5px",
}

export const okButtonStyle = {
  minWidth: '95px',
  marginLeft: '75%',
  marginTop: '5%',
  background: 'white',
  color: 'black',
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "black",
  borderRadius: "5px",
}

export const downloadcsvButtonStyle = {
  fontSize: '10px',
  marginTop: '0%',
  background: '#d7dedf',
  color: 'black',
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "black",
  borderRadius: "5px",
}

export const allButtonStyle = {
  fontSize: '12px',
  marginTop: '0%',
  background: '#d7dedf',
  color: 'black',
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "black",
  borderRadius: "5px",
}

export const missingButtonStyle = {
  marginLeft: '4%',
  fontSize: '12px',
  marginTop: '0%',
  background: '#d7dedf',
  color: 'black',
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "black",
  borderRadius: "5px",
}

export const beginUploadButtonStyle = {
  fontSize: '10px',
  background: '#006684',
  color: '#FFFFFF',
  cursor: 'pointer',
}

export const backButtonStyle = {
  background: '#F5F5F5',
  marginLeft: '14.5%',
  marginTop: '2%',
  height: '36px',
  color: '#006684',
}

export const nextButtonStyle = {
  background: '#F5F5F5',
  marginLeft: '14.5%',
  marginTop: '2%',
  height: '36px',
  color: '#006684',
}

export const iconPopupStyle = {
  width: '15vw',
  height: '23vh',
  padding: '18px',
}

export const folderLocationiconPopupStyle = {
  position: 'relative',
  width: '19vw',
  minHeight: '14vh',
  padding: '12px',
}

export const acceptedFilesTypeIconPopupStyle = {
  position: 'relative',
  width: '15vw',
  minHeight: '18vh',
  padding: '12px',
}

export const typographyStyle = {
  fontWeight: '600', 
  marginTop: '10px',
}

export const typographySelectStyle = {
  fontWeight: '600',
  marginTop: '2px',
}

export const uploadSigleTextStyle = {
  fontWeight: '400', 
  marginTop: '23px',
  color: '#007fa3',
  fontSize: "15px",
}

export const mediaButtonStyle = {
  marginTop: '10px',
  marginBottom: '20px',
}
export const gridInputStyle = {
  width: '80%'
}

export const popupStyle = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  height: 150,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  padding: 0,
};

export const errPopupTextStyle = {
  width: "100%",
  height: "100%",
  borderWidth: "2px",
  borderStyle: "solid",
  borderColor: "red",
  background: "pink"
};

export const successPopupTextStyle = {
  width: "100%",
  height: "100%",
  borderWidth: "2px",
  borderStyle: "solid",
  borderColor: "green",
  background: "lightgreen"
};

export const queueTableSTyle = {
  margin: 'auto', 
  minHeight: '400px',
};

export const mediaTableSTyle = {
  margin: 'auto', 
  minHeight: '400px',
  height: '150px'
};

export const uploadProgressTableSTyle = {
  margin: 'auto',
  height: '300px',
};

export const outerContainerStyle = {
  position: 'relative',
  left: "2%",
  width: '62%',
}

export const outerContainerStyle2 = {
  position: 'relative',
  left: "2%",
  width: '60%',
  marginTop: '0vh',
  marginBottom : '7vh',
}

export const uploadSingleFileContainerStyle = {
  borderWidth: '2px',
  borderStyle: 'dashed',
  borderColor: 'darkgrey',
  padding: '4%',
}


export const takeBackButtonStyle = {
  fontSize: '10px',
  background: '#F5F5F5',
  marginTop: '2%',
  height: '30px',
  color: '#006684',
  cursor: 'pointer',
}

export const ViewInPineButtonStyle = {
  fontSize: '10px',
  background: '#006684',
  marginLeft: '6%',
  marginTop: '2%',
  height: '30px',
  color: '#FFFFFF',
  cursor: 'pointer',
}

export const takeBack1ButtonStyle = {
  fontSize: "10px",
  height: "30px",
  color: "#006684",
  cursor: "pointer",
};

export const next2ButtonStyle = {
  fontSize: '10px',
  background: '#006684',
  height: '30px',
  color: '#FFFFFF',
  cursor: 'pointer',
}

export const doneButtonStyle = {
  fontSize: '10px',
  background: '#34b1eb',
  color: '#FFFFFF',
  cursor: 'pointer',  
}

export const cancelButtonStyle = {
  fontSize: '10px',
  background: '#34b1eb',
  color: '#FFFFFF',
  cursor: 'pointer',
  marginLeft: '2%',  
}

export const browseButtonStyle = {
  fontSize: '10px',
  background: '#006684',
  height: '30px',
  marginTop: '1.2%',
  marginLeft: '1%',
  color: '#FFFFFF',
  cursor: 'pointer',
}

export const processFileButtonStyle = {
  fontSize: '11px',
  fontWeight: 'bold',
  height: '30px',
  width: '27%',
  background: '#006684',
  color: '#FFFFFF',
  cursor: 'pointer',
}