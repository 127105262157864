import Box from '@mui/material/Box'
import { Outlet } from 'react-router-dom';

export default function Main() {
  return (
    <Box sx={{ width: '100%' }}
      display='flex'
      justifyContent='center'
      alignItems='start'
      data-testid="Main"
    >
      <Outlet />
    </Box>
  );
}