import { Grid, Typography } from "@mui/material"
import { PAGE2 } from "../../constants/AddSingleFileConstants"

const PageText = () => {
    return (
        <Grid
              item
              xs={2}
              display="flex"
              alignItems="flex-end"
              justifyContent="start"
              data-testid = 'PageText'
            >
              <Typography
                sx={{ lineHeight: "1.0", fontWeight: "600" }}
                variant="subtitle2"
              >
                {PAGE2}
              </Typography>
            </Grid>
    )
}

export default PageText