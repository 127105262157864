import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import PageDecrementButton from "../atoms/Buttons/PageDecrementButton";
import PageIncrementButton from "../atoms/Buttons/PageIncrementButton";

interface Props {
    isMissingBtn: any,
    currentPageDecrement: () => void,
    currentPageIncrement: () => void
}

const PageToggle = (props: Props) => {
    const csvFileDataWithStatus = useSelector(
        (state: any) => state.updatePineData.csvFileDataWithStatus
    );
    const startValueFromStore = useSelector(
        (state: any) => state.updatePineData.multipleStartValue
    );
    const endValueFromStore = useSelector(
        (state: any) => state.updatePineData.multipleEndValue
    );
    const totalValueFromStore = useSelector(
        (state: any) => state.updatePineData.multipleTotalValue
    );
    return (
        <Grid
            item
            xs={4}
            sx={{
                fontSize: "12px",
                display: "flex",
                justifyContent: "flex-end",
            }}
            data-testid="PageToggle"
        >
            {startValueFromStore + 1} - {endValueFromStore} of{" "}
            {props.isMissingBtn
                ? totalValueFromStore
                : csvFileDataWithStatus.length}
            <PageDecrementButton
                isMissingBtn={props.isMissingBtn}
                currentPageDecrement={props.currentPageDecrement}
            />
            <PageIncrementButton
                isMissingBtn={props.isMissingBtn}
                currentPageIncrement={props.currentPageIncrement}
            />
        </Grid>
    )
}

export default PageToggle