// export const apiEndpoint = window.location.origin.includes("localhost") ? 'http://localhost:8080/v1' :  'https://pine-dev.pearson.com/pine/api/v1';
export const presentEnv = window?.location?.host?.split('.')?.shift()?.split('-')?.pop()
export const apiEndpoint = presentEnv === 'pine' ? 'https://pine.pearson.com/pine/api/v1' : ( presentEnv === 'stg' ? 'https://pine-stg.pearson.com/pine/api/v1' : ( presentEnv === 'qa' ? 'https://pine-qa.pearson.com/pine/api/v1' : ( presentEnv === 'dev' ? 'https://pine-dev.pearson.com/pine/api/v1' : ( window?.location?.hostname === 'localhost' ? 'http://localhost:3001/api/v1' : '/pine/api/v1'))));
export const pinePlusEndpoint = presentEnv === 'pine' ? 'https://video.pearson.com' : 'https://video-stg.pearson.com';
export const taskqueueEndpoint = `${apiEndpoint}/taskQueue`
export const uploadEndpoint = `${apiEndpoint}/uploadJob`;
export const uploadMultipleEndpoints = `${apiEndpoint}/uploadMultipleJob`;
export const getMediaLibrary = `${apiEndpoint}/filterFileData`;
export const getUsersDetails = `${apiEndpoint}/user-info`; //Not in use now
export const getEnvDetails = `${apiEndpoint}/env`;
export const getPresignedURL = `${apiEndpoint}/requestSignedUrl`;
export const firstTimeUploadURL = `${apiEndpoint}/getSignedUrlToS3Upload`;
export const secondTimeUploadUrl = `${apiEndpoint}/generateSignedUrlforUploadedFile`;
export const filterErrLogsURL = `${apiEndpoint}/testLogHistoryFilter`;
export const ONE_CONFLUENCE_SPECS = 'https://one-confluence.pearson.com/pages/viewpage.action?pageId=427732416';
export const PINE_BULK_UPLOAD_GUIDE = 'https://pace.pearson.com/viewers/preview/#/book/urn:pearson:entity:ad9db5d6-08a5-49e6-8cf4-c985f0ab1489/version/urn:pearson:distributable:d7b54486-ed36-4a5b-89bd-266cfda19b5e/page/urn:pearson:entity:586e6195-2c93-45e9-be43-0fa3362755a3';
export const customConfig = {
    headers: {
    'Content-Type': 'application/json',
    }
};
export const columnList = ['Job ID', 'Start Time', 'End Time', 'File Name', 'Status'];
export const mediaTableColumnList = ['Filename', 'Email', 'Business Unit', 'Caption Language', 'Content Class', 'Content Type', 'Description', 'Disclipline', 'File Type', 'Keywords', 'Media Title', 'PlayList ID', 'Program', 'Reference ID', 'SMS Authentication', 'Status', 'Type'];
export const dataList = ['job_id', 'start_time','end_time','file_name', 'status']
export const dataList2 = ['filename','email','businessUnit','captionLanguage','contentClass','contentType','description','discipline','fileType','keywords','mediaTitle','playlistId','program','referenceId','smsAuthentication','status','type']
export const resultText = 'Results found';
export const auth = {
    MYCLOUD_URL: 'https://iam-stage.pearson.com/auth/loginRedirect.html?tree=Login&url='
};
export const mainAssetsFileTypes = ['MP4', 'M4V', 'WEBM', 'MPG', 'MP2', 'MPEG', 'MPE', 'MPV', 'OGG', 'QT', 'M4P', 'MOV', 'WMV', 'AVI', 'MKV', 'FLV', 'SWF', 'AVCHD', 'MPEG-2', 'WAV', 'AIFF', 'PCM', 'FLAC', 'MP3', 'AAC', 'ALAC', 'WMA'];
export const mainAssetsAudioFileTypes = ['MP3', 'AAC', 'OGG', 'FLAC', 'ALAC', 'WAV', 'AIFF', 'PCM', 'WMA'];
export const mainAssetsVideoFileTypes = ['WEBM', 'MPG', 'MP2', 'MPEG', 'MPE', 'MPV', 'OGG', 'MP4', 'M4P', 'M4V', 'AVI', 'WMV', 'MOV', 'QT','FLV', 'SWF', 'AVCHD'];
export const audioDescFileTypeArr = ['MP4'];
export const ThumbnailFileTypes = ['JPEG', 'JPG', 'PNG', 'GIF'];
export const captionFileFormats = ['SRT', 'VTT'];
export const navXMLFileFormats = ['XML']; 
export const historyErrTableColumns = [
  "Job ID",
  "Created At",
  "User Details",
  "File Details",
  "Message",
  "Error",
];
export const UploadReportColumns = [
  "Job ID",
  "Created At",
  "User Details",
  "File Details",
  "Upload Start Time",
  "Status",
  "Parent Metadata Sheet"
];
export const historyUploadTableColumns = [
  "Reference ID",
  "Email",
  "Created At",
  "Filename",
  "File Status",
  "File Type",
  "Thumbnail Filename",
  "Thumbnail Status",
  "Audio Descriptor Filename",
  "Audio Descriptor Status",
  "navXML Filename",
  "navXML Status",
  "Caption Filenames",
  "Caption Status",
  "Other Filenames",
  "Other Files Status",
  "Business Unit",
  "Job ID",
  "Updated At",
  "Last Updated",
  "Media Title",
  "Unit",
  "Topic",
  "Sub Topic",
  "Video Type",
  "Discipline",
  "Program",
  "Content Type",
  "Added On",
  "Keywords",
  "Content Class",
  "Playlist ID",
  "Clutch Video ID",
  "Caption Language",
  "Description",
  "SMS Authentication",
];
export const getLOBsEndPoint = 'lob_list';
export const getloadercleartime = 'loader_cleartime'
export const getPayloadSizeEndPoint = 'payload_size';
