import { Grid, MenuItem, Select, Typography } from "@mui/material"
import { useSelector } from "react-redux";
import { CHOOSE_LOB, NEW_LOB_ADDED } from "../constant/InputFormConstants";

interface Props {
    selectedLOBValue: any,
    showSuccessMsg: boolean,
    handleChange: (event: any) => void

}

const SelectLOB = (props: Props) => {
    const allLOBsFromStore = useSelector(
        (state: any) => state.updatePineData.allLOBs
      );

      const iff = (condition: any, then: any, otherwise: any) =>
    condition ? then : otherwise;

    return (
        <Grid data-testid="SelectLOB">
              <Select
                sx={{ width: "200px", height: "46px" }}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={props.selectedLOBValue}
                onChange={props.handleChange}
                displayEmpty
                renderValue={(value) =>
                  iff(
                    value?.length,
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 5px 5px",
                      }}
                    >
                      {value}
                    </Typography>,
                    <Typography
                      sx={{
                        fontFamily: "Helvetica",
                        fontWeight: 400,
                        lineHeight: "17px",
                        color: "gray",
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 5px 5px",
                      }}
                    >
                      {CHOOSE_LOB}
                    </Typography>
                  )
                }
              >
                {allLOBsFromStore?.map((option: any) => (
                  <MenuItem key={option} value={option}>
                    <Typography sx={{ fontSize: "13px" }}>{option}</Typography>
                  </MenuItem>
                ))}
              </Select>
              {props.showSuccessMsg === true && (
                <Typography sx={{ fontSize: "12px", color: "green" }}>
                  {NEW_LOB_ADDED}
                </Typography>
              )}
            </Grid>
    )
}

export default SelectLOB