import { configureStore } from '@reduxjs/toolkit';
import updatePineDataReducer from './uploadPineSlice';
const store = configureStore({
  reducer: {
    updatePineData: updatePineDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
});

export default store;