import { Grid } from "@mui/material"
import AllContentPageButton from "../atoms/Buttons/AllContentPageButton"
import MissingContentPageButton from "../atoms/Buttons/MissingContentPageButton"


const ContentPageButtons = (props: any) => {
    return (
        <Grid xs={8} item data-testid="ContentPageButtons">
            <AllContentPageButton
                enableAllContentPage={props.enableAllContentPage}
                isMissingBtn={props.isMissingBtn}
            />
            <MissingContentPageButton
                enableMissingContentPage={props.enableMissingContentPage}
                isMissingBtn={props.isMissingBtn}
            />
        </Grid>
    )
}

export default ContentPageButtons