import { Grid, Typography } from "@mui/material"
import { UPLOAD_INFO } from "../../constants/AddSingleFileConstants"

const UploadInfoText = () => {
    return (
        <Grid item>
              <Typography gutterBottom sx={{ fontSize: "16px" }}>
                {UPLOAD_INFO}
              </Typography>
            </Grid>
    )
}

export default UploadInfoText