import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Button, Typography } from "@mui/material";
import { takeBack1ButtonStyle } from "../../../../styles/sxStyle";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updatePineDataActions } from "../../../../store/uploadPineSlice";
import { BACK } from "../../constants/UploadMultipleAssetsContants";

const PrevPageButton = (): JSX.Element => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location: any = useLocation();

    const navigateToPreviousPage = () => {
        dispatch(
          updatePineDataActions.updateCsvFileDataWithStatus({
            csvFileDataWithStatus: [],
          })
        );
        dispatch(
          updatePineDataActions.updateMissingFilesData({
            missingFilesData: [],
          })
        );
        navigate("/pine/addSingleFile", {
          state: {
            previousUrl: location?.pathname,
          },
        });
      };

  return (
    <Button
      sx={takeBack1ButtonStyle}
      variant="outlined"
      size="small"
      onClick={() => {
        navigateToPreviousPage();
      }}
      data-testid="PrevPageButton"
    >
      <KeyboardArrowLeftIcon
        sx={{
          fontSize: "15px",
          marginLeft: "-5px",
          paddingRight: "3px",
        }}
      />{" "}
      <Typography
        sx={{
          textTransform: "capitalize",
          fontSize: "12px",
          fontWeight: "600",
        }}
      >
        {BACK}
      </Typography>
    </Button>
  )
}

export default PrevPageButton