import { useNavigate } from "react-router-dom"
import { Button, Typography } from "@mui/material"
import { next2ButtonStyle } from "../../../styles/sxStyle"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { NEXT } from "../constants/addPineFolderConstant"

const NextButton = (props: any): JSX.Element => {
    const navigate = useNavigate()
    const navigateToNextPage = () => navigate("/pine/addSingleFile")
    return (
        <Button onClick={() => { navigateToNextPage() }} data-testid="NextButton" sx={next2ButtonStyle} variant="contained" size="small"
            disabled={props.showChecked ? false : true}> {" "}
            <Typography sx={{ textTransform: "capitalize", fontSize: "12px", fontWeight: "600" }}>
                {NEXT}
            </Typography>
            <NavigateNextIcon fontSize="small"
                sx={{ fontSize: "15px", marginRight: "-5px", paddingLeft: "5px" }}
            />
        </Button>
    )
}

export default NextButton