import { Grid, Typography } from "@mui/material"
import { screenNumber } from "../constants/addPineFolderConstant"

const ScreenNumber = () : JSX.Element =>{
    return (
        <Grid item xs={2} display="flex" alignItems="flex-end" justifyContent="start" data-testid="ScreenNumber">
        <Typography variant="subtitle2"
          sx={{lineHeight: "1.0",fontWeight: "600",dislay: "flex",alignItems: "end"}}>
          {screenNumber}
        </Typography>
      </Grid> 
    )
}

export default ScreenNumber