import { Button, Grid } from "@mui/material";
import { useRef } from "react";
import { browseButtonStyle } from "../../../../styles/sxStyle";
import { useSelector } from "react-redux";

interface Props {
  handleOnChange: (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}
const BrowseFileButton = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const selectedUploadFromStore = useSelector((state: any) => state?.updatePineData?.selectedUploadFrom);

  return (
    <Grid item xs={12} sx={{ fontSize: "12px", textAlign: "center" }} data-testid="BrowseFileButton">
      <input
        type='file'
        onChange={props.handleOnChange}
        multiple={selectedUploadFromStore === 'From Local' ? true: false}
        hidden
        ref={inputRef}
        accept={selectedUploadFromStore === 'From Local' ? '': '.zip'}
      />
      <Button
        sx={browseButtonStyle}
        onClick={() => inputRef.current?.click()}
        variant="contained"
        size="small"
      >
        Browse files
      </Button>
    </Grid>
  )
}

export default BrowseFileButton