import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import FileTypeOptions from "./FileTypeOptions";

interface Props {
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleClose: (event: React.MouseEvent<HTMLButtonElement>) => void
    id: any,
    open: any,
    anchorEl: HTMLButtonElement | null
}

const CustomPopover = (props: Props) => {
    return (
        <Grid
            item
            xs={6}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            data-testid="CustomPopover"
        >
            <IconButton
                disableRipple
                onMouseEnter={props.handleClick}
                onMouseLeave={props.handleClose}
            >
                <ErrorOutlineIcon />
            </IconButton>
            <Popover
                id={props.id}
                open={props.open}
                anchorEl={props.anchorEl}
                onClose={props.handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                sx={{
                    pointerEvents: "none",
                }}
            >
                <FileTypeOptions
                    handleClose={props.handleClose}
                />
            </Popover>
        </Grid>
    )
}

export default CustomPopover