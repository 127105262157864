import { Grid } from "@mui/material";
import { uploadSingleFileContainerStyle } from "../../../styles/sxStyle";
import BrowseFileButton from "../atoms/Buttons/BrowseFileButton";
import { DRAG_DROP_FILES } from "../constants/UploadMultipleAssetsContants";

interface Props {
  handleDrop: (event: React.DragEvent<HTMLTextAreaElement | HTMLInputElement | HTMLDivElement>) => void;
  handleDragOver: (event: React.DragEvent<HTMLTextAreaElement | HTMLInputElement | HTMLDivElement>) => void
  handleOnChange: (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const FileUpload = (props: Props) => {
  return (
    <Grid
      item
      container
      direction="row"
      sx={uploadSingleFileContainerStyle}
      onDragOver={props.handleDragOver}
      onDrop={props.handleDrop}
      data-testid="FileUpload"
    >
      <Grid item xs={12} sx={{ fontSize: "12px", textAlign: "center" }}>
        {DRAG_DROP_FILES}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ fontSize: "12px", textAlign: "center", marginTop: "1%" }}
      >
        Or
      </Grid>
      <BrowseFileButton handleOnChange={props.handleOnChange} />
    </Grid>
  )
}

export default FileUpload