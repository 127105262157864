import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ErrorIcon from "@mui/icons-material/Error";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DCE4E9",
      color: "black",
      fontSize: "12px",
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "11px",
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFFFFF",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

export const CustomizedTables = (props: any) => {
    const [renderedArray, setRenderedArray] = useState<any[]>([]);
  
    let itemsPerPage = 25;
  
    useEffect(() => {
      setRenderedArray([]);
      let renderedArr;
      let start = props.currentPageNo * itemsPerPage;
      let end = start + itemsPerPage;
      let fileLength = props.csvFileDataWithStatus.length;
      if (props.isMissingBtn) {
        if (props.missingCSVFilesData && props.missingCSVFilesData.length > 0) {
          let fileLength = props.missingCSVFilesData.length;
          if (0 < fileLength && fileLength < end) {
            props.getChildValues(start, fileLength, fileLength);
          } else if (fileLength > end) {
            props.getChildValues(start, end, fileLength);
          }
        } else if (props?.missingCSVFilesData?.length === 0) {
          props.getChildValues(-1, 0, 0);
        }
        renderedArr = props.missingCSVFilesData.slice(start, end);
      } else {
        if ((0 < fileLength) && (fileLength < end)) {
          props.getChildValues(start, fileLength);
        } else if (fileLength > end) {
          props.getChildValues(start, end);
        }
        // eslint-disable-next-line array-callback-return
        renderedArr = props.csvFileDataWithStatus.slice(start, end);
      }
      setRenderedArray(renderedArr);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      props.csvFileDataWithStatus,
      props.missingCSVFilesData,
      props.currentPageNo,
      props.isMissingBtn,
    ]);
  
    return (
      <TableContainer component={Paper} data-testid="CustomizedTables">
        <Table sx={{ minWidth: 200 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Media Title</StyledTableCell>
              <StyledTableCell>File Name</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderedArray.map((row: any, index: any) => (
              <StyledTableRow>
                <StyledTableCell key={`${row.mediaTitle}-${index}`} component="th" scope="row">
                  {row.mediaTitle}
                </StyledTableCell>
                <StyledTableCell key={`${row.fieldName}-${index}`}>
                  {row.fieldName}
                </StyledTableCell>
                <StyledTableCell key={`${row.status}-${index}`}>
                  {row.status === "Missing" ? (
                    <span style={{ display: "flex", flexDirection: "row" }}>
                      <ErrorIcon sx={{ fontSize: "16px", color: "darkred" }} />
                      Missing
                    </span>
                  ) : (
                    row.status
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }