import { Grid, TextField, Typography } from "@mui/material";
import { LOB_ALREADY_PRESENT } from "../constant/InputFormConstants";

interface Props {
  validName: any,
  newLOBValue: any, 
  setValidName: any,
  setNewLOBValue: any,
}

const CustomTextField = (props: Props) => {
    return (
        <Grid data-testid="CustomTextField">
              <TextField
                fullWidth
                autoComplete="off"
                size="small"
                error={!props.validName ? true : false}
                InputProps={{
                  style: {
                    padding: "0px",
                    fontSize: "14px",
                    width: "200px",
                  },
                }}
                value={props.newLOBValue}
                onChange={(event) => {
                  props.setValidName(true);
                  props.setNewLOBValue(event.target.value);
                }}
                id="filled-basic"
                label="Enter New LOB Value"
                variant="filled"
              />
              {props.validName === false && (
                <Typography sx={{ fontSize: "12px", color: "#CB0000" }}>
                  {LOB_ALREADY_PRESENT}
                </Typography>
              )}
            </Grid>
    )
}

export default CustomTextField