import { Divider, Grid } from "@mui/material"
import SubText from "../atoms/SubTexts";
import BulkUploadButton from "../atoms/Buttons/BulkUploadButton";

const UploadAssetGrid = (): JSX.Element => {
    return (
        <>
            <Grid item xs={12} paddingY={0.5} sx={{ height: "4vh" }} data-testid="UploadAssetGrid">
                <Divider
                    sx={{ borderBottomWidth: 1, background: "#000000" }}
                    variant="fullWidth"
                />
            </Grid>
            <SubText />
            <Grid
                item
                xs={7.4}
                sx={{
                    margin: "0 auto",
                    marginTop: "3%",
                    height: "4vh",
                    textAlign: "center",
                }}
            >
                <BulkUploadButton />
            </Grid>
        </>
    )
}

export default UploadAssetGrid