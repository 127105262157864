export const SCREEN_NUMBER_2 = '2 of 2'
export const SCREEN_NUMBER_3 = '3 of 3'
export const BACK = 'Back'
export const VIEW_IN_PINE = 'View in Pine'
export const SUCCESS = 'Success'
// export const TRANSFER_STARTED_MESSAGE = 'The file transfer has begun from the source to the destination S3.'
export const TRANSFER_STARTED_MESSAGE = 'Your upload has been initiated and is occurring asynchronously. To view the status of your upload, please see '
export const TRANSCODING_PROCESS_INITIATED_MESSAGE = 'The transcoding process has been initiated. Visit your selected folder in pine to see uploaded assets and monitor progress.'
export const PINE_FOLDER_LOCATION = 'Pine Folder Location'
export const META_FILE = 'Metadata file'
export const UPDATE_SUMMARY = 'Update Summary'
export const ALREADY_TRANSFER_MESSAGE = 'Files present in metadata sheet are already transferred to destination S3.'
export const UPLOAD_SUCCESS_MESSAGE = 'Assets Successfully Uploaded to Pine.'
export const TRANSFER_IN_PROGRESS_MESSAGE = 'Assets transfer in progress.'
export const BULK_UPLOAD = 'Start a New bulk upload'