import { Grid, Typography, TextField } from "@mui/material";
import { REF_ID, STATUS, USER_DETAIL } from "../constants/HistoryLogConstants";

interface Props {
    searchedUserDetails2:any,
    searchedStatus: any,
    referenceId:any,
    setSearchedUserDetails2 : (event: React.SetStateAction<string>) => void,
    setReferenceId: (event: React.SetStateAction<string>) => void,
    setSearchedStatus: (event: React.SetStateAction<string>) => void
}

const ErroLogGrid = (props: Props) => {
    return (
        <>
              <Grid item xs={8} sm={4} md={2} data-testid="ErroLogGrid">
                <Typography variant="body2" fontWeight={600}>
                  {USER_DETAIL}
                </Typography>
                <TextField
                  value={props.searchedUserDetails2}
                  onChange={(e) => {
                    props.setSearchedUserDetails2(e.target.value);
                  }}
                  size="small"
                  inputProps={{ "data-testid": "fromvocabid" }}
                />
              </Grid>
              <Grid item xs={8} sm={4} md={2}>
                <Typography variant="body2" fontWeight={600}>
                  {REF_ID}
                </Typography>
                <TextField
                  value={props.referenceId}
                  onChange={(e) => {
                    props.setReferenceId(e.target.value);
                  }}
                  size="small"
                  inputProps={{ "data-testid": "tovocabid" }}
                />
              </Grid>
              <Grid item xs={8} sm={4} md={2}>
                <Typography variant="body2" fontWeight={600}>
                  {STATUS}
                </Typography>
                <TextField
                  value={props.searchedStatus}
                  onChange={(e) => {
                    props.setSearchedStatus(e.target.value);
                  }}
                  size="small"
                  inputProps={{ "data-testid": "tovocabid2" }}
                />
              </Grid>
            </>
    )
}

export default ErroLogGrid