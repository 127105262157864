/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { updatePineDataActions } from "../../../store/uploadPineSlice";
import { useNavigate } from "react-router-dom";
import { getAvailableLOBsFromDB } from "../../../utility/commonFunction";
import Link from '@mui/material/Link';
import { ONE_CONFLUENCE_SPECS, PINE_BULK_UPLOAD_GUIDE } from "../../../constants/appConstant";
import { triggerCustomEventsGTM } from "../../../utility/ga";

export const IndexPage = () => {
  const selectedLOBValue: any = useSelector(
    (state: any) => state?.updatePineData?.selectedLOBValue
  );
  const allLOBsFromStore = useSelector(
    (state: any) => state.updatePineData.allLOBs
  );
  const menuOptions = allLOBsFromStore;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getLOBsFromAPI();
  }, []);

  const getLOBsFromAPI = async () => {
    let fetchedLOBs: any = await getAvailableLOBsFromDB();
    dispatch(
      updatePineDataActions.updateAllLOBs({
        allLOBs: [...fetchedLOBs],
      })
    );
  }

  const handleChange = (event: any) => {
    dispatch(updatePineDataActions.setSelectedLOB(event.target.value));
  };

  const handleContinue = () => {
    //GA code to get LOB value per user
    const lobSelectedByUser = {
      event: 'lob-selected',
      lobValue: selectedLOBValue
    }
    triggerCustomEventsGTM(lobSelectedByUser)
    navigate("/pine/beginUpload");
    dispatch(updatePineDataActions.setShowLOB(true));
  };
  const iff = (condition: any, then: any, otherwise: any) =>
    condition ? then : otherwise;
  return (
    <Grid sx={{ width: "100%" }} data-testid="IndexPage">
      <Grid
        sx={{
          height: "60px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "1.2rem", color: "#4B4B4B" }}>
          Welcome to Pine
        </Typography>
      </Grid>
      <Grid
        sx={{
          height: "30px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >      
        <Typography sx={{ fontSize: "1.0rem", color: "#4B4B4B" }}>
          Need help? Access our <Link href={PINE_BULK_UPLOAD_GUIDE}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline", color: '#0000FF' }}>
            step-by-step documentation here
          </Link>.
        </Typography>
      </Grid>
      <Grid
        sx={{
          height: "30px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >      
        <Typography sx={{ fontSize: "0.84rem", color: "#4B4B4B" }}>
          Click <Link href={ONE_CONFLUENCE_SPECS}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline", color: '#0000FF' }}>
            here
          </Link> to access the video/audio file tech specs requirements
        </Typography>
      </Grid>
      <Grid
        columnGap={2}
        container
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <Grid item>
          <FormControl sx={{ m: 1 }} size="small">
            <FormHelperText sx={{ marginLeft: "0" }}>
              Line of Business
            </FormHelperText>
            <Grid display="flex" flexDirection="row" columnGap={4}>
              <Select
                sx={{ width: "270px", height: "28px" }}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedLOBValue}
                onChange={handleChange}
                displayEmpty
                renderValue={(value) =>
                  iff(
                    value?.length,
                    <Typography
                      sx={{
                        marginLeft: '-10px',
                        color: "black",
                        fontSize: "13px",
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 5px 5px",
                      }}
                    >
                      {value}
                    </Typography>,
                    <Typography
                      sx={{
                        marginLeft: '-10px',
                        color: "gray",
                        fontSize: "13px",
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 5px 5px",
                      }}
                    >
                      Choose your Line Of Business
                    </Typography>
                  )
                }
              >
                {menuOptions?.map((option: any) => (
                  <MenuItem key={option} value={option}>
                    <Typography sx={{ fontSize: "13px" }}>{option}</Typography>
                  </MenuItem>
                ))}
              </Select>
              <Button
                sx={{ backgroundColor: "#006684", height: "28px" }}
                disabled={selectedLOBValue ? false : true}
                size="small"
                variant="contained"
                onClick={handleContinue}
                data-testid="handleContinue"
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    textTransform: "capitalize",
                    padding: "5px",
                  }}
                >
                  Continue
                </Typography>
              </Button>
            </Grid>
            <FormHelperText sx={{ marginLeft: "0" }}>
              Please select a Line of Business
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};
