import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

const ShowErrMsg = (
  msg: any,
  displayMsg: any[] = [],
  errCount: number = 5,
  increamentCountHandler: any = () => { },
  enablingFileReupload: any = () => { },
  removingErrMsgWindow: any = () => { },
) => {
  let errs: any[] = [];
  if (displayMsg.length > 0) {
    // eslint-disable-next-line array-callback-return
    errs = displayMsg.map((item, index) => {
      if (index < errCount) {
        return <li>{item.name}</li>;
      }
    });
    if (displayMsg.length > errCount) {
      errs.push(
        <li style={{ cursor: "pointer" }} onClick={increamentCountHandler}>
          ...and {Number(displayMsg.length - errCount)} more
        </li>
      );
    }
  }
  return (
    <Grid
      item
      container
      sx={{
        margin: "2% 2% 2% 1%",
        border: "solid red 1px",
        background: "#FFDAD9",
        paddingY: "10px",
        paddingX: "10px",
      }}
      data-testid="ShowErrMsg"
    >
      <Grid
        item
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          marginTop: "8px",
          flexWrap: "nowrap"
        }}
      >
        <ErrorIcon sx={{ fontSize: "16px", color: "darkred", marginTop: "0.3%" }} />
        &nbsp;
        <Typography sx={{ fontSize: "13px", color: "#600000" }}>
          {msg}
        </Typography>
      </Grid>
      {!displayMsg?.includes("Your xlsx file has been successfully uploaded") &&
        displayMsg.length > 0 && (
          <Typography
            sx={{
              fontSize: "13px",
              color: "#600000",
            }}
          >
            <ul style={{ listStylePosition: "outside", margin: 0 }}>{errs}</ul>
          </Typography>
        )}
      {msg === `Upload failed, You have already uploaded these files.` && (
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              color: "#600000",
              paddingLeft: "20px",
            }}
          >
            Do you Want to Upload again?
          </Typography>
          <div>
            <Button>
              <Typography onClick={removingErrMsgWindow} sx={{ fontSize: "13px", textTransform: 'capitalize' }}>Cancel</Typography>
            </Button>
            <Button onClick={enablingFileReupload}>
              <Typography sx={{ fontSize: "13px", textTransform: 'capitalize' }}>Proceed</Typography>
            </Button>
          </div>
        </div>
      )}
    </Grid>
  );
};

export default ShowErrMsg