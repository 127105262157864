import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";

const WarningAlerts = (
    msg: any,
    displayMsg: any[] = [],
    errCount: number = 5,
    increamentCountHandler: any = () => {}
  ) => {
    let errs: any[] = [];
    if (displayMsg.length > 0) {
      errs = displayMsg.map((item, index) => {
        if (index < errCount) {
          return <li>{item}</li>;
        }
        return ''
      });
      if (displayMsg.length > errCount) {
        errs.push(
          <li style={{ cursor: "pointer" }} onClick={increamentCountHandler}>
            ...and {Number(displayMsg.length - errCount)} more
          </li>
        );
      }
    }
  
    return (
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert severity="warning">
          {msg}
          {errs}
        </Alert>
      </Stack>
    );
  }

  export default WarningAlerts