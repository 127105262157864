import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";

interface Props {
    title: any,
    closeBtn: any,
    cancelHandler: any,
    processAllData: any,
    effectiveProgress: any
}

const PopUpTitle = (props: Props) => (
    <Grid container direction="row" sx={{ fontWeight: "600" }} data-testid="PopUpTitle">
      <Grid fontSize="1.1rem" textAlign="left" item xs={10}>
        {props.title}
      </Grid>
      <Grid item xs={2}>
        {(props.closeBtn === true && props.effectiveProgress <= 95) && (
          <CloseIcon
            data-testid="closePopup"
            className="iconClose"
            onClick={() => {
              props.cancelHandler();
              props.processAllData();
          }}
          />
        )}
      </Grid>
    </Grid>
  );

export default PopUpTitle