/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiEndpoint, firstTimeUploadURL, secondTimeUploadUrl } from '../../../constants/appConstant';
import { updatePineDataActions } from "../../../store/uploadPineSlice";
import { triggerCustomEventsGTM } from "../../../utility/ga";
import CancelButton from "../atom/Buttons/CancelButton";
import OkButton from "../atom/Buttons/OkButton";
import UploadButton from "../atom/Buttons/UploadButton";
import "../fileUploadPopup.css";
import { createData, formatBytes } from "../utility";
import PopUpTitle from "./PopUpTitle";
import RenderTable from "./RenderTable";
let fileTypeArr: any = []
let totalUploadSize: any = 0
let controllers: any = []

interface AlertVersionProps {
  divider?: boolean;
  open: boolean;
  title?: string;
  closeButtonClick?: any;
  handleOnlyModalClose?: any;
  closeBtn?: boolean;
  okBtn?: string;
  cancelBtn?: string;
  okClick?: () => void;
  cancelClick?: () => void;
  cancelButtonClass?: string;
  okayButtonClass?: string;
  uploadIcon?: boolean;
  selectedFiles?: any;
  setIsProcessing?: any;
  setAlreadyUploadedFileErr2?: any;
  setAlreadyUploadedFiles?: any,
  setOpenModal?: any,
  uploadAgain?: any,
  removingErrMsgWindow?: any,
  setFilesWithDiffFoldernameErr?: any,
  setFilesWithDiffFoldername?: any,
  setFileUploadErr?: any,
  setsignedUrlErr?: any,
  setFailedUploadingFiles?: any,
  setNonPlaceholderMissingWarning?: any,
  setIsValidZipFile?: any,
  setZipValidationErrMsg?: any,
  setCheckFileAlreadyUploaded?:any,
  setPlaceHolderCheckarr?:any
  placeHolderCheckarr?:any,
  checkFileAlreadyUploaded?:any
  setMainFileCounter?:any
  mainFileCounter?:any
  setMainFilesAlreadyUploaded?:any
  setComcastCasePlacehoderMsg:any
}
const FileUploadPopup = ({
  divider,
  open,
  title,
  closeButtonClick,
  handleOnlyModalClose,
  okClick,
  cancelClick,
  closeBtn,
  okBtn,
  cancelBtn,
  cancelButtonClass,
  okayButtonClass,
  selectedFiles,
  setIsProcessing,
  setAlreadyUploadedFileErr2,
  setAlreadyUploadedFiles,
  setOpenModal,
  uploadAgain,
  removingErrMsgWindow,
  setFilesWithDiffFoldernameErr,
  setFilesWithDiffFoldername,
  setFileUploadErr,
  setsignedUrlErr,
  setFailedUploadingFiles,
  setNonPlaceholderMissingWarning,
  setIsValidZipFile,
  setZipValidationErrMsg,
  setCheckFileAlreadyUploaded,
  setPlaceHolderCheckarr,
  placeHolderCheckarr,
  checkFileAlreadyUploaded,
  setMainFileCounter,
  mainFileCounter,
  setMainFilesAlreadyUploaded,
  setComcastCasePlacehoderMsg
}: AlertVersionProps) => {
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const [uploadedFilesByApi, setUploadedFilesByApi] = useState<any>([]);
  const totalFileUploadCount = useSelector((state: any) => state.updatePineData.totalUploadingFilesCount);
  const trackingJobIdFromStore = useSelector((state: any) => state.updatePineData.trackingJobId);
  const selectedUploadFromStore = useSelector((state: any) => state?.updatePineData?.selectedUploadFrom);
  const allFilesWithJobIdCreatedAtArray = useSelector((state: any) => state.updatePineData.allFilesWithJobIdCreatedAtArray);
  const lastModifiedCSVData = useSelector((state: any) => state.updatePineData.csvFileDataWithStatus);
  const linkedPineFolder = useSelector((state: any) => state.updatePineData.linkedPineFolder);
  const csvFileData = useSelector((state: any) => state.updatePineData.csvFileData);
  const rawCSVFileDataFromStore = useSelector((state: any) => state.updatePineData.rawCSVFileData);
  const filenameJobIdCreatedAtArray = useSelector((state: any) => state.updatePineData.filenameWithJobIdCreatedAtArray);
  const fileSizeInKBFromStore = useSelector((state: any) => state.updatePineData.fileSizeInKB);
  const finalJsonFromStore = useSelector((state: any) => state?.updatePineData?.finalJson);

  const dispatch = useDispatch();
  let [selectedUploadedFiles, setSelectedUploadedFiles] = useState({});
  const [renderOkButton, setRenderOkButton] = useState(false);
  let [showChecked, setShowChecked] = useState(false);
  let [isParsing, setIsParsing] = useState(false);
  const [extractedZipFiles, setExtractedZipFiles] = useState([]);
  let effectiveProgress = 0;
  let uploadedFiles: any = selectedFiles;
  let selectedfiles: any = [];
  let fileSizeArray: any = [];
  let intervalId: any = '';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (let [key, value] of Object.entries(uploadedFiles)) {
    selectedfiles.push(value);
  }

  selectedfiles.forEach((item: any) => {
    let fileName = item.name;
    let fileSize = formatBytes(item.size);
    fileSizeArray.push({ fileName: fileName, fileSize: fileSize });
  });


  let resultArray = fileSizeArray.map((item: any) => {
    return createData(item.fileName, item.fileSize, "");
  });


  let resultArray2 = Object.values(selectedUploadedFiles).map((file: any) => {
    return createData(file.name, file.filesize, `${file.progress}%`);
  });

  if (resultArray2?.length > 0) {
    dispatch(
      updatePineDataActions.setBackdrop({ backdrop: false })
    );
  }
  let totalCombinedProgress: any = Object.values(selectedUploadedFiles).reduce(
    (totalProgress, current: any) => {
      return (totalProgress = totalProgress + current.progress);
    },
    0
  );

  let totalCombinedLoaded: any = Object.values(selectedUploadedFiles).reduce((totalFileLoaded, current: any) => {
    return totalFileLoaded = totalFileLoaded + current.loadedSize;
  }, 0)

  let totalCombinedSize: any = Object.values(selectedUploadedFiles).reduce((totalFileSize, current: any) => {
    return totalFileSize = totalFileSize + current.totalFileSize;
  }, 0)


  if (totalCombinedProgress !== 0) {
    effectiveProgress = (totalCombinedLoaded * 100) / totalCombinedSize;
  }

  const checkForFilenameAndRetrieveRefID = (file: any) => {
    let foundIndex = csvFileData.findIndex((item2: any) => {
      return file.name === item2.fieldName;
    });
    if (foundIndex !== -1) {
      let retrievedRefID = csvFileData[foundIndex]["refrenceId"];
      return retrievedRefID.toString();
    }
  };

  const cancelHandlerWithAbort = () => {
    controllers?.forEach((controller: any) => controller?.abort());
    closeButtonClick();
    setShowChecked(false);
    setIsProcessing(true)
    setSelectedUploadedFiles({});
  };

  const saveErrInTheDB = (filename: any, foldername: any, referenceId: any) => {
    let config = {
      method: "post",
      url: `${apiEndpoint}/updateFileUploadError`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        filename: filename,
        foldername: foldername,
        referenceId: referenceId,
      }),
    };

    axios
      .request(config)
      .then((response: any) => {
      })
      .catch((error: any) => {
        console.log("updateFileUploadError API error : ", error);
      });
  };

  const callPresignedURLAndSendData = async (presignedUrl: any, file: any, options: any, fileTypeForCaption?: any, index?: any): Promise<AxiosResponse<any, any>> => {
    let refId = checkForFilenameAndRetrieveRefID(file);
    const abortController = abortControllerRef?.current
    controllers[index] = abortController;
    return axios
      .put(presignedUrl, file, {
        ...options,
        signal: abortControllerRef.current.signal,
        ...{
          headers: {
            "Content-Type": file.type || fileTypeForCaption || "text/plain",
          },
        },
      })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.log("err : ", error);
        saveErrInTheDB(file?.name, linkedPineFolder, refId);
        setFileUploadErr(true);
        setFailedUploadingFiles((previous: any) => {
          return [...previous, file];
        });
        return Promise.reject(error)
      });
  }

  const removeUploadedFile = (filename: any = "") => {
    for (let [key, value] of Object.entries(selectedfiles)) {
      let obj: any = value;
      if (obj?.name === filename) {
        selectedfiles.splice(key, 1);
        dispatch(
          updatePineDataActions.updateTotalUploadingFilesCount({
            totalUploadingFilesCount: selectedfiles?.length,
          })
        );
        if (selectedfiles?.length === 0) {
          dispatch(updatePineDataActions.setBackdrop({ backdrop: false }));
          cancelHandlerWithAbort();
        }
      }
    }
  };

  const extractJobIDAndCreatedAt = (filename: string) => {
    return allFilesWithJobIdCreatedAtArray?.filter((item: any) => item.filename === filename);
  }

  const appendFileNameWithFolderLocation = (extractedZipFiles: any) => {
    // chnaging filenames in finalJson.csvdata as 'folderLocation/filenames' for zip uploaded files
    let matchedFiles: any = []
    let jobIdAndCreatedAt: any = []
    let modifiedFinalJson = { ...JSON.parse(JSON.stringify(finalJsonFromStore)) };
    modifiedFinalJson.trackingJobId = trackingJobIdFromStore
    modifiedFinalJson.csvdata = modifiedFinalJson?.csvdata?.map((file: any) => {
      if (!!file.filename) {
        let found = extractedZipFiles.find((zipFile: any) => zipFile.Key.match(/\/([^/]+)$/)[1] === file.filename)
        if (found) {
          file['filename'] = found.Key
          matchedFiles.push(found.Key)
          jobIdAndCreatedAt.push({
            job_id: file.job_id,
            created_at: file.created_at
          })
        }
      }
      if (!!file.thumbnailFilename) {
        let found = extractedZipFiles.find((zipFile: any) => zipFile.Key.match(/\/([^/]+)$/)[1] === file.thumbnailFilename)
        if (found) {
          file['thumbnailFilename'] = found.Key
          matchedFiles.push(found.Key)
        }
      }
      if (!!file.audioDescriptorFilename) {
        let found = extractedZipFiles.find((zipFile: any) => zipFile.Key.match(/\/([^/]+)$/)[1] === file.audioDescriptorFilename)
        if (found) {
          file['audioDescriptorFilename'] = found.Key
          matchedFiles.push(found.Key)
        }
      }
      if (!!file.navXMLFileName) {
        let found = extractedZipFiles.find((zipFile: any) => zipFile.Key.match(/\/([^/]+)$/)[1] === file.navXMLFileName)
        if (found) {
          file['navXMLFileName'] = found.Key
          matchedFiles.push(found.Key)
        }
      }
      if (!!file.captionFileNames) {
        const newCapFilenames = file.captionFileNames.split(';')?.reduce((accumulator: any, currentValue: any) => {
          // Find the file object in extractedZipFiles array that matches the currentValue
          const foundFile = extractedZipFiles.find((zipFile: any) =>
            zipFile.Key.includes(currentValue)
          );
          if (foundFile) {
            accumulator.push(foundFile.Key); // Push the matched filename to accumulator array
            matchedFiles.push(foundFile.Key)
          }
          return accumulator; // Return the accumulator for the next iteration
        }, []);
        file.captionFileNames = newCapFilenames.join(';')
      }
      if (!!file.otherFileNames) {
        const newCapFilenames = file.otherFileNames.split(';')?.reduce((accumulator: any, currentValue: any) => {
          const foundFile = extractedZipFiles.find((zipFile: any) =>
            zipFile.Key.includes(currentValue)
          );
          if (foundFile) {
            accumulator.push(foundFile.Key);
            matchedFiles.push(foundFile.Key)
          }
          return accumulator;
        }, []);
        file.otherFileNames = newCapFilenames.join(';')
      }
      return file
    }).filter(Boolean) // filter out null or undefined value

    dispatch(
      updatePineDataActions.updateFinalJson({
        finalJson: modifiedFinalJson,
      })
    )

    dispatch(
      updatePineDataActions.updateJobIdCreatedAtArray({
        jobIdCreatedAtArray: jobIdAndCreatedAt,
      })
    )

    let untrackedFiles = extractedZipFiles
    .filter((zipFile: any) => !matchedFiles.some((matched: any) => matched === zipFile.Key))
    .map((unmatchedFile: any) => unmatchedFile.Key)
    
    dispatch(
      updatePineDataActions.updateUntrackedFiles({
        untrackedFiles: untrackedFiles,
      })
    )

  }

  const getZipFileDetails = async (zipFilename: string) => {
    try {
      let api3Config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiEndpoint}/moveZipFolderFiles`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          trackingJobId: trackingJobIdFromStore,
          originalZipFilename: zipFilename
        }),
      };
      let zipFilesDetailsResponse = await axios(api3Config)
      
      if(zipFilesDetailsResponse.status === 200 && zipFilesDetailsResponse.data.error_code === 28 ){
        clearInterval(intervalId)
        // if error is 'Lambda didn't parsed the Zip folder. Retry after some time' hit the api again
        intervalId = setInterval(async () => {
          await getZipFileDetails(zipFilename)
        }, 2000)
        
      } else {
        if (zipFilesDetailsResponse?.data?.data?.length > 0) {
          clearInterval(intervalId)
          setIsParsing(false)
          setRenderOkButton(true)
          setExtractedZipFiles(zipFilesDetailsResponse?.data?.data)
          appendFileNameWithFolderLocation(zipFilesDetailsResponse?.data?.data)
        }
      }

    } catch (error: any) {
      console.log('catch error from moveZipFolderFiles', error)
      if (error?.response?.status === 403 && error?.response?.data?.error_code === 28) {
        clearInterval(intervalId)
        // if error is 'Lambda didn't parsed the Zip folder. Retry after some time' hit the api again
        intervalId = setInterval(async () => {
          await getZipFileDetails(zipFilename)
        }, 2000)
      } else {
        clearInterval(intervalId)
        setIsParsing(false)
        setRenderOkButton(true)
        setIsValidZipFile(false)
        setZipValidationErrMsg(error?.response?.data?.error_message)
      }
    }
  }

  const getZipUploadStatus = async (status: string, zipFilename: string) => {
    try {
      let api2Config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiEndpoint}/updateZipUploadStatus`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          trackingJobId: trackingJobIdFromStore,
          uploadStatus: status
        }),
      };

      let uploadResponse = await axios(api2Config)
      if (!uploadResponse?.data?.is_error) {
        await getZipFileDetails(zipFilename)
      }
      // IF IS_ERROR is true in response the next api will not get called and error message should be displayed in 'message'
    } catch (error: any) {
      console.log('catch error from getZipUploadStatus', error)
      setIsValidZipFile(false)
      setZipValidationErrMsg(error?.response?.data?.error_message)
    }
  }

  const checkIfSelectedFileIsMainFile = (selectedFile: any) => {
    let mainfileAlreadyUploadedCounterCheck = 0
    const resIndex =   allFilesWithJobIdCreatedAtArray.findIndex((item: any) => {
      // If selectedFile is a  main file then we need to work
        if( (selectedFile.name === item.filename)  && (item.type === 'filename') ) {
          if(item.alreadyUploaded === true) {
            mainfileAlreadyUploadedCounterCheck = 1
          }
          return true
        }    
    });

    // if the file is not a main file
    if(resIndex === -1) {
      return {
        fileAlredyUploaded: mainfileAlreadyUploadedCounterCheck,
        isMainFile:false
      }
    } else {
      return {
        fileAlredyUploaded: mainfileAlreadyUploadedCounterCheck,
        isMainFile:true
      }
    }

  }

  const uploadDataToS3 = async () => {
    setShowChecked(true);
    setIsProcessing(true);
    const cancelButton = document.querySelector(`.buttonStyle`)?.querySelectorAll(`button`)[0];
    if(cancelButton) cancelButton.style.display =  "none";
    

    if (selectedUploadFromStore === 'From Zip') {
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${apiEndpoint}/generateSignedUrlForZip`,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            zipfileName: `${trackingJobIdFromStore}.zip`,
            trackingJobId: trackingJobIdFromStore
          }),
        };
        let responseData = await axios(config);
        let presignedUrl = responseData?.data?.url;
        const getFileObject = (event: any) => {
          return {
            name: selectedFiles[0].name,
            uid: selectedFiles[0].lastModified,
            progress: Math.floor((event.loaded * 100) / event.total),
            loadedSize: event.loaded,
            totalFileSize: event.total,
            filesize: formatBytes(selectedFiles[0].size),
          };
        };

        let options = {
          onUploadProgress: (event: any) => {
            setSelectedUploadedFiles((previous: any) => {
              return {
                ...previous,
                [`${selectedFiles[0].lastModified}${selectedFiles[0].name}`]: getFileObject(event),
              };
            });
          },
        };
        setsignedUrlErr(false)
        let response = await callPresignedURLAndSendData(presignedUrl, selectedFiles[0], options)
        if (response?.status === 200) {
          await getZipUploadStatus('success', selectedFiles[0].name)
        }
      } catch (error: any) {
        setsignedUrlErr(true)
        setIsValidZipFile(false)
        setZipValidationErrMsg(error?.response?.data?.error_message)
        setOpenModal(false)
        dispatch(updatePineDataActions.setBackdrop({ backdrop: false }))
      }
    } else {
      let checkFileAlrdyUpd = false
      let inSelectedFilesMainFilesAlreadyUploaded = 0
      let inSelectedFilesMainFilesnotUploadedButCameForUpload = 0
      let inSelectedFilesSupportingFilesCameForUpload = 0
      await Promise.all(selectedfiles.map(async (file: any, index: any) => {
        let filename = file?.name;
        let fileExtension = filename?.split(".").pop();
        let fileTypeForCaption: any = "";
        if (fileExtension.toUpperCase() === "SRT") {
          fileTypeForCaption = "text/srt";
        } else if (fileExtension.toUpperCase() === "VTT") {
          fileTypeForCaption = "text/vtt";
        }
        let refId = checkForFilenameAndRetrieveRefID(file);
        let jobId_CreatedAtObj = extractJobIDAndCreatedAt(filename)

        const checkIsMainFileUploadedVar = checkIfSelectedFileIsMainFile(file);

        if(checkIsMainFileUploadedVar.isMainFile) {   // That means this file is a main file.

          if(checkIsMainFileUploadedVar.fileAlredyUploaded === 1) { // This means mainfile is already uploaded
            inSelectedFilesMainFilesAlreadyUploaded++
          } else {   // This means mainfile is not uploaded, but came for Upload
            inSelectedFilesMainFilesnotUploadedButCameForUpload++
          }

        } else {  // That means this file is not a main file 
          inSelectedFilesSupportingFilesCameForUpload++
        }

        try {
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: uploadAgain ? secondTimeUploadUrl : firstTimeUploadURL,
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              foldername: linkedPineFolder,
              filename: file.name,
              referenceId: refId,
              job_id: jobId_CreatedAtObj[0].jobId,
              created_at: jobId_CreatedAtObj[0].createdAt,
              mimetype: file.type || fileTypeForCaption || "text/plain",
            }),
          };

          let responseData = await axios(config);
          let presignedUrl = responseData?.data?.url;
          let jobId = responseData?.data?.updateData?.job_id;
          let createdAt = responseData?.data?.updateData?.created_at;
          let payloadKey = responseData?.data?.key;

          const getFileObject = (event: any) => {
            return {
              name: file.name,
              uid: file.lastModified,
              progress: Math.floor((event.loaded * 100) / event.total),
              loadedSize: event.loaded,
              totalFileSize: event.total,
              filesize: formatBytes(file.size),
            };
          };

          let options = {
            onUploadProgress: (event: any) => {
              setSelectedUploadedFiles((previous: any) => {
                return {
                  ...previous,
                  [`${file.lastModified}${file.name}`]: getFileObject(event),
                };
              });
            },
          };

          let response = await callPresignedURLAndSendData(presignedUrl, file, options, fileTypeForCaption, index)
          if (response?.status === 200) {
            controllers?.splice(index, 1);
            totalUploadSize = totalUploadSize + file?.size
            let api3Config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${apiEndpoint}/updateFileUploadStatus`,
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                filename: file.name,
                key: payloadKey,
                job_id: jobId,
                created_at: createdAt,
              }),
            };

            let api3Response = await axios(api3Config);
            rawCSVFileDataFromStore?.forEach((item: any) => {
              if (item['fieldName'] === filename) {
                (item?.fileType && item?.fileType !== '') ? fileTypeArr.push(item.fileType) : fileTypeArr.push('Other')
              }
            })

            for( let csvDataFinal of finalJsonFromStore.csvdata){
              //console.log("csvDataFinal",csvDataFinal)
              if(csvDataFinal.placeholder === 'N' ) {
                if(csvDataFinal.filename === file?.name) {
                  setPlaceHolderCheckarr((previous: any) => {
                    return [...previous, csvDataFinal.filename];
                  })
                }
              }
            }

          }
        } catch (err: any) {
          
          console.log("Error", err, "Err msg : ", err?.response?.data?.error);
          removeUploadedFile(filename);
          switch (true) {
            case (err?.response?.status === 403 && err?.response?.data?.error === "file is already uploaded" && !uploadAgain):
              if (selectedfiles?.length > 0) {
                setAlreadyUploadedFileErr2(true);
              } else {
                setAlreadyUploadedFileErr2(true);
                setOpenModal(false);
              }
              setUploadedFilesByApi((previous: any) => {
                return [...previous, filename];
              });
              setAlreadyUploadedFiles((previous: any) => {
                return [...previous, file];
              });
              checkFileAlrdyUpd = true;
              console.log("file already uploaded", checkFileAlrdyUpd)
              for( let csvDataFinal of finalJsonFromStore.csvdata){
                console.log("csvDataFinal",csvDataFinal)
                if(csvDataFinal.placeholder === 'N'  && csvDataFinal.status !=='completed') { // This means main file is not uploaded yet
                  setMainFileCounter(mainFileCounter+1);
                  if(csvDataFinal.filename === file?.name) {
                    setPlaceHolderCheckarr((previous: any) => {
                      return [...previous, csvDataFinal.filename];
                    })
                  }
                }
              }
              break;
            case (err?.response?.status === 403 && err?.response?.data?.error_code === 51):
              setFilesWithDiffFoldernameErr(true);
              setFilesWithDiffFoldername((previous: any) => {
                return [...previous, { name: err?.response?.data?.error }];
              });
              break;
          }
        }
      }));

      if(uploadAgain){
        setCheckFileAlreadyUploaded(true) 
      } else {
        setCheckFileAlreadyUploaded(checkFileAlrdyUpd)
      }
      
      console.log("placeHolderCheckarr",placeHolderCheckarr, "checkFileAlreadyUploaded",checkFileAlreadyUploaded, "mainFileCounter", mainFileCounter)
      
      // when placeholder is N and the main files present in array are same as user selected main files
      if(placeHolderCheckarr.length === mainFileCounter && placeHolderCheckarr.length !==0) {
        setNonPlaceholderMissingWarning(false)
      } else {
        if(placeHolderCheckarr.length === 0 && checkFileAlreadyUploaded) {

          // Here I have to work
          const totalMainfiles = allFilesWithJobIdCreatedAtArray.filter((item: any) => {
            if(item.type === 'filename') {
                return item
            }
          })

          const totalMainFilesAlreadyUploaded =  totalMainfiles.filter((item: any) => {
              if(item.alreadyUploaded === true) {
                return item
              }
          })

          if(totalMainfiles.length !== totalMainFilesAlreadyUploaded.length) { // This means some mainfiles were not uploaded
             let remainingMainFilesForUpload = totalMainfiles.length - totalMainFilesAlreadyUploaded.length
             if(remainingMainFilesForUpload === inSelectedFilesMainFilesnotUploadedButCameForUpload) {
                // This means all remaining mainfiles are came for upload
                setMainFilesAlreadyUploaded(false)
                setNonPlaceholderMissingWarning(false)
             } else{
                setMainFilesAlreadyUploaded(true)
                setNonPlaceholderMissingWarning(true)
             }
          } else {
            setMainFilesAlreadyUploaded(false)
            setNonPlaceholderMissingWarning(false)
          }

          
        } else { // If user selected the supporting files
          setNonPlaceholderMissingWarning(false)

          if( (placeHolderCheckarr.length !== mainFileCounter) && (placeHolderCheckarr.length !==0) && checkFileAlreadyUploaded) {
            setNonPlaceholderMissingWarning(true)
          }
        }

        // If case is Comcast 
        const checkAnyComcastFile = allFilesWithJobIdCreatedAtArray.filter((item: any) => {
          if (item.comcast_version === true && item.type === 'filename') {
            return item
          }
        })
        console.log("checkAnyComcastFile",checkAnyComcastFile)

        if (checkAnyComcastFile.length > 0 ) {
          setNonPlaceholderMissingWarning(false)
          setComcastCasePlacehoderMsg(true)
        }

        // if placeholder is Y then 
        if(finalJsonFromStore?.csvdata[0]?.placeholder === 'Y') {
          setNonPlaceholderMissingWarning(false)
        }
      }

    }
    selectedUploadFromStore === 'From Local' ? setRenderOkButton(true) : setIsParsing(true);
  };

  const processAllData = () => {
    let foundMatch: boolean = false;
    let removedUploadedFiles: any[] = [];
    let MissingItems: any[] = [];
    let recentCSVFilesData;
    if (lastModifiedCSVData.length > 0) {
      recentCSVFilesData = lastModifiedCSVData;
    } else {
      recentCSVFilesData = csvFileData;
    }
    // eslint-disable-next-line array-callback-return
    if (selectedUploadFromStore === 'From Zip') {
      let parsedFileArray = csvFileData.map((csvFile: any) => {
        let found = extractedZipFiles.find((zipFile: any) => zipFile.Key.match(/\/([^/]+)$/)[1] === csvFile.fieldName)
       
        if (found) {
          return {
            mediaTitle: csvFile.mediaTitle,
            fieldName: csvFile.fieldName,
            status: 'Parsed'
          }
        } else {
          return {
            mediaTitle: csvFile.mediaTitle,
            fieldName: csvFile.fieldName,
            status: 'Missing'
          }
        }
      })
      let missingItems = parsedFileArray.filter((modifiedItem: any) => {
        return modifiedItem?.status === "Missing";
      });
      
      let missingNonPlaceholderWarn = false;

      finalJsonFromStore.csvdata.forEach(( csvRow: any) => {
        if (csvRow.placeholder === 'N') {
          for (const misItm of missingItems) {
            if (csvRow.filename.split('/').pop() === misItm.fieldName) {
              missingNonPlaceholderWarn =  true
            }else{
              missingNonPlaceholderWarn = false
            }
          }  
        }else{
          missingNonPlaceholderWarn = false
        }
      })

      if (missingNonPlaceholderWarn) {
        setNonPlaceholderMissingWarning(true);
      } else{
        setNonPlaceholderMissingWarning(false);
      }
      
      dispatch(updatePineDataActions.updateCsvFileDataWithStatus({ csvFileDataWithStatus: parsedFileArray }));
      dispatch(updatePineDataActions.updateMissingFilesData({ missingFilesData: missingItems }));
    } else {
      let modifiedCSVData = recentCSVFilesData.map((item: any) => {
        let foundIndex = resultArray2.findIndex((item2: any) => {
          if (item?.status && item?.status === "Missing") {
            return item.fieldName === item2.filename && item2.progress === "100%";
          } else if (!item?.status) {
            return item.fieldName === item2.filename && item2.progress === "100%";
          }
        });
        if (foundIndex !== -1 && !item?.status) {
          return {
            mediaTitle: item.mediaTitle,
            fieldName: item.fieldName,
            status: "Uploaded",
          };
        } else if (foundIndex === -1 && !item?.status) {
          return {
            mediaTitle: item.mediaTitle,
            fieldName: item.fieldName,
            status: "Missing",
          };
        } else if (foundIndex !== -1 && item.status) {
          return {
            mediaTitle: item.mediaTitle,
            fieldName: item.fieldName,
            status: "Uploaded",
          };
        } else if (foundIndex === -1 && item.status) {
          return item;
        }
      });
      if (uploadedFilesByApi?.length > 0) {
        removedUploadedFiles = modifiedCSVData.filter((item: any) => {
          return uploadedFilesByApi.includes(item.fieldName);
        })
      }
      MissingItems = modifiedCSVData.filter((modifiedItem: any) => {
        return modifiedItem?.status === "Missing";
      });
      if (removedUploadedFiles?.length > 0) {
        dispatch(
          updatePineDataActions.setRemovedUploadedFiles({
            removedUploadedFiles: removedUploadedFiles,
          })
        );
      }
      dispatch(
        updatePineDataActions.updateCsvFileDataWithStatus({
          csvFileDataWithStatus: modifiedCSVData,
        })
      );
      dispatch(
        updatePineDataActions.updateMissingFilesData({
          missingFilesData: MissingItems,
        })
      );
      dispatch(
        updatePineDataActions.updateMultipleFileCount({
          multipleFileCount: Number(modifiedCSVData?.length - MissingItems?.length),
        })
      );
      if (MissingItems?.length > 0) {
        filenameJobIdCreatedAtArray?.forEach((item: any) => {
          if (foundMatch) return; // If a match is found, no need to continue

          MissingItems?.forEach((missingFile: any) => {
            if (item.filename === missingFile.fieldName) {
              foundMatch = true; // Set the flag to true
            }
          });
        });

        if (foundMatch) {
          setNonPlaceholderMissingWarning(true);
        }
      }
      // GA code to track local uploads
      if (fileTypeArr?.length > 0) {
        triggerCustomEventsGTM({
          event: 'local-upload',
          totalFiles: fileTypeArr?.length,
          fileTypes: fileTypeArr,
          totalRows: finalJsonFromStore?.csvdata?.length,
          metadataUploadSize: fileSizeInKBFromStore,
          assetUploadSize: `${(totalUploadSize / (1024 * 1024)).toFixed(2)} MB`
        })
      }
    }
  };

  useEffect(() => {
    let updatedSelectedFilesLength = selectedfiles?.length;
    dispatch(
      updatePineDataActions.updateTotalUploadingFilesCount({ totalUploadingFilesCount: updatedSelectedFilesLength })
    );
  }, [])

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  return (
    <div>
      <Dialog open={open} classes={{ paper: "wrapper" }}>
        <DialogTitle id="alert-dialog-title">
          <PopUpTitle
            title={title}
            closeBtn={closeBtn}
            cancelHandler={cancelHandlerWithAbort}
            processAllData={processAllData}
            effectiveProgress={effectiveProgress}
          />
        </DialogTitle>
        {divider === true && <Divider light />}
        <DialogContent>
          <DialogContentText className="contentStyle">
            {showChecked ?
              <RenderTable
                resultArray={resultArray2}
                effectiveProgress={effectiveProgress}
                selectedfiles={selectedfiles}
                totalFileUploadCount={totalFileUploadCount} /> :
              <RenderTable
                resultArray={resultArray}
                effectiveProgress={effectiveProgress}
                selectedfiles={selectedfiles}
                totalFileUploadCount={totalFileUploadCount}
              />}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="buttonStyle">
          {okBtn !== "" && okBtn !== undefined &&
            <>
              {cancelBtn !== "" && cancelBtn !== undefined && effectiveProgress < 100 && (
                <CancelButton
                  cancelHandlerWithAbort={cancelHandlerWithAbort}
                  processAllData={processAllData}
                  cancelButtonClass={cancelButtonClass}
                  cancelBtn={cancelBtn}
                />
              )}
              {!isNaN(effectiveProgress) && effectiveProgress >= 100 ? (
                <>
                  {isParsing && (
                    <Button variant="text"
                      size="small"
                    >
                      <CircularProgress size={15} disableShrink sx={{ marginRight: '0.5em' }} />
                      Parsing
                    </Button>
                  )}
                  {renderOkButton && (
                    <OkButton
                      handleOnlyModalClose={handleOnlyModalClose}
                      processAllData={processAllData}
                      removingErrMsgWindow={removingErrMsgWindow}
                      uploadAgain={uploadAgain}
                      okayButtonClass={okayButtonClass}
                      okBtn={okBtn}
                    />)}
                </>
              ) : (
                <UploadButton
                  uploadDataToS3={uploadDataToS3}
                  okayButtonClass={okayButtonClass}
                  effectiveProgress={effectiveProgress}
                />
              )}
            </>
          }
          
        </DialogActions>
        <div className="msgStyle">Once "Upload To S3" is selected, job cannot be cancelled.</div>
      </Dialog>
      
    </div>
  );
};

export default FileUploadPopup;
