import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material"

interface Props {
    handleRadioValueChange: any,
    radioValueselected: any,
    disableRadioBtns: any,
}

const FormControlGrid = (props: Props) => {
    return (
        <Grid item>
              <FormControl>
                Source
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={props.handleRadioValueChange}
                  value={props.radioValueselected}
                >
                  <FormControlLabel
                    value="From Local"
                    control={<Radio />}
                    label="Local"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 12,
                      },
                      "& .MuiTypography-root": {
                        fontSize: "14px",
                      },
                    }}
                    disabled={!props.disableRadioBtns ? false : true}
                  />
                  <FormControlLabel
                    value="S3 to S3"
                    control={<Radio />}
                    label="Remote"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 12,
                      },
                      "& .MuiTypography-root": {
                        fontSize: "14px",
                      },
                    }}
                    disabled={!props.disableRadioBtns ? false : true}
                  />
                  <FormControlLabel
                    value="From Zip"
                    control={<Radio />}
                    label="ZIP"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 12,
                      },
                      "& .MuiTypography-root": {
                        fontSize: "14px",
                      },
                    }}
                    disabled={!props.disableRadioBtns ? false : true}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
    )
}

export default FormControlGrid