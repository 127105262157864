import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pineFolderLocation: '',
  fileSizeInKB: '0 KB',
  linkedPineFolder: '',
  singleFileName: '',
  trackingJobId: '',
  multipleFileCount: 0,
  csvFileData: [],
  singleCsvFile: '',
  isProcessingTab: false,
  isProcessingTab2: false,
  showBtnChecked: false,
  csvFileDataWithStatus: [],
  untrackedFiles: [],
  missingFilesData: [],
  removedUploadedFiles: [],
  loggedInUserEmail: '',
  selectedLOBValue: '',
  showLOB: false,
  selectedUploadFrom: '',
  totalS3Files: 0,
  showLoader: true,
  showError: false,
  uploadReportFlag: false,
  multipleStartValue: 0,
  multipleEndValue: 25,
  multipleTotalValue: 25,
  totalUploadingFilesCount: 0,
  finalJson: {},
  backdrop: false,
  disabledRadioBtns: false,
  historyLogsData: [],
  uploadReportData: [],
  uploadTableLogsData: [],
  historyLogsLastKey: null,
  uploadTableLogsLastKey: null,
  filteredErrorLogsData: [],
  filteredHistoryLogsData: [],
  allLOBs: [],
  allowedFileUploadSize: 2,
  rawCSVFileData: [],
  csvJobId: '',
  notFoundFilesArr: [],
  jobIdCreatedAtArray: [],
  urlProtocol: '',
  filenameWithJobIdCreatedAtArray: [],
  allFilesWithJobIdCreatedAtArray: [],
  isAuthenticated: undefined
};

export const updatePineDataSlice = createSlice({
  name: "uploadpinedata",
  initialState: initialState,
  reducers: {
    setPineFolderLocation: (state, action) => {
      state.pineFolderLocation = action?.payload;
    },
    setFileSizeInKB: (state, action) => {
      state.fileSizeInKB = action?.payload;
    },
    setUrlProtocol: (state, action) => {
      state.urlProtocol = action?.payload?.urlProtocol;
    },
    setLinkedPineFolder: (state, action) => {
      state.linkedPineFolder = action?.payload;
    },
    updateSingleFileName(state, action) {
      state.singleFileName = action.payload.singleFileName;
    },
    updateTrackingJobId(state, action) {
      state.trackingJobId = action.payload.trackingJobId;
    },
    updateMultipleFileCount(state, action) {
      if (action.payload.updateType === "reset") {
        state.multipleFileCount = 0;
      } else {
        state.multipleFileCount = action.payload.multipleFileCount;
      }
    },
    updateCsvFileData(state: Record<any, any>, action) {
      state.csvFileData = [...action.payload.csvFileData];
    },
    updateSingleCsvFile(state: Record<any, any>, action) {
      state.singleCsvFile = action.payload.selectedFile;
    },
    updateisProcessingTab(state: Record<any, any>, action) {
      state.isProcessingTab = action.payload.isProcessingTab;
    },
    updateisProcessingTab2(state: Record<any, any>, action) {
      state.isProcessingTab2 = action.payload.isProcessingTab2;
    },
    updateshowBtnChecked(state: Record<any, any>, action) {
      state.showBtnChecked = action.payload.showBtnChecked;
    },
    updateCsvFileDataWithStatus(state: Record<any, any>, action) {
      if (Array.isArray(action.payload.csvFileDataWithStatus)) {
        state.csvFileDataWithStatus = [...action.payload.csvFileDataWithStatus];
      }
    },
    updateUntrackedFiles(state: Record<any, any>, action) {
      state.untrackedFiles = [...action.payload.untrackedFiles];
    },
    updateMissingFilesData(state: Record<any, any>, action) {
      state.missingFilesData = [...action.payload.missingFilesData];
    },
    setRemovedUploadedFiles(state: Record<any, any>, action) {
      if (Array.isArray(action.payload.removedUploadedFiles)) {
        state.removedUploadedFiles = [...action.payload.removedUploadedFiles];
      }
    },
    updateloggedInUserEmail(state: Record<any, any>, action) {
      state.loggedInUserEmail = action.payload.loggedInUserEmail;
    },
    setSelectedLOB(state, action) {
      state.selectedLOBValue = action?.payload;
    },
    setShowLOB(state, action) {
      state.showLOB = action?.payload;
    },
    updateSelectedUploadFrom(state, action) {
      state.selectedUploadFrom = action?.payload?.selectedUploadFrom;
    },
    updateTotalS3Files(state, action) {
      state.totalS3Files = action?.payload?.totalS3Files;
    },
    updateShowLoader(state, action) {
      state.showLoader = action?.payload?.showLoader;
    },
    updateShowError(state, action) {
      state.showError = action?.payload?.showError;
    },
    updateUploadReportFlag(state, action) {
      state.uploadReportFlag = action?.payload?.uploadReportFlag;
    },
    updateMultipleStartValue(state, action) {
      state.multipleStartValue = action?.payload?.multipleStartValue;
    },
    updateMultipleEndValue(state, action) {
      state.multipleEndValue = action?.payload?.multipleEndValue;
    },
    updateMultipleTotalValue(state, action) {
      state.multipleTotalValue = action?.payload?.multipleTotalValue;
    },
    updateTotalUploadingFilesCount(state, action) {
      state.totalUploadingFilesCount = action?.payload?.totalUploadingFilesCount;
    },
    updateFinalJson(state, action) {
      state.finalJson = action?.payload?.finalJson;
    },
    setBackdrop: (state, action) => {
      state.backdrop = action?.payload?.backdrop;
    },
    setDisabledRadioBtns: (state, action) => {
      state.disabledRadioBtns = action?.payload?.disabledRadioBtns;
    },
    updateHistoryLogsData(state: any, action) {
      state.historyLogsData = [...state.historyLogsData, ...action?.payload?.historyLogsData];
    },
    updateUploadReportData(state: any, action) {
      state.uploadReportData = action?.payload?.uploadReportData;
    },
    updateUploadTableLogsData(state: any, action) {
      state.uploadTableLogsData = [...state.uploadTableLogsData, ...action?.payload?.uploadTableLogsData];
    },
    updateHistoryLogsLastKey(state, action) {
      state.historyLogsLastKey = action?.payload?.historyLogsLastKey;
    },
    updateUploadTableLogsLastKey(state, action) {
      state.uploadTableLogsLastKey = action?.payload?.uploadTableLogsLastKey;
    },
    updateFilteredErrorLogsData(state: any, action) {
      state.filteredErrorLogsData = [...action?.payload?.filteredErrorLogsData];
    },
    updateFilteredHistoryLogsData(state: any, action) {
      state.filteredHistoryLogsData = [...action?.payload?.filteredHistoryLogsData];
    },
    updateAllLOBs(state: any, action) {
      state.allLOBs = [...action?.payload?.allLOBs];
    },
    updateAllowedFileUploadSize(state: any, action) {
      state.allowedFileUploadSize = action?.payload?.allowedFileUploadSize;
    },
    updateRawCSVFileData(state: any, action) {
      state.rawCSVFileData = action?.payload?.rawCSVFileData;
    },
    updateNotFoundFilesErr(state: any, action) {
      state.notFoundFilesArr = action?.payload?.notFoundFilesArr;
    },
    updateJobIdCreatedAtArray(state: any, action) {
      state.jobIdCreatedAtArray = action?.payload?.jobIdCreatedAtArray;
    },
    updatefilenameWithJobIdCreatedAtArray(state: any, action) {
      state.filenameWithJobIdCreatedAtArray = action?.payload?.filenameWithJobIdCreatedAtArray;
    },
    updateAllFilesWithJobIdCreatedAtArray(state: any, action) {
      state.allFilesWithJobIdCreatedAtArray = action?.payload?.allFilesWithJobIdCreatedAtArray;
    },
    updateJobId(state: any, action) {
      state.csvJobId = action?.payload?.csvJobId;
    },
    updateIsAuthenticated(state: any, action) {
      state.isAuthenticated = action?.payload.isAuthenticated;
    },
    resetWithPreservedValue(state: any, action) {
      return { ...initialState, loggedInUserEmail: action.payload }
    },
    resetAll: () => initialState,
  },
});

export const updatePineDataActions = updatePineDataSlice.actions;

export default updatePineDataSlice.reducer;