import { Typography } from "@mui/material"
import { ADD_NEW_LOB } from "../constant/InputFormConstants"

const AddNewText = () => {
    return (
        <Typography
            sx={{
                fontSize: "13px",
                textTransform: "capitalize",
                padding: "5px",
                width: "120px",
            }}
            data-testid="AddNewText"
        >
            {ADD_NEW_LOB}
        </Typography>
    )
}

export default AddNewText