import { Grid, IconButton, TextField } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchOutlined from "@mui/icons-material/Search";

interface Props {
  searchQuery: any,
  setSearchQuery: any
  startValue: any
  endValue: any
  totalValue: any
  tableObjArr: any
  searchedDataStartPageNoDecrement: any
  searchedDataStartPageNoIncrement: any
  currentPageDecrement: any
  currentPageIncrement: any
}

const CustomToogleWithTextField = (props: Props) => {
  return (
    <Grid
      item
      container
      direction="row"
      sx={{
        marginTop: "3%",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
      data-testid = 'CustomToogleWithTextField'
    >
      <Grid xs={8} item>
        <TextField
          sx={{ padding: "0px" }}
          inputProps={{ 'data-testid': 'panel-text' }}
          fullWidth
          size="small"
          id="standard-bare"
          variant="outlined"
          autoComplete="off"
          value={props.searchQuery}
          placeholder="Find media files"
          onChange={(e) => {
            props.setSearchQuery(e.target.value);
          }}
          InputProps={{
            style: {
              padding: 0,
            },
            endAdornment: (
              <IconButton>
                <SearchOutlined />
              </IconButton>
            ),
          }}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sx={{ fontSize: "12px", position: "absolute", right: "2%" }}
      >
        {props.startValue + 1} - {props.endValue} of{" "}
        {props.searchQuery ? props.totalValue : props.tableObjArr.length}
        <IconButton
          sx={{ padding: "0px" }}
          disabled={
            props.startValue === -1
              ? props.startValue + 1 === 0
              : props.startValue + 1 === 1
          }
          data-testid = 'BackwardButton'
          onClick={() => {
            props.searchQuery
              ? props.searchedDataStartPageNoDecrement()
              : props.currentPageDecrement();
          }}
        >
          <ArrowBackIosIcon
            sx={{ height: "10px", cursor: "pointer" }}
            fontSize="small"
          />
        </IconButton>
        <IconButton
          sx={{ padding: "0px" }}
          disabled={
            props.searchQuery
              ? props.endValue === props.totalValue
              : props.endValue === props.tableObjArr.length
          }
          onClick={() => {
            props.searchQuery
              ? props.searchedDataStartPageNoIncrement()
              : props.currentPageIncrement();
          }}
          data-testid = 'ForwardButton'
        >
          <ArrowForwardIosIcon
            sx={{ height: "10px", cursor: "pointer" }}
            fontSize="small"
          />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default CustomToogleWithTextField