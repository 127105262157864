import { Typography } from "@mui/material"
import { MAXIMUM_SIZE } from "../constant/InputFormConstants"

const MaximumSizeText = () => {
  return (
    <Typography
      sx={{
        fontSize: "16px",
        textTransform: "capitalize",
        padding: "5px",
        width: "270px",
      }}
      data-testid="MaximumSizeText"
    >
      {MAXIMUM_SIZE}
    </Typography>
  )
}

export default MaximumSizeText