import { Grid } from "@mui/material"
import { useSelector } from "react-redux";
import { ASSET_IDENTIFIED } from "../../constants/UploadMultipleAssetsContants";

const AssetDetail = () => {
    const csvFileDataWithStatus = useSelector(
        (state: any) => state.updatePineData.csvFileDataWithStatus
    );
    const missingCSVFilesData = useSelector(
        (state: any) => state.updatePineData.missingFilesData
    );
    return (
        <Grid item container direction="row" sx={{ fontSize: "12px" }} data-testid="AssetDetail">
            You've uploaded{" "}
            {csvFileDataWithStatus.length - missingCSVFilesData.length} 0f{" "}
            {csvFileDataWithStatus.length} {ASSET_IDENTIFIED}
        </Grid>
    )
}

export default AssetDetail