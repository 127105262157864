import { Link } from "react-router-dom"
import { Button, Typography } from "@mui/material"
import { takeBack1ButtonStyle } from "../../../styles/sxStyle"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"

const BackLink = (): JSX.Element => {
    return (
        <Link to="/pine/beginUpload" style={{ textDecoration: "none" }}>
            <Button sx={takeBack1ButtonStyle} variant="outlined" size="small" style={{ borderColor: "black" }} data-testid="BackLink">
                <KeyboardArrowLeftIcon sx={{ fontSize: "15px", marginLeft: "-5px", paddingRight: "3px" }} />
                {" "}
                <Typography
                    sx={{ textTransform: "capitalize", fontSize: "12px", fontWeight: "600" }}>
                    Back
                </Typography>
            </Button>
        </Link>
    )
}

export default BackLink