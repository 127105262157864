import { Grid } from "@mui/material";
import NextButton from "../atom/Buttons/NextButton";
import BackButton from "../atom/Buttons/BackButton";

interface Props {
  checkForNextBtnDisableCondition: () => boolean;
  navigateToPreviousPage: () => void;
  handleSubmit: () => Promise<void>;
}

const ButtonsWithGrid = (props: Props) => {
  const { navigateToPreviousPage, handleSubmit, checkForNextBtnDisableCondition } = props;

  return (
    <Grid
      item
      xs={10}
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "end",
      }}
      columnGap={2}
      data-testid = 'ButtonsWithGrid'
    >
      <BackButton navigateToPreviousPage={navigateToPreviousPage} data-testid = 'BackButton'/>
      <NextButton
        handleSubmit={handleSubmit}
        checkForNextBtnDisableCondition={checkForNextBtnDisableCondition}
        data-testid = 'NextButton'
      />
    </Grid>
  );
};

export default ButtonsWithGrid;
