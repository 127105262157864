import { Button } from "@mui/material";
import { NEXT } from "../../constants/AddSingleFileConstants";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import BackNextText from "../Text/BackNextText";

interface Props {
    handleSubmit: any,
    checkForNextBtnDisableCondition: any
}

const NextButton = (props: Props) => {
    return (
        <Button
            sx={{
                fontSize: "10px",
                background: "#006684",
                height: "30px",
                color: "#FFFFFF",
                cursor: "pointer",
            }}
            onClick={() => {
                props.handleSubmit();
            }}
            variant="contained"
            size="small"
            disabled={props.checkForNextBtnDisableCondition() ? false : true}
            data-testid="NextButton"
        >
            {" "}
            <BackNextText
                text={NEXT}
            />
            <NavigateNextIcon
                fontSize="small"
                sx={{
                    fontSize: "15px",
                    marginRight: "-5px",
                    paddingLeft: "5px",
                }}
            />
        </Button>
    )
}

export default NextButton