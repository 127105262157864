/* eslint-disable @typescript-eslint/no-unused-vars */
import { Backdrop, CircularProgress, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePineDataActions } from "../../../store/uploadPineSlice";
import { unixTimestampToString } from "../../../utility/commonFunction";

const getUserEmailID = (user_details:any) => { 
  if(user_details?.includes('@')){
    return user_details;
   }else {
     return;
   }
}

function descendingComparator(a: any, b: any, orderBy: any) {
  if (orderBy === "") {
    return 0;
  }
  if (orderBy === "modifiedAt" || orderBy === "date") {
    const dayA = Number(a[orderBy].substring(0, 2));
    const monthA = Number(a[orderBy].substring(3, 5));
    const yearA = Number(a[orderBy].substring(6, 10));
    const dayB = Number(b[orderBy].substring(0, 2));
    const monthB = Number(b[orderBy].substring(3, 5));
    const yearB = Number(b[orderBy].substring(6, 10));
    if (yearB < yearA) {
      return -1;
    }
    if (yearB > yearA) {
      return 1;
    }
    if (yearB === yearA) {
      if (monthB < monthA) return -1;
      if (monthB > monthA) return 1;
      if (monthB === monthA) {
        if (dayB < dayA) return -1;
        if (dayB > dayA) return 1;
      }
    }
    return 0;
  }
  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
    return -1;
  }
  if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
    return 1;
  }
  return 0;
}

function getComparator(order: any, orderBy: any) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any, comparator: any): any {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

function EnhancedTableHead(props: any) {
  const { order, orderBy, headCells, isIcon, onRequestSort } = props;
  const createSortHandler = (property: any) => (event: any) => {
    // const data = handleFilterData(property);
    // onRequestSort(event, data);
  };
  return (
    <TableHead style={{ backgroundColor: "#E8ECF1" }}>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell}
            // sortDirection={
            //   orderBy === handleFilterData(headCell) ? order : false
            // }
            sx={{ overflowWrap: "anywhere", width: "100px" }}
            align="left"
            className="headcell"
          >
              {headCell}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
          </TableCell>
        ))}
        {isIcon && (
          <TableCell
            sx={{ overflowWrap: "anywhere", width: "100px" }}
            align="left"
            className="headcell"
          >
            Actions
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

interface TableProps {
  isHistoryLogs?: any;
  isUploadReport?: any;
  rows?: any;
  columns?: any;
  isIcon?: any;
  handleIconClick?: any;
}
export const TableWrapper = ({
  isHistoryLogs,
  isUploadReport,
  rows,
  columns,
  isIcon,
  handleIconClick,
}: TableProps) => {
  const dispatch = useDispatch();
  const backdropValueFromStore = useSelector(
    (state: any) => state.updatePineData.backdrop
  );
  const selectedFileName = useSelector(
    (state: any) => state.updatePineData.singleFileName
  );
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  useEffect(()=>{
    if (rows?.length === 0) {
      dispatch(updatePineDataActions.setBackdrop({ backdrop: true }));
    } else {
      dispatch(updatePineDataActions.setBackdrop({ backdrop: false }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const rowValues = Object.values(rows);
  setTimeout(() => {
    dispatch(updatePineDataActions.setBackdrop({ backdrop: false }));
  }, 6000);
  let objToAddHistory: any = {};
  let objToAddUploadLogs: any = {};
  let objToAddUploadTableDb: any = {};
  const finalArrHistory: any[] = [];
  const finalArrUploads: any[] = [];
  const finalArrGameDb: any[] = [];
  if (isUploadReport) {
    rowValues.forEach((obj: any) => {
      objToAddUploadLogs.job_id = obj?.job_id;
      objToAddUploadLogs.created_at = unixTimestampToString(obj?.created_at);
      objToAddUploadLogs.user_detail = getUserEmailID(obj?.user_detail);
      objToAddUploadLogs.file_details = obj?.file_details;
      objToAddUploadLogs.updated_at = obj?.upload_start_time;
      objToAddUploadLogs.status = obj?.status.charAt(0).toUpperCase() + obj?.status.slice(1);
      objToAddUploadLogs.parent_metadata_sheet = selectedFileName;
      finalArrUploads.push(objToAddUploadLogs);
      objToAddUploadLogs = {};
    });
  } else if (isHistoryLogs) {
    rowValues.forEach((obj: any) => {
      objToAddHistory.job_id = obj?.job_id;
      objToAddHistory.created_at = unixTimestampToString(obj?.created_at);
      objToAddHistory.updated_at = obj?.updated_at;
      objToAddHistory.reference_id = obj?.reference_id;
      objToAddHistory.user_details = getUserEmailID(obj?.user_details);
      objToAddHistory.file_details = obj?.file_details;
      objToAddHistory.message = obj?.message;
      objToAddHistory.error = obj?.error
      finalArrHistory.push(objToAddHistory);
      objToAddHistory = {};
    });
  } else {
    rowValues.forEach((obj: any) => {
      objToAddUploadTableDb.reference_id = obj?.reference_id;
      objToAddUploadTableDb.filename = obj?.filename;
      objToAddUploadTableDb.status = obj?.status;
      objToAddUploadTableDb.file_type = obj?.file_type;
      objToAddUploadTableDb.thumbnail_filename = obj?.thumbnail_filename;
      objToAddUploadTableDb.thumbnail_status = obj?.thumbnail_status;
      objToAddUploadTableDb.audio_descriptor_filename = obj?.audio_descriptor_filename;
      objToAddUploadTableDb.audio_descriptor_status = obj?.audio_descriptor_status;
      objToAddUploadTableDb.nav_xml_filename = obj?.nav_xml_filename;
      objToAddUploadTableDb.nav_status = obj?.nav_status;
      objToAddUploadTableDb.caption_filenames = obj?.caption_filenames;
      objToAddUploadTableDb.caption_status = obj?.caption_status;
      objToAddUploadTableDb.other_filenames = obj?.other_filenames;
      objToAddUploadTableDb.other_status = obj?.other_status;
      objToAddUploadTableDb.business_unit = obj?.business_unit;
      objToAddUploadTableDb.job_id = obj?.job_id;
      objToAddUploadTableDb.created_at = unixTimestampToString(obj?.created_at);
      objToAddUploadTableDb.updated_at = obj?.updated_at;
      objToAddUploadTableDb.last_updated = obj?.last_updated;
      objToAddUploadTableDb.email = obj?.email;
      objToAddUploadTableDb.media_title = obj?.media_title;
      objToAddUploadTableDb.unit = obj?.unit;
      objToAddUploadTableDb.topic = obj?.topic;
      objToAddUploadTableDb.sub_topic = obj?.sub_topic;
      objToAddUploadTableDb.video_type = obj?.video_type;
      objToAddUploadTableDb.discipline = obj?.discipline;
      objToAddUploadTableDb.program = obj?.program;
      objToAddUploadTableDb.content_type = obj?.content_type;
      objToAddUploadTableDb.added_on = obj?.added_on;
      objToAddUploadTableDb.keywords = obj?.keywords;
      objToAddUploadTableDb.content_class = obj?.content_class;
      objToAddUploadTableDb.playlist_id = obj?.playlist_id;
      objToAddUploadTableDb.clutch_video_id = obj?.clutch_video_id;
      objToAddUploadTableDb.caption_language = obj?.caption_language;
      objToAddUploadTableDb.description = obj?.description;
      objToAddUploadTableDb.sms_authentication = obj?.sms_authentication;
      finalArrGameDb.push(objToAddUploadTableDb);
      objToAddUploadTableDb = {};
    });
  }

  let finalArr = [];
  if (isUploadReport) {
    finalArr = finalArrUploads
  } else if (isHistoryLogs) finalArr = finalArrHistory;
  else finalArr = finalArrGameDb;
  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer
          sx={
            stableSort(finalArr, getComparator(order, orderBy))?.length === 0 &&
            isHistoryLogs === true
              ? {}
              : { width: "100%" }
          }
        >
          <Table
            sx={
              stableSort(finalArr, getComparator(order, orderBy))?.length === 0 &&
              isUploadReport ? { minWidth: 750, width: "100%" } :
              isHistoryLogs === true
                ? { minWidth: 750 }
                : { minWidth: 750, width: "max-content" }
            }
            size="small"
          >
            <EnhancedTableHead
              isIcon={isIcon}
              headCells={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {stableSort(finalArr, getComparator(order, orderBy))?.length >
                0 ? (
                stableSort(finalArr, getComparator(order, orderBy)).map(
                  (row: any) => (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      {isUploadReport ? (
                        <>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "140px",
                              paddingTop: "11px",
                              paddingBottom: "11px",
                            }}
                            align="left"
                          >
                            {row.job_id}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "140px",
                              paddingTop: "11px",
                              paddingBottom: "11px",
                            }}
                            align="left"
                          >
                            {row.created_at}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "140px",
                              paddingTop: "11px",
                              paddingBottom: "11px",
                            }}
                            align="left"
                          >
                            {row.user_detail}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "140px",
                              paddingTop: "11px",
                              paddingBottom: "11px",
                            }}
                            align="left"
                          >
                            {row.file_details}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "140px",
                              paddingTop: "11px",
                              paddingBottom: "11px",
                            }}
                            align="left"
                          >
                            {row.updated_at}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "140px",
                              paddingTop: "11px",
                              paddingBottom: "11px",
                            }}
                            align="left"
                          >
                            {row.status}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "140px",
                              paddingTop: "11px",
                              paddingBottom: "11px",
                            }}
                            align="left"
                          >
                            {row.parent_metadata_sheet}
                          </TableCell>
                        </>
                      )
                      : isHistoryLogs ? (
                          <>
                            <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "140px",
                              paddingTop: "11px",
                              paddingBottom: "11px",
                            }}
                            align="left"
                          >
                            {row.job_id}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "140px",
                              paddingTop: "11px",
                              paddingBottom: "11px",
                            }}
                            align="left"
                          >
                            {row.created_at}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "300px",
                              paddingTop: "11px",
                              paddingBottom: "11px",
                            }}
                            align="left"
                          >
                            {row.user_details}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "300px",
                              paddingTop: "11px",
                              paddingBottom: "11px",
                            }}
                            align="left"
                          >
                            {row.file_details}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "200px",
                              paddingTop: "11px",
                              paddingBottom: "11px",
                            }}
                            align="left"
                          >
                            {row.message}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "340px",
                              paddingTop: "11px",
                              paddingBottom: "11px",
                            }}
                            align="left"
                          >
                            {row.error}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "150px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.reference_id}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "200px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.email}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "130px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.created_at}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "200px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.filename}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.status}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "70px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.file_type}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "200px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.thumbnail_filename}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "70px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.thumbnail_status}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "200px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.audio_descriptor_filename}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "70px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.audio_descriptor_status}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "200px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.nav_xml_filename}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "70px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.nav_status}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "200px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.caption_filenames}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "200px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.caption_status}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "200px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.other_filenames}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "200px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.other_status}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.business_unit}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "170px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.job_id}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "150px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.updated_at}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.last_updated}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.media_title}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.unit}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.topic}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.sub_topic}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.video_type}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.discipline}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.program}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.content_type}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.added_on}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "200px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.keywords}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.content_class}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.playlist_id}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.clutch_video_id}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "100px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.caption_language}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "150px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.description}
                          </TableCell>
                          <TableCell
                            sx={{
                              overflowWrap: "anywhere",
                              width: "150px",
                              minHeight: "30px",
                            }}
                            align="left"
                          >
                            {row.sms_authentication}
                          </TableCell>
                        </>
                      )}
                      {isIcon && (
                        <TableCell
                          sx={{
                            overflowWrap: "anywhere",
                            width: "100px",
                            minHeight: "30px",
                          }}
                          align="left"
                        >
                          <IconButton
                            data-testid="fetchGameOptions"
                            sx={{ padding: "3px" }}
                            onClick={(e) => {
                              handleIconClick(
                                e,
                                row.id,
                                row.name,
                                row.previewURL,
                                row.exportURL
                              );
                            }}
                          >
                            {isIcon}
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  )
                )
              ) : (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={backdropValueFromStore}
                >
                  <CircularProgress />
                </Backdrop>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
