export const SCREEN_NUMBER_3 = '3 of 3'
export const BACK = 'Back'
export const NEXT = 'Next'
export const UPLOAD_INFO = 'Upload Info'
export const UPLOAD_ASSET = 'Upload Assets'
export const ACCEPTED_TYPE = 'Accepted file types :'
export const UPLOAD_LISTED_ASSET = 'Upload assets listed in your metadata file.'
export const LIMIT_PER_UPLOAD = 'Limit of 2 GB per upload.'
export const DRAG_DROP_FILES = 'Drag and Drop files here'
export const UPLOAD_SUMMARY = 'Upload Summary'
export const ASSET_IDENTIFIED = 'assets identified in your metadata file.'
export const UPLAOD_FAILED_ERR_MSG = 'Upload failed, You can only upload files listed in your metadata file.'
export const TRY_UPLOADING_FILE_AGAIN = 'Below mentioned files are not uploaded, please try again.'
export const DUPLICATE_FILE_ERR_TITLE = 'Error : The items listed below already exists in a different folder. Please update the location or provide a unique Ref ID to add this file to the current folder.'
export const REUPLOAD_WARN_MSG = 'Warning: Would you like to create a new version of these files? If so, click Proceed.'
export const DELETING_NON_PLAEHOLDER_ERR_MSG = 'Error occured while deleting the missing non-placeholder file data.'
export const MISSING_NON_PLAEHOLDER_WARN_MSG = 'Some main video or audio files are missing. If you proceed, the reference IDs assigned to these assets will not be imported to Pine, and the supporting files associated with them will be uploaded without proper stacking. If this is not your intention, please upload main video or audio file now.'
export const PLACEHOLDER_FILE_UPLOAD_WARNING = 'You marked the following lines as being placeholders on your metadata sheet:'
export const PLACEHOLDER_FILE_UPLOAD_WARNING_MSG = 'Placeholders are blank assets with no payload, but video file(s) matching the filenames on the above lines have been provided. If you proceed, these video files will be uploaded and placeholders will not be created.'
export const INVALID_ZIP_FILE = 'Invalid File : You can upload only .zip file'
