import { Button, Grid, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import { browseButtonStyle, processFileButtonStyle, uploadSingleFileContainerStyle } from "../../../styles/sxStyle";
import { useRef } from "react";
import { BROWSE_FILES, DRAG_DROP, PROCESS_FILE, UPLOAD_FAILED_MSG } from "../constants/AddSingleFileConstants";
import ShowErrMsg from "./ShowErrMsg";

interface Props {
  fileName: any
  handleFileDeSelect: any
  showChecked: any
  checkValidFile: any
  checkReqFieldsErr: any
  checkMetadataFileErr: any
  checkMetadataFileTypesErr: any
  checkMetadataFileExtErr: any
  s3FilesFieldsErr: any
  nonS3FilesFieldsErr: any
  duplicateRefIdErr: any
  placeHolderErr: any
  duplicateFileNameErr: any
  handleFileProcessing: () => Promise<void>
  setShowChecked: any
  handleDragOver: any
  handleDrop: any
  handleOnChange: (e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  radioValueselected: any
  selectedCSVFile: any
}

const DragandDropWithErrorMsg = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      {props.selectedCSVFile ? (
        <Grid container>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            xs={12}
            sx={{
              border: "1px solid grey",
              backgroundColor: "#DCE4E9",
              minHeight: "38%",
              marginTop: "2%",
            }}
            data-testid='DragandDropWithErrorMsg'
          >
            <Grid item display="flex" paddingLeft="3px">
              <Grid sx={{ paddingTop: "1%", fontSize: "14px" }}>
                <DescriptionIcon fontSize="small" />
              </Grid>
              <Grid
                item
                sx={{ paddingLeft: "1px", fontSize: "14px" }}
                zeroMinWidth
              >
                <Typography noWrap>{props.fileName}</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              onClick={props.handleFileDeSelect}
              sx={{
                display: "flex",
                alignItems: "center",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "3px",
                fontSize: "14px",
                cursor: "pointer",
              }}
              data-testid='Cancel-Icon'
            >
              <CancelIcon fontSize="small" />
            </Grid>
          </Grid>
          <Grid
            xs={12}
            item
            sx={{
              marginTop: "4%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            {props.showChecked ? (
              props.checkValidFile ||
                props.checkReqFieldsErr ||
                props.checkMetadataFileErr ||
                props.checkMetadataFileTypesErr ||
                props.checkMetadataFileExtErr ||
                // checkRefIdUnderscoreErr ||
                props.s3FilesFieldsErr ||
                props.nonS3FilesFieldsErr ||
                props.duplicateRefIdErr ||
                props.placeHolderErr ||
                props.duplicateFileNameErr ? (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    color: "red",
                    fontSize: "12px",
                  }}
                  data-testid='Error-Icon'
                >
                  <ErrorIcon
                    sx={{ fontSize: "16px", color: "darkred" }}
                  />
                  &nbsp;
                  {"Could not validate metadata"}
                </Grid>
              ) : (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    color: "#006684",
                    fontSize: "12px",
                  }}
                  data-testid='Done-Icon'
                >
                  <DoneIcon sx={{ fontSize: "16px" }} />
                  &nbsp;
                  {"Processing Complete"}
                </Grid>
              )
            ) : (
              <Button
                sx={processFileButtonStyle}
                onClick={() => {
                  props.handleFileProcessing()
                  props.setShowChecked(true)
                }}
                variant="contained"
                size="small"
                data-testid='Process-File'
              >
                {PROCESS_FILE}
              </Button>
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          {" "}
          {props.checkValidFile &&
            (ShowErrMsg(
              UPLOAD_FAILED_MSG
            ))
          }
          <Grid
            item
            container
            marginTop="5px"
            direction="row"
            sx={uploadSingleFileContainerStyle}
            onDragOver={props.handleDragOver}
            onDrop={props.handleDrop}
            data-testid='DragandDrop'
          >
            <Grid
              item
              xs={12}
              sx={{ fontSize: "12px", textAlign: "center" }}
            >
              {DRAG_DROP}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                fontSize: "12px",
                textAlign: "center",
                marginTop: "1%",
              }}
            >
              Or
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ fontSize: "12px", textAlign: "center" }}
            >
              <input
                type="file"
                accept=".xlsx, .csv"
                id="docFile"
                onChange={props.handleOnChange}
                hidden
                ref={inputRef}
              />
              <Button
                sx={browseButtonStyle}
                onClick={() => inputRef.current?.click()}
                variant="contained"
                size="small"
                disabled={props.radioValueselected ? false : true}
                data-testid='Browse-File'
              >
                {BROWSE_FILES}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default DragandDropWithErrorMsg