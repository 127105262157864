import { Grid, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface Props {
    searchQuery: any,
    setSearchQuery: any
    startValue: any
    endValue: any
    totalValue: any
    tableObjArr: any
    searchedDataStartPageNoDecrement: any
    searchedDataStartPageNoIncrement: any
    currentPageDecrement: any
    currentPageIncrement: any
}

const CustomToggle = (props: Props) => {
    return (
        <Grid
            item
            container
            direction="row"
            sx={{
                marginTop: "3.5%",
                marginBottom: "4%",
                position: "relative",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
            }}
            data-testid = 'CustomToggle'
        >
            <Grid
                item
                xs={4}
                sx={{
                    fontSize: "12px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "center",
                }}
            >
                {props.startValue + 1} - {props.endValue} of{" "}
                {props.searchQuery ? props.totalValue : props.tableObjArr.length}
                <IconButton
                    sx={{ padding: "0px" }}
                    onClick={() => {
                        props.searchQuery
                            ? props.searchedDataStartPageNoDecrement()
                            : props.currentPageDecrement();
                    }}
                    disabled={
                        props.startValue === -1
                            ? props.startValue + 1 === 0
                            : props.startValue + 1 === 1
                    }
                    data-testid = 'BackButton'
                >
                    <ArrowBackIosIcon
                        sx={{ height: "10px", cursor: "pointer" }}
                        fontSize="small"
                    />
                </IconButton>
                <IconButton
                    sx={{ padding: "0px" }}
                    disabled={
                        props.searchQuery
                            ? props.endValue === props.totalValue
                            : props.endValue === props.tableObjArr.length
                    }
                    onClick={() => {
                        props.searchQuery
                            ? props.searchedDataStartPageNoIncrement()
                            : props.currentPageIncrement();
                    }}
                    data-testid = 'NextButton'
                >
                    <ArrowForwardIosIcon
                        sx={{ height: "10px", cursor: "pointer" }}
                        fontSize="small"
                    />
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default CustomToggle