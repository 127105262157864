import { Button } from "@mui/material"
import { SUBMIT } from "../../constant/InputFormConstants"

interface Props {
    submitHandler: any,
    checkAnyNewDataAdded: any
}

const SubmitButton = (props: Props) => {
    return (
        <Button
            variant="contained"
            size="small"
            sx={{ backgroundColor: "#006FA6", textTransform: "capitalize" }}
            onClick={props.submitHandler}
            disabled={props.checkAnyNewDataAdded() ? false : true}
            data-testid="SubmitButton"
        >
            {SUBMIT}
        </Button>
    )
}

export default SubmitButton