import { Typography } from '@mui/material';
import Box from '@mui/material/Box'

const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: "white",
                paddingY: "5px",
                position: "fixed",
                bottom: "0",
                width: "100%",
            }}
            data-testid="Footer"
        >
            <Typography display='flex'
                justifyContent='center'
                alignItems='center' variant='subtitle2' style={{ fontWeight: '600px', marginTop: 'auto' }}>© Copyright, Pearson Inc. All Rights Reserved.</Typography>
        </Box>
    )
}

export default Footer;