import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Button, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updatePineDataActions } from "../../../../store/uploadPineSlice";
import { takeBack1ButtonStyle } from "../../../../styles/sxStyle";
import { BACK } from "../../constants/ShowUploadAssestsConstants";

const BackButton = (): JSX.Element => {
    const location: any = useLocation();
    const dispatch: any = useDispatch()
    const navigate = useNavigate();
    const navigateToPreviousPage = () => {
        dispatch(
            updatePineDataActions.updateCsvFileDataWithStatus({
              csvFileDataWithStatus: [],
            })
          );
          dispatch(
            updatePineDataActions.updateMissingFilesData({
              missingFilesData: [],
            })
          );
          dispatch(
            updatePineDataActions.setRemovedUploadedFiles({
              removedUploadedFiles: [],
            })
          );
          dispatch(
            updatePineDataActions.updatefilenameWithJobIdCreatedAtArray({
              filenameWithJobIdCreatedAtArray: [],
            })
          );
          dispatch(
            updatePineDataActions.updateAllFilesWithJobIdCreatedAtArray({
              allFilesWithJobIdCreatedAtArray: [],
            })
          );
          dispatch(
            updatePineDataActions.updateMultipleFileCount({ multipleFileCount: 0 })
          );
          dispatch(
            updatePineDataActions.updateTotalUploadingFilesCount({ totalUploadingFilesCount: 0 })
          );
        navigate("/pine/addSingleFile", {
            state: {
                previousUrl: location?.pathname,
            },
        });
    };
    return (
        <Button
            sx={takeBack1ButtonStyle}
            data-testid="BackButton"
            variant="outlined"
            size="small"
            onClick={() => {
                navigateToPreviousPage();
            }}
        >
            <KeyboardArrowLeftIcon
                sx={{
                    fontSize: "15px",
                    marginLeft: "-5px",
                    paddingRight: "3px",
                }}
            />{" "}
            <Typography
                sx={{
                    textTransform: "capitalize",
                    fontSize: "12px",
                    fontWeight: "600",
                }}
            >
                {BACK}
            </Typography>
        </Button>
    )
}

export default BackButton