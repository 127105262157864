import { Grid, Typography } from "@mui/material"
import { UPLOAD_INFO } from "../../constants/UploadMultipleAssetsContants"

const UploadInfoText = () => {
    return (
        <Grid item>
            <Typography gutterBottom sx={{ fontSize: "16px" }} data-testid="UploadInfoText">
              {UPLOAD_INFO}
            </Typography>
          </Grid>
    )
}

export default UploadInfoText