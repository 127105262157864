import { Grid, Typography } from "@mui/material"

const SubTexts = () => {
    return (
        <>
            <Grid item data-testid = 'SubTexts'>
              <Typography gutterBottom sx={{ fontSize: "16px" }}>
                Upload metadata file
              </Typography>
            </Grid>
            <Grid item>
              <Typography gutterBottom sx={{ fontSize: "11px" }}>
                Upload your completed metadata file.
              </Typography>
            </Grid>
        </>
    )
}

export default SubTexts