import { Grid, Typography } from "@mui/material"
import { UPLOAD_LISTED_ASSET } from "../../constants/UploadMultipleAssetsContants"
import { useSelector } from "react-redux";

const UploadListedAssetText = () => {
  const allowedFileUploadSizeFromStore = useSelector((state: any) => state.updatePineData.allowedFileUploadSize);

    return  (
        <Grid item data-testid="UploadListedAssetText">
            <Typography
              gutterBottom
              sx={{
                fontSize: "12px",
                fontFamily: "Open Sans",
                fontWeight: "400",
              }}
            >
              {UPLOAD_LISTED_ASSET}{" "}
              <b>{`Limit per upload is ${allowedFileUploadSizeFromStore} GB.`}</b>
            </Typography>
          </Grid>
    )
}

export default UploadListedAssetText