import { Grid } from "@mui/material"
import ScreenNumber from "../atoms/ScreenNumber";
import BackButton from "../atoms/Buttons/BackButton";
import ViewInPineLink from "../atoms/Links/ViewInPineLink";

const SubHeader = (): JSX.Element => {
    return (
        <Grid
          container
          item
          paddingY={1}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
          data-testid="SubHeader"
        >
          <ScreenNumber/>
          <Grid
            item
            xs={10}
            display="flex"
            justifyContent="flex-end"
            columnGap={2}
          >
            <BackButton/>
            <ViewInPineLink/>
          </Grid>
        </Grid>
    )
}

export default SubHeader