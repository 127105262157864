import { Grid } from "@mui/material"
import PageToggle from "./PageToggle"

interface Props {
  isMissingBtn: any,
  currentPageDecrement: () => void,
  currentPageIncrement: () => void
}

const PageToggleWithGrid = (props: Props) => {
  return (
    <Grid
      item
      container
      direction="row"
      sx={{
        marginTop: "3.5%",
        marginBottom: "4%",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
      data-testid="PageToggleWithGrid"
    >
      <PageToggle
        isMissingBtn={props.isMissingBtn}
        currentPageDecrement={props.currentPageDecrement}
        currentPageIncrement={props.currentPageIncrement}
      />
    </Grid>
  )
}

export default PageToggleWithGrid