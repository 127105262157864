import { Button } from "@mui/material"
import { Link } from "react-router-dom";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch, useSelector } from "react-redux";
import { updatePineDataActions } from "../../../../store/uploadPineSlice";
import { BULK_UPLOAD } from "../../constants/ShowUploadAssestsConstants";

const BulkUploadButton = (): JSX.Element => {
    const dispatch = useDispatch()
    const userMailFromStore = useSelector(
        (state: any) => state?.updatePineData?.loggedInUserEmail
      );
    const resetAllStoreData = () => {
        dispatch(updatePineDataActions.resetWithPreservedValue(userMailFromStore));
    };
    return (
        <Link
            to="/pine"
            style={{ color: "#006684", textDecoration: "none" }}
            data-testid="BulkUploadButton"
            onClick={() => {
                resetAllStoreData();
            }}
        >
            <Button
                variant="outlined"
                sx={{
                    textTransform: "capitalize",
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#006684",
                }}
            >
                <b>{BULK_UPLOAD}</b>
                <ReplayIcon
                    fontSize="small"
                    sx={{ paddingLeft: "4px", fontSize: "15px" }}
                />
            </Button>
        </Link>
    )
}

export default BulkUploadButton