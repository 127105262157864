import { Button } from '@mui/material'

interface Props {
    handleOnlyModalClose: any,
    processAllData: any,
    removingErrMsgWindow: any,
    uploadAgain: any,
    okayButtonClass: any,
    okBtn: any,
    // warningAlertCheck: any
}

const OkButton = (props: Props) => {
    return (
        <Button
            variant="contained"
            size="small"
            data-testid="OkButton"
            onClick={() => {
                // props.warningAlertCheck();
                props.processAllData();
                props.handleOnlyModalClose();
                if (props.uploadAgain) {
                    props.removingErrMsgWindow();
                }
            }}
            className={props.okayButtonClass}
            sx={{ backgroundColor: "#006FA6", textTransform: "capitalize" }}        >
            {props.okBtn}
        </Button>
    )
}

export default OkButton