/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MaximumSizeText from "../atom/MaximumSizeText";

interface Props {
    isEdit: any
    selectedUploadSize: any,
    setSelectedUploadSize: any,
    setIsEdit: any
}

const SizeInputField = (props: Props) => {
    return (
        <Grid
            display="flex"
            flexDirection="row"
            alignItems="center"
            columnGap={4}
            sx={{ marginTop: "15px", marginLeft: "-4px" }}
            data-testid="SizeInputField"
          >
            <MaximumSizeText/>
            <Grid display="flex" flexDirection="row">
              <input
                type="number"
                style={{ width: "35px" }}
                value={props.selectedUploadSize}
                onChange={(e) => {
                  props.setSelectedUploadSize(e.target.value);
                }}
                disabled={props.isEdit ? false : true}
              />
              &nbsp;GB
              <a style={{ marginLeft: "5px" }} role="button" title="Edit">
                <EditOutlinedIcon
                  style={{ height: "20px" }}
                  data-testid="edit-icon"
                  onClick={() => {
                    props.setIsEdit(true);
                  }}
                />
              </a>
            </Grid>
          </Grid>
    )
}

export default SizeInputField