import { Grid } from "@mui/material"
import PopoverGrid from "../atoms/PopoverGrid"
import SubText from "../atoms/SubText";
import FolderValidation from "../atoms/FolderValidation";

const CreateFolderGrid = (props: any) : JSX.Element => {
    
    return (
    <Grid item data-testid="CreateFolderGrid" container xs={12} paddingY={2} paddingX={2}
        sx={{ borderRadius: "10px", position: "relative", backgroundColor: "white" }}>
        
        <PopoverGrid/>
        <SubText/>
        <FolderValidation {...props}/>

      </Grid>)
}

export default CreateFolderGrid