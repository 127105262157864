import { Grid } from "@mui/material";
import SubmitButton from "../atom/Buttons/SubmitButton";
import CancelButton from "../atom/Buttons/CancelButton";

interface Props {
    resetAll: any,
    setSelectedUploadSize: any,
    submitHandler: any,
    checkAnyNewDataAdded: any,
    previousUploadSize: any
}

const SubCancel = (props: Props) => {
    return (
        <Grid
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            columnGap={2}
            sx={{ marginTop: "25px" }}
            data-testid="SubCancel"
        >
            <CancelButton
                resetAll={props.resetAll}
                setSelectedUploadSize={props.setSelectedUploadSize}
                previousUploadSize={props.previousUploadSize}
            />
            <SubmitButton
                submitHandler={props.submitHandler}
                checkAnyNewDataAdded={props.checkAnyNewDataAdded}
            />
        </Grid>
    )
}

export default SubCancel