import axios from "axios";
import _ from "lodash";
// import cookie from "react-cookies";
import { apiEndpoint, auth, getLOBsEndPoint, getPayloadSizeEndPoint, getloadercleartime, pinePlusEndpoint, uploadEndpoint } from "../constants/appConstant";

export const structuredJson = (
  obj: Array<any>,
  email: string,
  filename: string
) =>
  _.map(obj, function (item, idx) {
    if (item["Media.guid"] && idx) {
      return {
        Filename: filename,
        Email: email,
        Business_Unit: item["Media.CustomField.businessUnit"],
        Caption_Language: item["MediaFile.cc1Language"],
        Content_Class: item["Media.CustomField.contentClass"],
        Content_Type: item["Media.CustomField.contentType"],
        Description: item["Media.description"],
        Discipline: item["Media.CustomField.discipline"],
        File_Type: item["Media.CustomField.contentType"],
        Keywords: item["Media.keywords"],
        Media_Title: item["Media.title"],
        PlayList_ID: item["Media.CustomField.playListID"],
        Program: item["Media.CustomField.program"],
        Reference_ID: item["Media.guid"],
        SMS_Authentication: item["Media.CustomField.authenticate"] || "None",
        Status: "",
        Type: "",
        File_Metadata: {
          Video_URL: item["MediaFile.videoSourceUrl"],
          Thumbnail_URL: item["MediaFile.thumbSourceUrl"],
          chapterXml_URL: item["Media.CustomField.chapterXmlSourceUrl"],
          caption_URL: item["MediaFile.cc1SourceUrl"],
        },
      };
    }
  });

export const getUniqueRefId1 = async () => {
  try {
    let config = {
      method: "get",
      url: `${apiEndpoint}/getNewRefrenceId`,
      headers: {

      },
    };

    let response: any = await axios(config);
    if (response?.data?.referenceId) {
      return response?.data?.referenceId;
    }
  } catch (err) {
    return false;
  }
};

export const structuredJson2 = async (
  obj: Array<any>,
  email: string,
  filename: string
) => {
  let newArr: any = [];
  obj.forEach((item) => {
    newArr.push({
      filename: item["Filename"],
      lob: item["LOB"],
      fileType: item["File Type"],
      mediaTitle: item["Media Title"],
      businessUnit: item["Business Unit"],
      description: item["Description"] || "",
      refrenceId: item["Reference ID"],
      email: item["Email"] || email,
      keywords: item["Keywords"] || "",
      discipline: item["Discipline"] || "",
      program: item["Program"] || "",
      playlistId: item["PlayList ID"] || "",
      smsAuthentication: item["SMS Authentication"] || "",
      captionLanguage: item["Caption Language"] || "",
      thumbnailFilename: item["Thumbnail FileName"] || "",
      audioDescriptorFilename: item["AudioDescriptor FileName"] || "",
      navXMLFileName: item["navXML FileName"] || "",
      captionFileNames: item["Caption FileNames"] || "",
      contentClass: item["Content Class"] || "",
      contentType: item["Content Type"] || "",
      addedOn: item["Added On"] || "",
      lastUpdated: item["Last Updated"] || "",
      clutchVideoID: item["Clutch Video ID"] || "",
      unit: item["Unit"] || "",
      topic: item["Topic"] || "",
      subTopic: item["Sub-topic"] || "",
      videoType: item["Video Type"] || "",
      otherFileNames: item["Other FileNames"] || "",
      placeholder: item["Placeholder"] || "N"
    });
  });
  newArr.forEach((item: any) => {
    let captionFiles = item?.captionFileNames;
    let splittedCaption = captionFiles?.split(";").map(function (value: any) {
      return value.trim();
    });
    let joinedCaptionFiles = splittedCaption.join(';');
    if (joinedCaptionFiles.endsWith(";")) {
      joinedCaptionFiles = joinedCaptionFiles?.substring(
        0,
        joinedCaptionFiles.length - 1
      );
    }
    item.captionFileNames = joinedCaptionFiles;
  })
  newArr.forEach((item: any) => {
    let otherFiles = item?.otherFileNames;
    let splittedOtherFiles = otherFiles?.split(";").map(function (value: any) {
      return value.trim();
    });
    let joinedOtherFiles = splittedOtherFiles.join(';');
    if (joinedOtherFiles.endsWith(";")) {
      joinedOtherFiles = joinedOtherFiles?.substring(
        0,
        joinedOtherFiles.length - 1
      );
    }
    item.otherFileNames = joinedOtherFiles;
  })
  let emptyReferenceArray = newArr.filter((item: any) => {
    return !item.refrenceId;
  });
  if (emptyReferenceArray.length) {
    for (let i = 0; i < emptyReferenceArray.length; i++) {
      let ref = await getUniqueRefId1();
      emptyReferenceArray[i].refrenceId = ref;
    }
  }
  return newArr;
};

export const uploadFile = async (file: File | string | Blob) => {
  // let allCookies: any = document.cookie
  //   .split(";")
  //   .map((cookie) => cookie.split("="))
  //   .reduce(
  //     (accumulator, [key, value]) => ({
  //       ...accumulator,
  //       [key.trim()]: decodeURIComponent(value),
  //     }),
  //     {}
  //   );
  return await axios.post(uploadEndpoint, file, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// export const getCookie = (name: string): string => {
//   let value = " " + document.cookie;
//   let cStart = value.indexOf(" " + name + "=");
//   if (cStart === -1) {
//     value = "";
//   } else {
//     cStart = value.indexOf("=", cStart) + 1;
//     let cEnd = value.indexOf(";", cStart);
//     if (cEnd === -1) {
//       cEnd = value.length;
//     }
//     value = decodeURI(value.substring(cStart, cEnd));
//   }
//   return value;
// };

// export const deleteCookieByName = (name: any) => {
//   cookie.remove(name, {
//     domain: ".pearson.com",
//     path: "/",
//     secure: true,
//   });
//   return true;
// };

export const redirect = (): any => {
  const currentUrl = window.location.href;
  if (
    !(
      window.location.origin.includes("dev") ||
      window.location.origin.includes("qa") ||
      window.location.origin.includes("stg") ||
      window.location.origin.includes("local")
    )
  ) {
    auth.MYCLOUD_URL = 'https://iam.pearson.com/auth/XUI/?realm=/pearson&authIndexType=service&authIndexValue=Login&goto=';
  }
  const redirectUrl = auth.MYCLOUD_URL + encodeURIComponent(currentUrl);
  window.location.replace(redirectUrl);
  return;
};

export const isUserProfileValid = (userProfile: any): any => {
  if (!userProfile?.pearsonUserNickname || userProfile?.pearsonUserNickname === null) {
    return false;
  }
  return true;
};

export const logout = (userId: any, ENV: any) => {

  return fetch(`${apiEndpoint}/aes-logout/`, {
    method: 'GET'
  })
    .then((res) => {
      if (res.status === 200) return res.status;
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};

export const unixTimestampToString = (timestamp: any) => {
  let updatedtimestamp = timestamp / 1000;
  let datetime = new Date(updatedtimestamp * 1000);
  return datetime?.toUTCString();
};

export const dateStringToTimestamp = (strDate: any) => {
  if (strDate) {
    const dt = new Date(strDate).getTime();
    return dt / 1000;
  } else {
    return "";
  }
};

export const getAvailableLOBsFromDB = async () => {
  try {
    let config = {
      method: "get",
      url: `${apiEndpoint}/searchConfig?key=${getLOBsEndPoint}`,
      headers: {

      },
    };
    let response: any = await axios(config);
    if (response?.data?.data[0]?.value) {
      return JSON.parse(response?.data?.data[0]?.value);
    }
  } catch (err) {
    return ["HE", "ELL", "PVS", "Assessments", "WFS", "Technology", "DirectToConsumer","HR Learning and Development"];
  }
};

export const getClearIdFromDB = async () => {
  try {
    let config = {
      method: "get",
      url: `${apiEndpoint}/searchConfig?key=${getloadercleartime}`,
    };
    let response: any = await axios(config);
    return JSON.parse(response?.data?.data[0]?.value)
  } catch (err) {
    return 300000;
  }
}

export const getFilesUploadSizeFromDB = async () => {
  try {
    let config = {
      method: "get",
      url: `${apiEndpoint}/searchConfig?key=${getPayloadSizeEndPoint}`,
      headers: {

      },
    };
    let response: any = await axios(config);
    if (response?.data?.data[0]?.value) {
      return JSON.parse(response?.data?.data[0]?.value);
    }
  } catch (err) {
    return 2;
  }
};

export const getTokenForCheckingRefIdPresentInPine = async () => {
  try {
    let config = {
      method: 'get',
      url: `${pinePlusEndpoint}/API/Authentication/v1.0/Login?Login=smcchinta@gmail.com&Password=Pearson8*&format=json`,
      headers: {
        'Cookie': 'AWSALB=ctQKbvacI6KAvjBXLky1w4QcDluuUZuoIFBakC1Hy8eOuqNtp6pZeXUDsf23hBGjYZGOtaPzLrzQuXMcEx4v+QiEFgLtCWpccs1ydEqHMggCNu3DpFUFQFf/n5xt; AWSALBCORS=ctQKbvacI6KAvjBXLky1w4QcDluuUZuoIFBakC1Hy8eOuqNtp6pZeXUDsf23hBGjYZGOtaPzLrzQuXMcEx4v+QiEFgLtCWpccs1ydEqHMggCNu3DpFUFQFf/n5xt; PS3-Session__162A16FE=hg11t1ziy3hrmk3r3izw0nse; PS3-Session__162A16FE-Alt=hg11t1ziy3hrmk3r3izw0nse'
      }
    };

    let response: any = await axios(config);
    if (response?.data?.APIResponse?.Code === "SUCCESS") {
      return response?.data?.APIResponse?.Token;
    }
  } catch (err) {
    console.log('Generate Pine Token err : ', err)
    return false;
  }
};

export const getMonthName = (monthIndex: any) => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  return months[monthIndex];
}

export const getCurrentYearAndMonth = () => {
  const now = new Date();
  const year = now.getFullYear();
  const monthIndex = now.getMonth();
  const month = getMonthName(monthIndex);
  return `${year}/${month}`;
}