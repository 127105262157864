import { Backdrop, CircularProgress, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { useSelector } from "react-redux";

interface Props {
    resultArray: any[],
    effectiveProgress: any,
    selectedfiles: any,
    totalFileUploadCount: any
}

const RenderTable = (props: Props) => {
    const backdropValueFromStore = useSelector((state: any) => state.updatePineData.backdrop);

    return (
        <>
            <Grid
                container
                paddingBottom={2}
                spacing={1}
                data-testid="RenderTable"
                sx={{ display: "flex", justifyContent: "end", flexDirection: "column" }}
            >
                <Grid item container sx={{ display: "flex", flexDirection: "row" }}>
                    <Grid item xs={1}>
                        {Math.ceil(props.effectiveProgress) < 100 ? <UpgradeIcon /> : <DoneIcon />}
                    </Grid>
                    <Grid item xs={9}>
                        <Typography textAlign="left" sx={{ color: "#006FA6" }}>
                            {Math.ceil(props.effectiveProgress) === 0
                                ? `Ready to upload ${props.selectedfiles.length} files`
                                : 0 < Math.ceil(props.effectiveProgress) &&
                                    Math.ceil(props.effectiveProgress) < 100
                                    ? `Uploading ${props.totalFileUploadCount} files`
                                    : `Uploaded ${props.totalFileUploadCount} files`}
                        </Typography>
                    </Grid>
                    {!isNaN(props.effectiveProgress) && Math.ceil(props.effectiveProgress) > 0 && (
                        <Grid item xs={2}>
                            <Typography>{Math.ceil(props.effectiveProgress)}%</Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    <LinearProgress
                        variant="determinate"
                        value={Math.ceil(props.effectiveProgress)}
                        sx={{ color: "#006FA6" }}
                    />
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead sx={{ backgroundColor: "#E8ECF1" }}>
                        <TableRow>
                            <TableCell align="left" className="headcell">
                                File Name
                            </TableCell>
                            <TableCell align="left" className="headcell">
                                File Size
                            </TableCell>
                            <TableCell align="left" className="headcell">
                                Progress
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.resultArray?.length > 0 ? props.resultArray.map((row: any, index: any) => (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={`${row.filename}-${index}`}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell align="left">{row.filename}</TableCell>
                                <TableCell align="left">{row.filesize}</TableCell>
                                <TableCell align="left">{row.progress}</TableCell>
                            </TableRow>
                        )) : <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdropValueFromStore}>
                            <CircularProgress />
                        </Backdrop>}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};

export default RenderTable