import DescriptionIcon from "@mui/icons-material/Description";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import UploadInfoText from "../Texts/UploadInfoText";

const FolderAndDescriptionIcon = () => {

    const linkedPineFolder = useSelector(
        (state: any) => state.updatePineData.linkedPineFolder
      );
      const selectedFileName = useSelector(
        (state: any) => state.updatePineData.singleFileName
      );

    return (
        <Grid
          item
          xs={12}
          sx={{
            borderRadius: "10px",
            padding: "2%",
            backgroundColor: "white",
          }}
          data-testid="FolderAndDescriptionIcon"
        >
          <UploadInfoText/>
          <Grid item container direction="row" sx={{ fontSize: "12px" }}>
            <Grid item>
              <FolderOpenIcon fontSize="small" />
            </Grid>
            <Grid
              item
              sx={{ padding: "0.4%", marginLeft: "1%", color: "#006684" }}
            >
              {linkedPineFolder}
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            sx={{ fontSize: "12px", marginTop: "1%" }}
          >
            <Grid item sx={{}}>
              <DescriptionIcon fontSize="small" />
            </Grid>
            <Grid
              item
              sx={{ fontSize: "12px", padding: "0.4%", marginLeft: "1%" }}
              zeroMinWidth
            >
              <Typography sx={{ fontSize: "12px" }} noWrap>
                {selectedFileName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
    )
}

export default FolderAndDescriptionIcon