import { Grid, Typography } from "@mui/material"
import { UPLOAD_ASSET } from "../../constants/UploadMultipleAssetsContants"

const UploadAssetText = () => {
    return (
        <Grid item xs={6}>
            <Typography gutterBottom sx={{ fontSize: "16px" }} data-testid="UploadAssetText">
              {UPLOAD_ASSET}
            </Typography>
          </Grid>
    )
}

export default UploadAssetText