/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UploadReportColumns, apiEndpoint, filterErrLogsURL, historyErrTableColumns, historyUploadTableColumns } from "../../../constants/appConstant";
import { updatePineDataActions } from "../../../store/uploadPineSlice";
import { dateStringToTimestamp } from "../../../utility/commonFunction";
import Compare from "../atoms/Buttons/LoadMoreButton";
import TitleText from "../atoms/TitleText";
import ButtonsWithGrid from "../molecules/ButtonsWithGrid";
import ErrorLogGrid from "../molecules/ErrorLogGrid";
import HistoryLogGrid from "../molecules/HistoryLogGrid";
import { TableWrapper } from "./TableWrapper";

const HistoryLogs = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const historyLogsDataFromStore = useSelector(
    (state: any) => state.updatePineData.historyLogsData
  );
  const uploadTableLogsDataFromStore = useSelector(
    (state: any) => state.updatePineData.uploadTableLogsData
  );
  const uploadLogsFromStore = useSelector(
    (state: any) => state.updatePineData.uploadReportData
  );
  const filteredErrorLogsDataValue = useSelector(
    (state: any) => state.updatePineData.filteredErrorLogsData
  );
  const filteredHistoryLogsDataValue = useSelector(
    (state: any) => state.updatePineData.filteredHistoryLogsData
  );
  const jobIdCreatedAtArrFromStore = useSelector(
    (state: any) => state.updatePineData.jobIdCreatedAtArray
  );
  const csvFileData = useSelector(
    (state: any) => state.updatePineData.csvFileData
  );
  const uploadReportFlag = useSelector(
    (state: any) => state.updatePineData.uploadReportFlag
  );

  const [isHistoryLogs, setIsHistoryLogs] = useState(true);
  const [jobIdFromUrl, setjJobIdFromUrl] = useState('');
  const [isUploadReport, setIsUploadReport] = useState(false);
  const [viewUploadReport, setViewUploadReport] = useState(false);
  const [searchedUserDetails, setSearchedUserDetails] = useState("");
  const [searchedUserDetails2, setSearchedUserDetails2] = useState("");
  const [searchedCreatedAt, setSearchedCreatedAt] = useState("");
  const [referenceId, setReferenceId] = useState("");
  const [searchedStatus, setSearchedStatus] = useState("");
  const [responseApiData, setResponseApiData] = useState<any>({});
  const [errLogsCount, setErrLogsCount] = useState(10);
  const [historyLogsCount, setHistoryLogsCount] = useState(10);
  const [uploadLogsCount, setUploadLogsCount] = useState(10);
  const [filteredErrCount, setFilteredErrCount] = useState(10);
  const [filteredHistoryCount, setFilteredHistoryCount] = useState(10);
  const [isfilteredError, setIsFilteredError] = useState(false);
  const [isfilteredHistory, setIsFilteredHistory] = useState(false);
  let errLogs: any[] = [];
  let remainingErrLogs: any[] = [];
  let historyLogs: any[] = [];
  let uploadLogs: any[] = [];
  let remainingHistoryLogs: any[] = [];
  let remainingUploadLogs: any[] = [];
  let filteredErrLogs: any[] = [];
  let remainingFilteredErrLogs: any[] = [];
  let filteredHistoryLogs: any[] = [];
  let remainingFilteredHistoryLogs: any[] = [];

  const loadMoreErrLogs = () => {
    if (historyLogsDataFromStore?.length > 0) {
      errLogs = historyLogsDataFromStore.slice(0, errLogsCount);
      remainingErrLogs = historyLogsDataFromStore.slice(errLogsCount);
    }
  };

  const loadMoreHistoryLogs = () => {
    if (uploadTableLogsDataFromStore?.length > 0) {
      historyLogs = uploadTableLogsDataFromStore.slice(0, historyLogsCount);
      remainingHistoryLogs = uploadTableLogsDataFromStore.slice(historyLogsCount);
    }
  };

  const loadMoreUploadLogs = () => {
    if (uploadLogsFromStore?.length > 0) {
      uploadLogs = uploadLogsFromStore.slice(0, uploadLogsCount);
      remainingUploadLogs = uploadLogsFromStore.slice(uploadLogsCount);
    }
  };

  const loadMoreForFilteredErrLogs = () => {
    if (filteredErrorLogsDataValue?.length > 0) {
      filteredErrLogs = filteredErrorLogsDataValue.slice(0, filteredErrCount);
      remainingFilteredErrLogs = filteredErrorLogsDataValue.slice(filteredErrCount);
    }
  };

  const loadMoreForFilteredHistoryLogs = () => {
    if (filteredHistoryLogsDataValue?.length > 0) {
      filteredHistoryLogs = filteredHistoryLogsDataValue.slice(
        0,
        filteredHistoryCount
      );
      remainingFilteredHistoryLogs =
        filteredHistoryLogsDataValue.slice(filteredHistoryCount);
    }
  };

  const extractFileDataInMetaDataSheet = (extractedData: any) => {
    let filenamesArray: any = []
    csvFileData?.forEach((item: any) => {
      if (item['fieldName'] && item['fieldName'] !== '') {
        filenamesArray.push(item['fieldName'].split('/').pop())
      }
    })
    // eslint-disable-next-line array-callback-return
    const filterDataArray = extractedData.data.filter((filename: any) =>
      filenamesArray.some((item: any) => item === filename?.file_details)
    );
    return { data: filterDataArray }
  }

  const extractDataFromResponse = (data: any) => {
    let array: any = []
    data?.forEach((item: any) => {
      if (item.hasOwnProperty('filename') && item?.filename !== '') {
        const obj: any = {}
        obj.job_id = item.job_id
        obj.created_at = item?.created_at
        obj.user_detail = item.email
        obj.file_details = item.filename
        obj.upload_start_time = item.updated_at
        obj.status = item.status
        array.push(obj)
      }
      if (item.hasOwnProperty('audio_descriptor_filename') && item?.audio_descriptor_filename !== '') {
        const obj: any = {}
        obj.job_id = item.job_id
        obj.created_at = item?.created_at
        obj.user_detail = item.email
        obj.file_details = item.audio_descriptor_filename
        obj.upload_start_time = item.updated_at
        obj.status = item.status
        array.push(obj)
      }
      if (item.hasOwnProperty('thumbnail_filename') && item?.thumbnail_filename !== '') {
        const obj: any = {}
        obj.job_id = item.job_id
        obj.created_at = item?.created_at
        obj.user_detail = item.email
        obj.file_details = item.thumbnail_filename
        obj.upload_start_time = item.updated_at
        obj.status = item.status
        array.push(obj)
      }
      if (item.hasOwnProperty('nav_xml_filename') && item?.nav_xml_filename !== '') {
        const obj: any = {}
        obj.job_id = item.job_id
        obj.created_at = item?.created_at
        obj.user_detail = item.email
        obj.file_details = item.nav_xml_filename
        obj.upload_start_time = item.updated_at
        obj.status = item.status
        array.push(obj)
      }
      if (item.hasOwnProperty('caption_filenames') && item?.caption_filenames !== '') {
        let captionFileStatusArr = item?.caption_status?.split(",");
        captionFileStatusArr?.forEach((captionFileWithStatus: any) => {
          const obj: any = {}
          let lastUnderscoreIndex = captionFileWithStatus.lastIndexOf('_');
          obj.job_id = item.job_id
          obj.created_at = item?.created_at
          obj.user_detail = item.email
          obj.file_details = captionFileWithStatus.substring(0, lastUnderscoreIndex)
          obj.status = captionFileWithStatus.substring(lastUnderscoreIndex + 1)
          obj.upload_start_time = item.updated_at
          array.push(obj)
        })
      }
      if (item.hasOwnProperty('other_filenames') && item?.other_filenames !== '') {
        let otherFileStatusArr = item?.other_status?.split(",");
        otherFileStatusArr?.forEach((otherFileWithStatus: any) => {
          const obj: any = {}
          obj.job_id = item.job_id
          obj.created_at = item?.created_at
          obj.user_detail = item.email
          let lastUnderscoreIndex = otherFileWithStatus.lastIndexOf('_');
          obj.file_details = otherFileWithStatus.substring(0, lastUnderscoreIndex)
          obj.status = otherFileWithStatus.substring(lastUnderscoreIndex + 1)
          obj.upload_start_time = item.updated_at
          array.push(obj)
        })
      }
    })
    return { data: array }
  }

  const fetchErrLogsAPIData = () => {
    let config = {
      method: 'post',
      url: filterErrLogsURL,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        "lastKey": null,
        // "limit": 10,
      })
    };

    axios(config)
      .then((response: any) => {
        setResponseApiData(response?.data);
      })
      .catch((error: any) => {
        console.log("filterLogHistory api error : ", error);
      });

  };

  const fetchHistoryLogsData = () => {
    let config = {
      method: "post",
      url: `${apiEndpoint}/testUploadJobFilter`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        lastKey: null,
        // limit: 10,
      }),
    };

    axios
      .request(config)
      .then((response: any) => {
        setResponseApiData(response?.data);
      })
      .catch((error: any) => {
        console.log('filterUploadJob api error : ', error);
      });
  };

  const fetchUploadReportData = async (uploadReportType?: String) => {
    if (viewUploadReport && uploadReportType === 'viewUploadReport') {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiEndpoint}/getreportData`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          jobId: jobIdFromUrl
        })
      };
      axios
        .request(config)
        .then((response: any) => {
          let insertedDataArray = response?.data?.insertedData
          let updatedDataArray = response?.data?.updatedData
          dispatch(updatePineDataActions.updateSingleFileName({ singleFileName: insertedDataArray?.length > 0 ? insertedDataArray[0]?.central_metadata : updatedDataArray[0]?.central_metadata }));
          let extractedData = extractDataFromResponse([...insertedDataArray, ...updatedDataArray])
          setResponseApiData(extractedData);
          dispatch(updatePineDataActions.setBackdrop({ backdrop: false }));
        })
        .catch((error: any) => {
          console.log('filterUploadJob api error : ', error);
          dispatch(updatePineDataActions.setBackdrop({ backdrop: false }));
        });
    } else if (viewUploadReport && uploadReportType === 'viewZipUploadReport') {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiEndpoint}/zipReportData`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          trackingJobId: jobIdFromUrl
        })
      };
      axios
        .request(config)
        .then((response: any) => {
          let responseDataArray = response?.data?.data
          dispatch(updatePineDataActions.updateSingleFileName({ singleFileName: responseDataArray[0]?.central_metadata }));
          let extractedData = extractDataFromResponse(responseDataArray)
          setResponseApiData(extractedData);
          dispatch(updatePineDataActions.setBackdrop({ backdrop: false }));
        })
        .catch((error: any) => {
          console.log('filterUploadJob api error : ', error);
          dispatch(updatePineDataActions.setBackdrop({ backdrop: false }));
        });
    } else {
      let config = {
        method: "post",
        url: `${apiEndpoint}/getFileStatus`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ searchdata: jobIdCreatedAtArrFromStore }),
      };
      axios
        .request(config)
        .then((response: any) => {
          let extractedData = extractDataFromResponse(response?.data?.data)
          const filteredData = extractFileDataInMetaDataSheet(extractedData)
          setResponseApiData(filteredData);
          dispatch(updatePineDataActions.setBackdrop({ backdrop: false }));
        })
        .catch((error: any) => {
          console.log('filterUploadJob api error : ', error);
          dispatch(updatePineDataActions.setBackdrop({ backdrop: false }));
        });
    }
  }

  const handleLoadMore = () => {
    if (isHistoryLogs === false) {
      if (
        (searchedUserDetails2 !== "" ||
          referenceId !== "" ||
          searchedStatus !== "") &&
        isfilteredHistory
      ) {
        setFilteredHistoryCount((previous: any) => {
          return previous + 10;
        });
      } else {
        setHistoryLogsCount((previous: any) => {
          return previous + 10;
        });
      }
    } else if (isUploadReport) {
      setUploadLogsCount((previous: any) => {
        return previous + 10;
      })
    } else {
      if (
        (searchedUserDetails !== "" || searchedCreatedAt !== "") &&
        isfilteredError
      ) {
        setFilteredErrCount((previous: any) => {
          return previous + 10;
        });
      } else {
        setErrLogsCount((previous: any) => {
          return previous + 10;
        });
      }
    }
  };

  const fetchFilteredHistoryLogsAPIData = () => {

    let config = {
      method: 'get',
      url: `${apiEndpoint}/filterErrorLog?lastKey=&userDetails=${searchedUserDetails}&createdAt=${dateStringToTimestamp(searchedCreatedAt)}&job_id=&created_at=`,
    };

    axios.request(config)
      .then((response: any) => {
        if (response?.data?.data?.length > 0 || response?.status === 200) {
          dispatch(
            updatePineDataActions.updateFilteredErrorLogsData({
              filteredErrorLogsData: response.data.data,
            })
          );
        }
      })
      .catch((error: any) => {
        console.log('errLogs filter api error : ', error);
      });

  };

  const fetchFilteredUploadTableLogsData = () => {

    let config = {
      method: 'get',
      url: `${apiEndpoint}/filterUploadJob?userDetails=${searchedUserDetails2}&status=${searchedStatus?.toLowerCase()}&referenceId=${referenceId}&lastKey=&job_id=&created_at=`,
    };

    axios.request(config)
      .then((response: any) => {
        if (response?.data?.data?.length > 0 || response?.status === 200) {
          dispatch(
            updatePineDataActions.updateFilteredHistoryLogsData({
              filteredHistoryLogsData: response.data.data,
            })
          );
        }
      })
      .catch((error: any) => {
        console.log('historyLogs filter api error : ', error);
      });

  };

  const getFilteredResponse = () => {
    if (isHistoryLogs === false) {
      if (searchedUserDetails2 !== '' || referenceId !== '' || searchedStatus !== '') {
        setIsFilteredError(false);
        setIsFilteredHistory(true);
        fetchFilteredUploadTableLogsData();
      }
    } else {
      if (searchedUserDetails !== '' || searchedCreatedAt !== '') {
        setIsFilteredHistory(false);
        setIsFilteredError(true);
        fetchFilteredHistoryLogsAPIData();
      }
    }
  };

  const clearAllStates = () => {
    setSearchedUserDetails("");
    setSearchedCreatedAt("");
    setSearchedUserDetails2("");
    setReferenceId("");
    setSearchedStatus("");
    setFilteredErrCount(10);
    setFilteredHistoryCount(10);
    setIsFilteredError(false);
    setIsFilteredHistory(false);
    dispatch(
      updatePineDataActions.updateFilteredErrorLogsData({
        filteredErrorLogsData: [],
      })
    );
    dispatch(
      updatePineDataActions.updateFilteredHistoryLogsData({
        filteredHistoryLogsData: [],
      })
    );
  }

  const handleRefresh = async () => {
    dispatch(updatePineDataActions.setBackdrop({ backdrop: true }));
    dispatch(
      updatePineDataActions.updateUploadReportData({
        uploadReportData: [],
      })
    );
    uploadLogs.length = 0
    remainingUploadLogs.length = 0
    setUploadLogsCount(10)
    await fetchUploadReportData()
  }

  if (isHistoryLogs === false) {
    loadMoreHistoryLogs();
    loadMoreForFilteredHistoryLogs();
  } else if (isUploadReport) {
    loadMoreUploadLogs();
  } else {
    loadMoreErrLogs();
    loadMoreForFilteredErrLogs();
  }

  useEffect(() => {
    let urlValue = window?.location?.href?.split("/")?.pop();
    if (urlValue === "uploadReport" && !uploadReportFlag) {
      navigate("/pine")
    }
    if (urlValue === "historyLogs") {
      setIsHistoryLogs(false);
      fetchHistoryLogsData();
    } else if (uploadReportFlag && urlValue === "uploadReport") {
      setIsUploadReport(true)
      fetchUploadReportData();
    } else if (urlValue?.includes('viewUploadReport')) {
      setViewUploadReport(true)
      setIsUploadReport(true)
      setjJobIdFromUrl(urlValue.split('jobId=')[1])
      viewUploadReport && fetchUploadReportData('viewUploadReport');
    } else if (urlValue?.includes('viewZipUploadReport')) {
      setViewUploadReport(true)
      setIsUploadReport(true)
      setjJobIdFromUrl(urlValue.split('jobId=')[1])
      viewUploadReport && fetchUploadReportData('viewZipUploadReport');
    } else {
      fetchErrLogsAPIData();
    }
  }, [viewUploadReport, isUploadReport]);

  useEffect(() => {
    loadMoreErrLogs();
  }, [errLogsCount])

  useEffect(() => {
    loadMoreHistoryLogs();
  }, [historyLogsCount])

  useEffect(() => {
    loadMoreUploadLogs();
  }, [uploadLogsCount])

  useEffect(() => {
    loadMoreForFilteredErrLogs();
  }, [filteredErrCount])

  useEffect(() => {
    loadMoreForFilteredHistoryLogs();
  }, [filteredHistoryCount])

  useEffect(() => {
    let fetchedData: any = responseApiData?.data;
    // let fetchedLastKey:any = responseApiData.lastkey;
    if (fetchedData?.length > 0) {
      if (isUploadReport || viewUploadReport) {
        dispatch(
          updatePineDataActions.updateUploadReportData({
            uploadReportData: fetchedData,
          })
        );
      } else if (isHistoryLogs) {
        dispatch(
          updatePineDataActions.updateHistoryLogsData({
            historyLogsData: fetchedData,
          })
        );
      } else {
        dispatch(
          updatePineDataActions.updateUploadTableLogsData({
            uploadTableLogsData: fetchedData,
          })
        );
      }
    }
  }, [responseApiData]);

  return (
    <>
      <Grid style={{ width: "100%" }} paddingX={4}>
        <TitleText
          isHistoryLogs={isHistoryLogs}
          isUploadReport={isUploadReport}
          handleRefresh={handleRefresh}
        />
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          marginTop={1}
          columnSpacing={2}
          rowSpacing={1}
          rowGap={1}
          paddingBottom={2}
        >
          {isUploadReport ? (
            <>
            </>
          ) : isHistoryLogs ? (
            <HistoryLogGrid
              searchedUserDetails={searchedUserDetails}
              searchedCreatedAt={searchedCreatedAt}
              setSearchedUserDetails={setSearchedUserDetails}
              setSearchedCreatedAt={setSearchedCreatedAt}
            />
          ) : (
            <ErrorLogGrid
              searchedUserDetails2={searchedUserDetails2}
              searchedStatus={searchedStatus}
              referenceId={referenceId}
              setSearchedUserDetails2={setSearchedUserDetails2}
              setReferenceId={setReferenceId}
              setSearchedStatus={setSearchedStatus}
            />
          )}
          {!isUploadReport && (
            <ButtonsWithGrid
              getFilteredResponse={getFilteredResponse}
              clearAllStates={clearAllStates}
            />)}
        </Grid>
        <TableWrapper
          isHistoryLogs={isHistoryLogs}
          isUploadReport={isUploadReport}
          rows={
            isUploadReport ? uploadLogs :
              isHistoryLogs
                ? (filteredErrLogs?.length > 0 || isfilteredError)
                  ? filteredErrLogs
                  : errLogs
                : (filteredHistoryLogs?.length > 0 || isfilteredHistory)
                  ? filteredHistoryLogs
                  : historyLogs
          }
          columns={
            isUploadReport ? UploadReportColumns : isHistoryLogs ? historyErrTableColumns : historyUploadTableColumns
          }
        />
        <Compare
          isHistoryLogs={isHistoryLogs}
          isUploadReport={isUploadReport}
          isfilteredHistory={isfilteredHistory}
          historyLogs={historyLogs}
          uploadLogs={uploadLogs}
          filteredHistoryLogs={filteredHistoryLogs}
          isfilteredError={isfilteredError}
          errLogs={errLogs}
          filteredErrLogs={filteredErrLogs}
          remainingHistoryLogs={remainingHistoryLogs}
          remainingUploadLogs={remainingUploadLogs}
          remainingFilteredHistoryLogs={remainingFilteredHistoryLogs}
          remainingErrLogs={remainingErrLogs}
          remainingFilteredErrLogs={remainingFilteredErrLogs}
          handleLoadMore={handleLoadMore}
        />
      </Grid>
    </>
  );
};

export default HistoryLogs;
