import { Button } from "@mui/material";

interface Props {
    cancelHandlerWithAbort: any,
    processAllData: any
    cancelButtonClass: any,
    cancelBtn: any
}

const CancelButton = (props: Props) => {
    return (
        <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => {
                props.cancelHandlerWithAbort();
                props.processAllData();
            }}
            className={props.cancelButtonClass}
            sx={{
                color: "#006FA6",
                borderColor: "#006FA6",
                textTransform: "capitalize",
            }}
            data-testid="CancelButton"
        >
            {props.cancelBtn}
        </Button>
    )
}

export default CancelButton