export const PAGE2 = '2 of 3'
export const BACK = 'Back'
export const NEXT = 'Next'
export const UPLOAD_INFO = 'Upload Info'
export const IDENTIFIED = 'Identified files'
export const DRAG_DROP = 'Drag and Drop file here'
export const BROWSE_FILES = 'Browse file'
export const PROCESS_FILE = 'VALIDATE CSV FILE'
export const UPLOAD_FAILED_MSG = 'Upload Failed. Your metadata file must be an xlsx or csv.'
export const REQUIRED_FIELDS = 'Business Unit, File Type, Media Title and File Name fields are required.'
export const XLSX_UPLOADED = 'Your xlsx file has been successfully uploaded'
export const ACCEPTED_FILE_TYPES = 'Accepted file types for bulk upload include Video, Standalone Audio, Thumbnails, Captions (.Vtt, .srt), Audio Descriptor files and Nav.xml.'
export const TRY_AGAIN = 'Please remove it and try again.'
export const MISSING_NON_REQ_FIELDS_WARN_TITLE = 'The following fields have been left blank, is that correct? If so, please continue.'
export const FILE_TYPE_ERR_TITLE = 'Your file lists unsupported file types.'
export const MISSING_EXTENSION_ERR_TITLE = 'On below rows file extension is missing.'
export const DUPLICATE_REF_ID_ERR_TITLE = 'Reference ID is duplicated on below rows : '
export const DUPLICATE_FILENAME_ERR_TITLE = 'Filenames are duplicated on below rows : '
export const REQUIRED_FIELD_ERR_TITLE = `The following fields are required: Business Unit, File Type, Media Title, and File Name.\nYour file is missing the following metadata fields:`
export const METADATA_FILE_ERR_TITLE = 'Please check the content of the file and re-upload.'
export const METADATA_UPLOAD_ERR_TITLE = 'Please validate the metadata and retry.'
export const ALREADY_EXIST_REF_ID_ERR_TITLE = 'Error : Reference ID already exists'
export const FILE_UPLOAD_NAME_CHANGE_TITLE = 'Error : Different name for already updated files.'
export const S3_LOCATION_NOTFOUND_ERR_TITLE = 'Getting error while checking if files are present in S3 bucket.'
export const HTTPS_LOCATION_NOTFOUND_ERR_TITLE = 'Getting error while checking if HTTPS files are present.'
export const INVALID_FILE_UPDATE_ERR_TITLE = 'Please validate the metadata and retry.'
export const DUPLICATE_FILE_ERR_TITLE = 'Error : The items listed below already exists in a different folder. Please update the location or provide a unique Ref ID to add this file to the current folder.'
export const INVALID_PLACEHOLDER_ERR_TITLE = 'Invalid Placeholder value found on below rows.'
export const LOCAL_EXCEEDED_ROWS = 'Error : There is a limit of 100 lines per metadata sheet for uploads using the local pathway.'
export const LOCAL_EXCEED_LIMIT = 100



