import styled from "@emotion/styled";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from "@mui/material";
import { useEffect, useState } from "react";
interface Props {
  tableObjArr: any
  currentPageNo: any
  totalNoOfPages: any
  searchQuery: any
  handleFileProcessing: () => Promise<void>
  getChildValues: any
  searchedDataStartPageNo: any
}

const CustomizedTables = (props: Props) => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: "#DCE4E9",
          color: "black",
          fontSize: "12px",
          fontWeight: "bold",
          width: '30%',
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: "11px",
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
          backgroundColor: "#FFFFFF",
        },
        // hide last border
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }));
      
    const [renderedArray, setRenderedArray] = useState<any[]>([]);
  
    let itemsPerPage = 25;
  
    useEffect(() => {
      props.handleFileProcessing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
  
    useEffect(() => {
      setRenderedArray([]);
      let start = props.currentPageNo * itemsPerPage;
      let end = start + itemsPerPage;
      let fileLength = props.tableObjArr.length;
      if (fileLength <= end) {
        props.getChildValues(start, fileLength);
      } else if (fileLength > end) {
        props.getChildValues(start, end);
      }
  
      let renderedArr = [];
      if (props.searchQuery) {
        // eslint-disable-next-line array-callback-return
        start = props.searchedDataStartPageNo * itemsPerPage;
        end = start + itemsPerPage;
        renderedArr = props.tableObjArr.filter((item: any) => {
          if (
            item?.mediaTitle.toLowerCase().includes(props.searchQuery.toLowerCase()) ||
            item?.fieldName.toLowerCase().includes(props.searchQuery.toLowerCase())
          ) {
            return item;
          }
          return undefined
        });
        if (renderedArr && renderedArr.length > 0) {
          let fileLength = renderedArr.length;
          if (fileLength < end) {
            props.getChildValues(start, fileLength, fileLength);
            renderedArr = renderedArr.slice(start, fileLength);
          } else if (fileLength > end) {
            props.getChildValues(start, end, fileLength);
            renderedArr = renderedArr.slice(start, end);
          }
        } else {
          props.getChildValues(-1, 0, 0);
        }
      } else {
        renderedArr = props?.tableObjArr?.slice(start, end);
      }
      setRenderedArray(renderedArr);
    }, [props.tableObjArr, props.currentPageNo, props.searchQuery, props.searchedDataStartPageNo, itemsPerPage, props]);
  
    return (
    <Grid item sx={{ marginTop: "3%" }}>
      <TableContainer component={Paper} data-testid="CustomizedTables">
        <Table sx={{ minWidth: 200 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Media Title</StyledTableCell>
              <StyledTableCell>File Name</StyledTableCell>
              <StyledTableCell>File Type</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderedArray.map((row: any, index: any) => (
              <StyledTableRow>
                <StyledTableCell key={`${row.mediaTitle}-${index}`} component="th" scope="row">
                  {row.mediaTitle}
                </StyledTableCell>
                <StyledTableCell key={`${row.fieldName}-${index}`}>
                  {row.fieldName}
                </StyledTableCell>
                <StyledTableCell key={`${row.fileType}-${index}`}>
                  {row.fileType}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
    );
  }

export default CustomizedTables