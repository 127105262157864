import { Button } from "@mui/material";
import BackNextText from "../Text/BackNextText";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { takeBack1ButtonStyle } from "../../../../styles/sxStyle";
import { BACK } from "../../constants/AddSingleFileConstants";

interface Props {
    navigateToPreviousPage: () => void
}

const BackButton = (props: Props) => {
    return (
        <Button
            sx={takeBack1ButtonStyle}
            variant="outlined"
            size="small"
            style={{ borderColor: "black" }}
            onClick={() => {
                props.navigateToPreviousPage();
            }}
            data-testid="BackButton"
        >
            <KeyboardArrowLeftIcon
                sx={{
                    fontSize: "15px",
                    marginLeft: "-5px",
                    paddingRight: "3px",
                }}
            />{" "}
            <BackNextText
                text={BACK}
            />
        </Button>
    )
}

export default BackButton