import { Grid, Typography } from "@mui/material"
import { SCREEN_NUMBER_3 } from "../constants/UploadMultipleAssetsContants";

const ScreenNumber = (): JSX.Element => {

    return (
        <Grid item xs={2} data-testid="ScreenNumber">
            <Typography
              sx={{ lineHeight: "1.0", fontWeight: "600" }}
              variant="subtitle2"
            >
              {SCREEN_NUMBER_3}
            </Typography>
          </Grid>
    )
}

export default ScreenNumber