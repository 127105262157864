import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector } from "react-redux";

const PageDecrementButton = (props: any) => {
    const missingCSVFilesData = useSelector(
        (state: any) => state.updatePineData.missingFilesData
    );
    const startValueFromStore = useSelector(
        (state: any) => state.updatePineData.multipleStartValue
    );
    return (
        <IconButton
            data-testid="PageDecrementButton"
            sx={{ padding: "0px" }}
            disabled={props.isMissingBtn && missingCSVFilesData?.length === 0 ? startValueFromStore + 1 === 0 : startValueFromStore + 1 === 1}
            onClick={() => {
                props.currentPageDecrement();
            }}
        >
            <ArrowBackIosIcon
                sx={{ height: "10px", cursor: "pointer" }}
                fontSize="small"
            />
        </IconButton>
    )
}

export default PageDecrementButton