import { Button, Typography } from "@mui/material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { VIEW_IN_PINE } from "../../constants/ShowUploadAssestsConstants"
import { pinePlusEndpoint } from "../../../../constants/appConstant";

const ViewInPineLink = (): JSX.Element => {
    
    return (
        <a
              target="_blank"
              rel="noopener noreferrer"
              href={pinePlusEndpoint}
              style={{ textDecoration: "none" }}
            >
              <Button
                data-testid="ViewInPineLink"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "10px",
                  background: "#006684",
                  height: "30px",
                  color: "#FFFFFF",
                  cursor: "pointer",
                }}
                variant="contained"
                size="small"
              >
                {" "}
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  {VIEW_IN_PINE}
                </Typography>
                <OpenInNewIcon
                  fontSize="small"
                  sx={{
                    fontSize: "16px",
                    marginRight: "-5px",
                    paddingLeft: "5px",
                  }}
                />
              </Button>
            </a>
    )
}

export default ViewInPineLink