import Grid from "@mui/material/Grid"
import { useState, useEffect } from "react"
import CustomDivider from "../atoms/Divider"
import SubHeader from "../molecules/SubHeader"
import { useSelector, useDispatch } from "react-redux"
import CreateFolderGrid from "../molecules/CreateFolderGrid"
import { useLocation, useNavigate } from "react-router-dom"
import { updatePineDataActions } from "../../../store/uploadPineSlice"

const CreatePineFolder = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location: any = useLocation()
  const [showChecked, setShowChecked] = useState(false)
  const selectedLOBValue: any = useSelector((state: any) => state?.updatePineData?.selectedLOBValue)
  const linkedPineFolderValue: any = useSelector((state: any) => state?.updatePineData?.linkedPineFolder)

  useEffect(() => {
    if (selectedLOBValue === "")
      navigate("/pine")
    else if (location?.state?.previousUrl === "/pine/addSingleFile")
      dispatch(updatePineDataActions.setPineFolderLocation(`${linkedPineFolderValue}`))
    else
      dispatch(updatePineDataActions.setPineFolderLocation(`${selectedLOBValue}/`))
    // eslint-disable-next-line
  }, [])
  
  return (
    <Grid container
      data-testid="CreatePineFolder"
      display="flex"
      marginTop="20px"
      justifyContent="center"
      alignItems="center"
      width="500px">

      <SubHeader showChecked={showChecked} />
      <CustomDivider />
      <CreateFolderGrid showChecked={showChecked} setShowChecked={setShowChecked}/>

    </Grid>
  )
}

export default CreatePineFolder
