import { Button, Grid } from "@mui/material";
import { APPLY_FILTER } from "../../constants/HistoryLogConstants";

interface Props {
    getFilteredResponse: () => void,
}

const ApplyFilterButton = (props: Props) => {
    return (
        <Grid
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            md={4}
            item
            data-testid="ApplyFilterButton"
        >
            <Button
                sx={{ width: "fit-content", backgroundColor: "#006684" }}
                variant="contained"
                onClick={() => {
                    props.getFilteredResponse();
                }}
            >
                {APPLY_FILTER}
            </Button>
        </Grid>
    )
}

export default ApplyFilterButton