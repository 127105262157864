/* eslint-disable react-hooks/exhaustive-deps */
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SubHeader from "../molecules/SubHeader";
import UploadAssetGrid from "../molecules/UploadAssetGrid";

const ShowUploadedAssets = () => {
  const linkedPineFolder = useSelector(
    (state: any) => state.updatePineData.linkedPineFolder
  );
  const selectedFileName = useSelector(
    (state: any) => state.updatePineData.singleFileName
  );

const navigate = useNavigate()

  useEffect(() => {
    if (linkedPineFolder === "" || selectedFileName === "") navigate("/pine");
    // if(selectedUploadFromStore === "From Local"){
    //   sendCSVFileData();
    // }
  }, []);

    return (
      <Grid container sx={{ width: "500px", marginTop: "20px" }} data-testid="ShowUploadedAssets">
        <SubHeader/>
        <UploadAssetGrid/>
      </Grid>
    );
};

export default ShowUploadedAssets;
