import { Grid, Typography } from "@mui/material"
const SubText = () : JSX.Element => {
    return(
        <Grid item data-testid="SubText">
          <Typography gutterBottom sx={{ fontSize: "12px" }}>
            Add the path to your folder in Pine where your assets will upload.
          </Typography>
        </Grid>
    )
}
export default SubText