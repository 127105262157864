import { Button } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useSelector } from "react-redux";

const MissingContentPageButton = (props: any) => {
    const missingCSVFilesData = useSelector(
        (state: any) => state.updatePineData.missingFilesData
    );
    return (
        <Button
            sx={{
                marginLeft: "4%",
                fontSize: "13px",
                marginTop: "0%",
                background: props.isMissingBtn ? "#FFDAD9" : "#d7dedf",
                color: "black",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
            }}
            onClick={() => {
                props.enableMissingContentPage();
            }}
            variant="contained"
            size="small"
            data-testid="MissingContentPageButton"
        >
            <ErrorIcon
                fontSize="small"
                sx={{ fontSize: "17.2px", color: "#CB0000" }}
            />
            &nbsp;{`Missing (${missingCSVFilesData.length})`}
        </Button>
    )
}

export default MissingContentPageButton