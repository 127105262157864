import { Grid, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import { ACCEPTED_FILE_TYPES, TRY_AGAIN, XLSX_UPLOADED } from "../constants/AddSingleFileConstants";

const ShowErrMsg = (
    msg: any,
    displayMsg: any = [],
    identifyPopup: any = "",
    errCount: any[] = [],
    increamentErrCountHandler: any = () => { }
) => {
    let combinedErrArr: any[] = [];
    msg?.forEach((item: any, index: any) => {
        combinedErrArr.push({ msg: item });
    })
    displayMsg?.forEach((item: any, index: any) => {
        let dummyObj: any = combinedErrArr[index];
        if (dummyObj !== undefined) {
            dummyObj['displayMsg'] = item;
            combinedErrArr[index] = dummyObj;
        }
    })
    identifyPopup?.forEach((item: any, index: any) => {
        let dummyObj: any = combinedErrArr[index];
        if (dummyObj !== undefined) {
            dummyObj['identifyPopup'] = item;
            combinedErrArr[index] = dummyObj;
        }
    })
    errCount?.forEach((item: any, index: any) => {
        let dummyObj: any = combinedErrArr[index];
        if (dummyObj !== undefined) {
            dummyObj['errCount'] = item;
            combinedErrArr[index] = dummyObj;
        }
    })

    let renderingElementsArr: any[] = [];
    renderingElementsArr = combinedErrArr.map((item: any, index: any) => {
        let errs: any[] = [];
        if (
            !item?.displayMsg?.includes("Your xlsx file has been successfully uploaded") &&
            item?.displayMsg?.length > 0
        ) {
            // eslint-disable-next-line array-callback-return
            errs = item.displayMsg.map((innerItem: any, index: any) => {
                if (index < item?.errCount) {
                    return <li>{innerItem}</li>;
                }
            });
            if (item?.displayMsg?.length > item?.errCount) {
                errs.push(
                    <li style={{ cursor: "pointer" }} onClick={() => increamentErrCountHandler(index)}>
                        ...and {Number(item?.displayMsg?.length - item?.errCount)} more
                    </li>
                );
            }
        }
        return (
            <>
                <Grid
                    item
                    container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        // alignItems: "baseline",
                        marginTop: "8px",
                        flexWrap: "nowrap"
                    }}
                >
                    {" "}
                    {item?.identifyPopup === "checkNonReqFieldsErr" ? (
                        <WarningIcon sx={{ fontSize: "16px", color: "white" , marginTop: "0.3%"}} />
                    ) : (
                        <ErrorIcon sx={{ fontSize: "16px", color: "darkred", marginTop: "0.3%" }} />
                    )}
                    &nbsp;
                    <Typography sx={{ fontSize: "13px", color: "#600000" }} style={{ whiteSpace: "pre-line" }}>
                        {item?.msg}
                    </Typography>
                </Grid>
                {/* {
                    !item.displayMsg.includes(XLSX_UPLOADED) &&
                    item.identifyPopup === "checkReqFieldsErr" &&
                    item.displayMsg.length > 0 && (
                        <Typography
                            sx={{ fontSize: "13px", color: "#600000", paddingLeft: "22px" }}
                        >
                            {REQUIRED_FIELD_ERR_TITLE}
                        </Typography>
                    )
                } */}
                {
                    !item?.displayMsg?.includes(XLSX_UPLOADED) &&
                    item?.identifyPopup === "checkFileTypesErr" &&
                    item?.displayMsg?.length > 0 && (
                        <Typography
                            sx={{ fontSize: "13px", color: "#600000", paddingLeft: "22px" }}
                        >
                            {ACCEPTED_FILE_TYPES}
                        </Typography>
                    )
                }
                {
                    !item?.displayMsg?.includes(XLSX_UPLOADED) &&
                    item?.identifyPopup === "checkFileTypesErr" &&
                    item?.displayMsg.length > 0 && (
                        <Typography
                            sx={{
                                fontSize: "13px",
                                width: "100%",
                                color: "#600000",
                                paddingLeft: "22px",
                            }}
                        >
                            Row which contains invalid file types are listed below.
                        </Typography>
                    )
                }
                {!item?.displayMsg?.includes(XLSX_UPLOADED) &&
                    item?.identifyPopup === "underscoreRefIds" &&
                    item?.displayMsg.length > 0 && (
                        <Typography
                            sx={{ fontSize: "13px", width: "100%", color: "#600000", paddingLeft: "22px" }}
                        >
                            {TRY_AGAIN}
                        </Typography>
                    )}
                {
                    !item?.displayMsg?.includes(XLSX_UPLOADED) &&
                    item?.displayMsg?.length > 0 && (
                        <Typography
                            sx={{
                                fontSize: "13px",
                                color: "#600000",
                            }}
                        >
                            <ul style={{ listStylePosition: "outside", margin: 0 }}>{errs}</ul>
                        </Typography>
                    )
                }
            </>
        )
    })

    return (
        <Grid
            item
            container
            sx={{
                margin: "2% 2% 2% 1%",
                border:
                    identifyPopup === "checkNonReqFieldsErr"
                        ? "solid yellow 1px"
                        : "solid red 1px",
                background:
                    identifyPopup === "checkNonReqFieldsErr" ? "#f9f230" : "#FFDAD9",
                paddingY: "10px",
                paddingX: "10px",
            }}
        >
            {renderingElementsArr}
        </Grid>
    );
};

export default ShowErrMsg