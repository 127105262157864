import { Typography } from "@mui/material"
interface Props {
    text: any
}

const BackNextText = (props: Props) => {
    return (
        <Typography
            sx={{
                textTransform: "capitalize",
                fontSize: "12px",
                fontWeight: "600",
            }}
        >
            {props.text}
        </Typography>
    )
}

export default BackNextText