import { Grid } from "@mui/material"
import ContentPageButtons from "./ContentPageButtons"
import PageToggle from "./PageToggle"

interface Props {
    isMissingBtn: any,
    enableAllContentPage: () => void,
    enableMissingContentPage: () => void,
    currentPageDecrement: () => void,
    currentPageIncrement: () => void
}

const ContentPageBtnGrid = (props: Props) => {
    return (
        <Grid
            item
            container
            direction="row"
            sx={{
                marginTop: "3%",
                position: "relative",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
            data-testid="ContentPageBtnGrid"
        >
            <ContentPageButtons
                enableAllContentPage={props.enableAllContentPage}
                enableMissingContentPage={props.enableMissingContentPage}
                isMissingBtn={props.isMissingBtn}
            />
            <PageToggle
                isMissingBtn={props.isMissingBtn}
                currentPageDecrement={props.currentPageDecrement}
                currentPageIncrement={props.currentPageIncrement}
            />
        </Grid>
    )
}

export default ContentPageBtnGrid