import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import { Button, Typography } from "@mui/material";
import { PLACEHOLDER_FILE_UPLOAD_WARNING, PLACEHOLDER_FILE_UPLOAD_WARNING_MSG } from "../constants/UploadMultipleAssetsContants";

const WarningAlerts = (
    msg: any,
    displayMsg: any[] = [],
    errCount: number = 5,
    increamentCountHandler: any = () => {},
    enablingFileReupload: any = () => {},
    removingErrMsgWindow: any = () => {}
  ) => {
    let errs: any[] = [];
    if (displayMsg.length > 0) {
      // eslint-disable-next-line array-callback-return
      errs = displayMsg.map((item, index) => {
        if (index < errCount) {
          return <li>{item.name}</li>;
        }
      });
      if (displayMsg.length > errCount) {
        errs.push(
          <li style={{ cursor: "pointer" }} onClick={increamentCountHandler}>
            ...and {Number(displayMsg.length - errCount)} more
          </li>
        );
      }
    }
    return (
      <Stack sx={{ width: "100%", padding: '6px' }} spacing={2}>
        <Alert severity="warning">
          {msg}
          {errs}
          {msg === PLACEHOLDER_FILE_UPLOAD_WARNING && PLACEHOLDER_FILE_UPLOAD_WARNING_MSG}
          {msg === `Warning: Would you like to create a new version of these files? If so, click Proceed.` && (
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                color: "#600000",
              }}
            >
            Do you Want to Upload again?
            </Typography>
            <div>
              <Button>
                <Typography onClick={removingErrMsgWindow} sx={{ fontSize: "13px", textTransform: 'capitalize' }}>Cancel</Typography>
              </Button>
              <Button onClick={enablingFileReupload}>
                <Typography sx={{ fontSize: "13px", textTransform: 'capitalize' }}>Proceed</Typography>
              </Button>
            </div>
          </div>
        )}
        </Alert>
      </Stack>
      
    );
  }

  export default WarningAlerts