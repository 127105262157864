import { Grid } from "@mui/material"
import NextPageButton from "../atoms/Buttons/NextPageButton"
import PrevPageButton from "../atoms/Buttons/PrevPageButton"
import ScreenNumber from "../atoms/ScreenNumber"

interface Props {
  selectedFiles?: any,
  setDeleteMissingFilesErr?: any
  signedUrlErr: any
}

const ScreenToggle = (props: Props) => {
  return (
    <Grid
      container
      item
      paddingY={1}
      xs={12}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "space-between",
      }}
      data-testid="ScreenToggle"
    >
      <ScreenNumber />
      <Grid
        item
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        xs={10}
        columnGap={2}
      >
        <PrevPageButton />
        <NextPageButton selectedFiles={props.selectedFiles}
          setDeleteMissingFilesErr={props.setDeleteMissingFilesErr} 
          signedUrlErr={props.signedUrlErr}
        />
      </Grid>
    </Grid>
  )
}

export default ScreenToggle