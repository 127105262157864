import { Button, Grid } from "@mui/material"
import AddNewText from "../AddNewText"

interface Props {
    newLOBValue: any,
    validName: any,
    addNewLOBHandler: any
}

const AddNewButton = (props: Props) => {
    return (
        <Grid
            display="flex"
            flexDirection="row"
            columnGap={4}
            sx={{ marginTop: "23px" }}
          >
            <Button
              sx={{ backgroundColor: "#006684", height: "28px" }}
              disabled={props.newLOBValue === "" || props.validName === false ? true : false}
              size="small"
              variant="contained"
              onClick={props.addNewLOBHandler}
              data-testid="AddNewButton"
            >
              <AddNewText/>
            </Button>
          </Grid>
    )
}

export default AddNewButton