import { Divider, Grid } from "@mui/material"

const CustomDivider = () => {
    return (
        <Grid item xs={12} paddingY={0.5} sx={{ height: "4vh" }} data-testid="CustomDivider">
            <Divider
              sx={{ borderBottomWidth: 1, background: "#000000" }}
              variant="fullWidth"
            />
          </Grid>
    )
}

export default CustomDivider