import { useState } from "react"
import Popover from "@mui/material/Popover"
import ClearIcon from "@mui/icons-material/Clear"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { Box, Grid, IconButton, Typography } from "@mui/material"
import { folderLocationiconPopupStyle } from "../../../styles/sxStyle"

const PopoverGrid = () : JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(null)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : undefined
    
    return (
        <Grid container justifyContent="space-between" data-testid="PopoverGrid">
          
          <Typography gutterBottom sx={{ fontSize: "18px" }}>
            Add Pine Folder Location
          </Typography>

          <Grid item>
            <IconButton disableRipple onMouseEnter={handleClick} onMouseLeave={handleClose}>
              <ErrorOutlineIcon />
            </IconButton>

            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
              anchorOrigin={{ vertical: "bottom",horizontal: "left" }}
              sx={{ pointerEvents: "none" }} >

              <Box sx={folderLocationiconPopupStyle}>

                <Grid container justifyContent="space-between">
                  <Grid item sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Pine Folder Location
                  </Grid>

                  <Grid item sx={{ position: "absolute", right: "4%" }}>
                    <span onClick={handleClose} style={{ cursor: "pointer" }}>
                      <ClearIcon sx={{ fontSize: "20px" }} />
                    </span>
                  </Grid>

                </Grid>

                <Grid item sx={{ fontSize: "12px", marginTop: "3%" }}>
                  To add your media files to a folder named{" "}
                  <b>Pearson Canada</b> inside a folder named <b>HE</b> the path
                  would be : <b>HE/Pearson Canada</b>
                </Grid>

                <Grid item sx={{ fontSize: "12px", marginTop: "3%" }}>
                  You can create a New Folder by adding the desired folder path.
                </Grid>

              </Box>
            </Popover>

          </Grid>
        </Grid>
    )
}

export default PopoverGrid