import { Grid, Typography } from "@mui/material"
import { IDENTIFIED } from "../../constants/AddSingleFileConstants"

const IdentifiedFilesText = () => {
    return (
        <Grid item data-testid ='IdentifiedFilesText'>
            <Typography gutterBottom sx={{ fontSize: "16px" }}>
                {IDENTIFIED}
            </Typography>
        </Grid>
    )
}

export default IdentifiedFilesText