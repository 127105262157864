import { Button } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useSelector } from "react-redux";

const AllContentPageButton = (props: any) => {
    const csvFileDataWithStatus = useSelector((state: any) => state?.updatePineData?.csvFileDataWithStatus);
      
    return (
        <Button
            sx={{
                fontSize: "13px",
                marginTop: "0%",
                background: !props.isMissingBtn ? "#D0E6F2" : "#d7dedf",
                color: "black",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
            }}
            onClick={() => {
                props.enableAllContentPage();
            }}
            variant="contained"
            size="small"
            data-testid="AllContentPageButton"
        >
            <DoneIcon fontSize="small" sx={{ fontSize: "15px" }} />
            &nbsp;{`All (${csvFileDataWithStatus?.length})`}
        </Button>
    )
}
export default AllContentPageButton