import { Grid, Typography, TextField } from "@mui/material";
import { CREATED, USER_DETAIL } from "../constants/HistoryLogConstants";

interface Props {
  searchedUserDetails: any,
  searchedCreatedAt: any,
  setSearchedUserDetails: (event: React.SetStateAction<string>) => void,
  setSearchedCreatedAt: (event: React.SetStateAction<string>) => void
}

const HistoryLogGrid = (props: Props) => {
  return (
    <Grid
      item
      xs={8}
      display="flex"
      justifyContent="row"
      style={{ paddingLeft: "14px" }}
      data-testid="HistoryLogGrid"
    >
      <Grid item xs={8} sm={4} md={2}>
        <Typography variant="body2" fontWeight={600}>
          {USER_DETAIL}
        </Typography>
        <TextField
          value={props.searchedUserDetails}
          sx={{
            width: "100%",
          }}
          onChange={(e) => {
            props.setSearchedUserDetails(e.target.value);
          }}
          size="small"
          inputProps={{ "data-testid": "fromvocabid" }}
        />
      </Grid>
      <Grid item xs={8} sm={4} md={2} style={{ marginLeft: "150px" }}>
        <Typography variant="body2" fontWeight={600}>
          {CREATED}
        </Typography>
        <TextField
          value={props.searchedCreatedAt}
          sx={{
            width: "200px",
          }}
          onChange={(e) => {
            props.setSearchedCreatedAt(e.target.value);
          }}
          size="small"
          inputProps={{ "data-testid": "tovocabid" }}
        />
      </Grid>
    </Grid>
  )
}

export default HistoryLogGrid