import Grid from "@mui/material/Grid"
import ScreenNumber from "../atoms/ScreenNumber"
import BackLink from "../atoms/BackLink"
import NextButton from "../atoms/NextButton"

const SubHeader = (props: any) : JSX.Element => {
    return (
        <Grid container item data-testid="SubHeader" display="flex" flexDirection="row" justifyContent="space-between" xs={12} paddingY={1}>
            <ScreenNumber />
            <Grid item xs={10} display="flex" justifyContent="end" flexDirection="row" columnGap={2}>
                <BackLink />
                <NextButton showChecked={props.showChecked} />
            </Grid>
        </Grid>
    )
}

export default SubHeader