import TagManager  from 'react-gtm-module';

let isGtmInitialized = false
const GTM_ID = 'GTM-PNTGM9KC'
/**
  * @function initializeGTM
  * @description initialise GTM in project
  */
export const initializeGTM = () => {
  const env = getAuthAndPreview()
  const auth = env.auth
  const preview = env.preview
  
  if (auth && preview) {

    const tagManagerArgs = {
      gtmId: GTM_ID,
      auth,
      preview
    }

    TagManager.initialize(tagManagerArgs)
    isGtmInitialized = true
  }
}

/* eslint-disable */
export const triggerCustomEventsGTM = (args) => {
  if(isGtmInitialized){
    window.dataLayer = window.dataLayer ? window.dataLayer : [];
    window.dataLayer.push(args)
  }
}

const getAuthAndPreview = () => {
  let auth
  let preview

  if (window.location.origin.includes('dev')) {
    auth = 'F9MmrQzjjXVnn3EMnt36LA'
    preview = 'env-22'
  } else if (window.location.origin.includes('qa')) {
    auth = '3PyDS1zL8yfA_62YSqljnQ'
    preview = 'env-24'
  } else if (window.location.origin.includes('stg')) {
    auth = 'y_csgfe7XOXqjMb-OixpQg'
    preview = 'env-23'
  } else if (window.location.origin.includes('local')) {
    auth = 'F9MmrQzjjXVnn3EMnt36LA'
    preview = 'env-22'
  } else { // Prod
    auth = '4SrSO6sNcI93oeAi7bPESg'
    preview = 'env-25'
  }

  return { auth, preview }
}