import { Grid, Typography } from "@mui/material"
import { SCREEN_NUMBER_2, SCREEN_NUMBER_3 } from "../constants/ShowUploadAssestsConstants"
import { useSelector } from "react-redux";

const ScreenNumber = (): JSX.Element => {
    const selectedUploadFromStore = useSelector(
        (state: any) => state.updatePineData.selectedUploadFrom
    );
    return (
        <Grid item xs={2} data-testid="ScreenNumber">
            {selectedUploadFromStore === "S3 to S3" ? (
                <Typography
                    sx={{ lineHeight: "1.0", fontWeight: "600" }}
                    variant="subtitle2"
                >
                    {SCREEN_NUMBER_2}
                </Typography>
            ) : (
                <Typography
                    sx={{ lineHeight: "1.0", fontWeight: "600" }}
                    variant="subtitle2"
                >
                    {SCREEN_NUMBER_3}
                </Typography>
            )}
        </Grid>
    )
}

export default ScreenNumber