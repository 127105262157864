/* eslint-disable react-hooks/exhaustive-deps */
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { Grid } from "@mui/material";
import Button from '@mui/material/Button'
import unauthorizedSvg from '../../images/icon-unauthorized.svg'
import { logout } from '../../utility/commonFunction';
const Item = styled(Paper)(({ theme }) => ({
    padding: '8px',
    textAlign: 'center'
}))

const styles = {
    gridItem: {
        paddingTop: '10px !important' // Remove padding-top
    }
}

const UnauthorizedPage = () => {
    const headLine = 'Unauthorized Access'
    const buttonDisabled = false

    const handleBackToPine = () => {
        const redirectUrl = `${window.location.origin}/pine`;
        window.localStorage.clear()
        logout(null, null);
        window.location.replace(redirectUrl);
    }

    return (
        <>
            <Grid data-testid="UnauthorizedPage"
                container
                spacing={3}
                direction='column'
                alignItems='center'
                justifyContent='center'
                sx={{
                    minHeight: "100vh"
                }}>
                <Grid item sx={styles.gridItem}>
                    <Item
                        sx={{
                            color: '#005A70',
                            width: '100%',
                            height: '8vh',
                            fontFamily: 'Open Sans',
                            fontSize: 'clamp(2rem, 5vw, 3.5rem)',
                            fontWeight: 700,
                            lineHeight: '10vh',
                            textAlign: 'center',
                            boxShadow: 'none',
                            position: 'relative'
                        }}
                    >
                        {headLine}
                    </Item>
                </Grid>
                <Grid item sx={styles.gridItem}>
                    <img
                        src={unauthorizedSvg}
                        alt='View'
                        style={{
                            width: '100%',
                            height: 'auto',
                            position: 'relative'
                        }}
                    />
                </Grid>
                <Grid item sx={styles.gridItem}>
                    <Button
                        data-testid="unauthorizedBackToPineBtn"
                        variant='contained'
                        sx={{ mt: 2, backgroundColor: '#006684'}}
                        disabled={buttonDisabled}
                        onClick={handleBackToPine}
                    >
                        Back to Pine Bulk Upload
                    </Button>
                </Grid>

            </Grid>

        </>
    );
};

export default UnauthorizedPage;