import { Route, Routes } from 'react-router-dom';
import Parent from '../components/Common/Parent/parent';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../styles/commonStyle';
import { Provider } from 'react-redux';
import store from '../store/store';
import UploadPineTab from '../components/uploadPineTab/uploadPineTab';
import CreatePineFolder from '../components/CreatePineFolder/organisms/CreatePineFolder';
import AddSingleFile from '../components/AddSingleFile/organism/addSingleFile';
import UploadMultipleAssets from '../components/UploadMultipleAssets/uploadMultipleAssets';
import ShowUploadedAssets from '../components/ShowUploadedAssets/organism/showUploadedAssets';
import HistoryLogs from '../components/HistoryLogs/organsim/HistoryLogs';
import '../styles/index.css';
import { IndexPage } from '../components/Common/IndexPage/IndexPage';
import InputForm from "../components/InputForm/organism/InputForm";

function Layout() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Routes>
          <Route path='/' element={<Parent />}>
            <Route index element={<UploadPineTab />} />
          </Route>
          <Route path='/pine' element={<Parent />}>
            <Route index element={<IndexPage />} />
            <Route path='/pine/beginUpload' element={<UploadPineTab />} />
            <Route path='/pine/addPineFolder' element={<CreatePineFolder />} />
            <Route path='/pine/addSingleFile' element={<AddSingleFile />} />
            <Route path='/pine/addMultipleFiles' element={<UploadMultipleAssets />} />
            <Route path='/pine/showUploadedAssets' element={<ShowUploadedAssets />} />
            <Route path='/pine/errorLogs' element={<HistoryLogs />} />
            <Route path='/pine/historyLogs' element={<HistoryLogs />} />
            <Route path='/pine/uploadReport' element={<HistoryLogs />} />
            <Route path= '/pine/viewUploadReport' element={<HistoryLogs />} />
            <Route path= '/pine/viewZipUploadReport' element={<HistoryLogs />} />
            <Route path='/pine/inputForm' element={<InputForm />} />
          </Route>
        </Routes>
      </Provider>
    </ThemeProvider>
  );
}

export default Layout;