import ClearIcon from "@mui/icons-material/Clear";
import { Box, Grid } from "@mui/material";
import { acceptedFilesTypeIconPopupStyle } from "../../../styles/sxStyle";
import { ACCEPTED_TYPE } from "../constants/UploadMultipleAssetsContants";

interface Props {
    handleClose: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const FileTypeOptions = (props: Props) => {
    interface Item {
        name: string;
    }
    const typesArray: Item[] = [
        { name: 'Video' },
        { name: 'Standalone Audio' },
        { name: 'Thumbnails' },
        { name: 'Captions(.Vtt)' },
        { name: 'Audio descriptor files' },
        { name: 'Nav.xml' }
    ]
    return (
        <Box sx={acceptedFilesTypeIconPopupStyle}>
            <Grid container sx={{}} data-testid="FileTypeOptions">
                <Grid item sx={{ fontSize: "16px", marginLeft: "1%" }}>
                    {ACCEPTED_TYPE}
                </Grid>
                <Grid
                    item
                    sx={{ position: "absolute", right: "4%", top: "8.5%" }}
                >
                    <span onClick={props.handleClose}>
                        <ClearIcon fontSize="small" />
                    </span>
                </Grid>
            </Grid>
            <Grid item sx={{ fontSize: "12px", marginTop: "3%" }}>
                <ul>
                    {typesArray.map((item, index) => (
                        <li key={index}>{item.name}</li>
                    ))}
                </ul>
            </Grid>
        </Box>
    )
}

export default FileTypeOptions