import { Button, Grid } from "@mui/material";
import { CLEAR } from "../../constants/HistoryLogConstants";

interface Props {
    clearAllStates: () => void
}

const ClearAllButton = (props: Props) => {
    return (
        <Grid
            item
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            md={4}
            data-testid="ClearAllButton"
        >
            <Button
                onClick={() => {
                    props.clearAllStates();
                }}
                sx={{
                    width: "fit-content",
                    color: "#006684",
                    borderColor: "#006684",
                }}
                variant="outlined"
            >
                {CLEAR}
            </Button>
        </Grid>
    )
}

export default ClearAllButton