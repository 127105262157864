import DoneIcon from "@mui/icons-material/Done";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { META_FILE, PINE_FOLDER_LOCATION, SUCCESS, TRANSCODING_PROCESS_INITIATED_MESSAGE, TRANSFER_STARTED_MESSAGE, UPDATE_SUMMARY, UPLOAD_SUCCESS_MESSAGE } from "../constants/ShowUploadAssestsConstants";

const SubText = (): JSX.Element => {
    const selectedUploadFromStore = useSelector(
        (state: any) => state.updatePineData.selectedUploadFrom
    );
    const linkedPineFolder = useSelector(
        (state: any) => state.updatePineData.linkedPineFolder
    );
    const multipleFileCount = useSelector(
        (state: any) => state.updatePineData.multipleFileCount
    );
    const selectedFileName = useSelector(
        (state: any) => state.updatePineData.singleFileName
    );

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/pine/uploadReport');
    };

    return (
        <Grid
            item
            xs={12}
            sx={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "2%",
            }}
            data-testid="SubText"
        >
            <Grid item sx={{ marginLeft: "1%" }}>
                <Typography gutterBottom sx={{ fontSize: "16px" }}>
                    {SUCCESS}
                </Typography>
            </Grid>
            <Grid item sx={{ marginLeft: "1%" }}>
                {(selectedUploadFromStore === "S3 to S3" || selectedUploadFromStore === "From Zip") ? (
                    <Typography gutterBottom sx={{ fontSize: "12px" }}>
                        {TRANSFER_STARTED_MESSAGE}
                        {/* <Link to="/pine/uploadReport"></Link> */}
                        <span onClick={handleNavigate}
                            style={{ textDecoration: "underline", color: '#0000FF', cursor: 'pointer' }}>
                            this report.
                        </span>
                    </Typography>
                ) : (
                    <Typography gutterBottom sx={{ fontSize: "12px" }}>
                        {TRANSCODING_PROCESS_INITIATED_MESSAGE}
                    </Typography>
                )}
            </Grid>
            <Grid item sx={{ marginLeft: "1%" }} paddingTop={1}>
                <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    {PINE_FOLDER_LOCATION}
                </Typography>
                <Grid
                    container
                    columnGap={1}
                    sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                    }}
                >
                    <FolderOpenIcon sx={{ fontSize: "18px" }} />
                    <Typography
                        gutterBottom
                        sx={{
                            fontSize: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "7px",
                        }}
                    >
                        {linkedPineFolder}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item sx={{ marginLeft: "1%" }} paddingTop={1}>
                <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    {META_FILE}
                </Typography>
                <Grid
                    container
                    columnGap={1}
                    sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        paddingTop: "2px",
                    }}
                >
                    <InsertDriveFileIcon sx={{ fontSize: "18px" }} />
                    <Typography
                        gutterBottom
                        sx={{
                            fontSize: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "7px",
                        }}
                    >
                        {selectedFileName}
                    </Typography>
                </Grid>
            </Grid>
             {selectedUploadFromStore === 'From Local' &&
                (
                    <Grid item sx={{ marginLeft: "1%", fontSize: "12px" }} paddingTop={1}>
                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                            {UPDATE_SUMMARY}
                        </Typography>
                        <Grid
                            container
                            columnGap={1}
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }}
                        >
                            <Grid item>
                                <DoneIcon sx={{ fontSize: "20px" }} />
                            </Grid>
                            <Grid item sx={{ padding: "0.6%" }}>
                                {multipleFileCount} {UPLOAD_SUCCESS_MESSAGE}
                            </Grid>
                        </Grid>
                    </Grid>)
            }
        </Grid>
    )
}
export default SubText