import { Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { LOAD_MORE, NO_RESULT } from "../../constants/HistoryLogConstants";

interface Props {
    isHistoryLogs: any,
    isUploadReport:  any,
    isfilteredHistory: any
    historyLogs: any,
    uploadLogs: any,
    filteredHistoryLogs: any,
    isfilteredError: any,
    errLogs: any,
    filteredErrLogs: any,
    remainingHistoryLogs: any,
    remainingUploadLogs: any,
    remainingFilteredHistoryLogs: any,
    remainingErrLogs: any,
    remainingFilteredErrLogs: any,
    handleLoadMore: () => void
}

const Compare = (props: Props) => {
    const backdropValueFromStore = useSelector(
        (state: any) => state.updatePineData.backdrop
    );
    const checkForEmptyTableCondition = () => {
        if(props.isUploadReport === true && props.uploadLogs.length > 0) {
            return false;
        } else if (props.isHistoryLogs === false && props.isfilteredHistory === false) {
            if (props?.historyLogs?.length === 0) {
                return true;
            } else {
                return false;
            }
        } else if (
            props.isHistoryLogs === false &&
            (props?.filteredHistoryLogs?.length > 0 || props.isfilteredHistory)
        ) {
            if (props.filteredHistoryLogs?.length === 0) {
                return true;
            } else {
                return false;
            }
        } else if (props.isHistoryLogs === true && props.isfilteredError === false) {
            if (props?.errLogs?.length === 0) {
                return true;
            } else {
                return false;
            }
        } else if (
            props.isHistoryLogs === true &&
            (props?.filteredErrLogs?.length > 0 || props.isfilteredError)
        ) {
            if (props?.filteredErrLogs?.length === 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const checkForLoadMoreDisable = () => {
        if (props.isUploadReport === true) {
            if (props.remainingUploadLogs?.length === 0) {
                return true;
            } else {
                return false;
            }
        } else if (props.isHistoryLogs === false && props.isfilteredHistory === false) {
            if (props.remainingHistoryLogs?.length === 0) {
                return true;
            } else {
                return false;
            }
        } else if (
            props.isHistoryLogs === false &&
            (props.filteredHistoryLogs?.length > 0 || props.isfilteredHistory)
        ) {
            if (props.remainingFilteredHistoryLogs?.length === 0) {
                return true;
            } else {
                return false;
            }
        } else if (props.isHistoryLogs === true && props.isfilteredError === false) {
            if (props.remainingErrLogs?.length === 0) {
                return true;
            } else {
                return false;
            }
        } else if (
            props.isHistoryLogs === true &&
            (props.filteredErrLogs?.length > 0 || props.isfilteredError)
        ) {
            if (props.remainingFilteredErrLogs?.length === 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const compare = () => {
        if (checkForEmptyTableCondition() && backdropValueFromStore === false) {
            return <h1>{NO_RESULT}</h1>;
        }
        if (!checkForLoadMoreDisable()) {
            return (
                <Button
                    variant="outlined"
                    className="loadMore"
                    style={{ width: "fit-content", borderColor: "#006684", color: "#006684" }}
                    onClick={() => {
                        props.handleLoadMore();
                    }}
                    disabled={checkForLoadMoreDisable() ? true : false}
                >
                    {LOAD_MORE}
                </Button>
            );
        }
    }

    return (
        <Grid display="flex" justifyContent="center" paddingY={5} data-testid="Compare">
            {compare()}
        </Grid>
    )

};

export default Compare