import { Typography } from "@mui/material"

const AvailableLOB = () => {
    return (
        <Typography
            sx={{
              fontSize: "16px",
              textTransform: "capitalize",
              padding: "5px",
              width: "220px",
              marginLeft: "-4px",
              marginTop: "25px",
            }}
            data-testid="AvailableLOB"
          >
            Available Line of Business
          </Typography>
    )
}

export default AvailableLOB