/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiEndpoint } from "../../../constants/appConstant";
import { updatePineDataActions } from "../../../store/uploadPineSlice";
import {
  getAvailableLOBsFromDB,
  getFilesUploadSizeFromDB,
} from "../../../utility/commonFunction";
import AlertPopup from "../../Common/AlertPopup/AlertPopup";
import AvailableLOB from "../atom/AvailableLOBtext";
import AddNewButton from "../atom/Buttons/AddNewButton";
import CustomTextField from "../molecule/CustomTextField";
import SelectLOB from "../molecule/SelectLOB";
import SizeInputField from "../molecule/SizeInputField";
import SubCancel from "../molecule/SubmitCancelButton";

const InputForm = () => {
  const dispatch = useDispatch();
  const [selectedLOBValue, setSelectedLOBValue] = useState("");
  const [newLOBValue, setNewLOBValue] = useState("");
  const [validName, setValidName] = useState(true);
  const [selectedUploadSize, setSelectedUploadSize] = useState<any>(2);
  const [isEdit, setIsEdit] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [totalLOBs, setTotalLOBs] = useState(7);
  const [previousUploadSize, setPreviousUploadSize] = useState(2);
  const allLOBsFromStore = useSelector(
    (state: any) => state.updatePineData.allLOBs
  );

  useEffect(() => {
    getLOBsAndUploadFileSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timeId: any;
    if (showSuccessMsg === true) {
      timeId = setTimeout(() => {
        setShowSuccessMsg(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeId);
    };
  }, [showSuccessMsg]);

  const handleChange = (event: any) => {
    setSelectedLOBValue(event.target.value);
  };

  const getLOBsAndUploadFileSize = async () => {
    let fetchedLOBs: any[] = await getAvailableLOBsFromDB();
    setTotalLOBs(fetchedLOBs?.length)
    dispatch(
      updatePineDataActions.updateAllLOBs({
        allLOBs: [...fetchedLOBs],
      })
    );
    let fetchedUploadSize: any = await getFilesUploadSizeFromDB();
    setPreviousUploadSize(fetchedUploadSize)
    setSelectedUploadSize(fetchedUploadSize);
  };

  const updateLOBsInDB = () => {

    let config = {
      method: "post",
      url: `${apiEndpoint}/updateConfig`,
      headers: {

        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        key: "lob_list",
        value: allLOBsFromStore,
      }),
    };

    axios
      .request(config)
      .then((response) => {
      })
      .catch((error) => {
        console.log("updateConfig LOBS api error : ", error);
      });
  };

  const updateUploadSizeInDB = () => {

    let config = {
      method: "post",
      url: `${apiEndpoint}/updateConfig`,
      headers: {

        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        key: "payload_size",
        value: selectedUploadSize,
      }),
    };

    axios
      .request(config)
      .then((response) => {
      })
      .catch((error) => {
        console.log("updateConfig fileSize api error : ", error);
      });
  };


  const checkForAlreadyPresentLOB = () => {
    let foundLOBIndex: any = allLOBsFromStore?.findIndex((item: any) => {
      return item.toLowerCase() === newLOBValue.toLowerCase();
    });
    if (foundLOBIndex !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const addNewLOBHandler = () => {
    if (newLOBValue !== "" && checkForAlreadyPresentLOB()) {
      setValidName(false);
    } else {
      setValidName(true);
      dispatch(
        updatePineDataActions.updateAllLOBs({
          allLOBs: [...allLOBsFromStore, newLOBValue],
        })
      );
      setNewLOBValue("");
      setShowSuccessMsg(true);
    }
  };

  const resetAll = () => {
    setSelectedLOBValue("");
    setNewLOBValue("");
    setValidName(true);
    setIsEdit(false);
  };

  const checkAnyNewDataAdded = () => {
    if (totalLOBs !== allLOBsFromStore?.length || previousUploadSize !== selectedUploadSize) {
      return true
    } else {
      return false
    }
  }

  const submitHandler = () => {
    if (newLOBValue !== "") {
      addNewLOBHandler();
    }
    setTotalLOBs(allLOBsFromStore?.length);
    setPreviousUploadSize(selectedUploadSize)
    resetAll();
    updateLOBsInDB();
    updateUploadSizeInDB();
    setShowPopup(true);
  };

  return (
    <>
      <AlertPopup open={showPopup} contentText={"Your changes are successfully submitted."} okClick={() => setShowPopup(false)} okBtn="Done" />
      <Grid
        data-testid="InputForm"
        sx={{
          borderRadius: "10px",
          minHeight: "300px",
          minWidth: "550px",
          marginTop: "50px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          padding: "10px",
          backgroundColor: "white",
        }}
      >
        <FormControl sx={{ m: 1, width: "94%" }} size="small">
          <AvailableLOB />
          <Grid
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            columnGap={2}
          >
            <SelectLOB
              selectedLOBValue={selectedLOBValue}
              showSuccessMsg={showSuccessMsg}
              handleChange={handleChange}
            />
            <CustomTextField
              validName={validName}
              newLOBValue={newLOBValue}
              setValidName={setValidName}
              setNewLOBValue={setNewLOBValue}
            />
          </Grid>
          <AddNewButton
            newLOBValue={newLOBValue}
            validName={validName}
            addNewLOBHandler={addNewLOBHandler}
          />
          <SizeInputField
            isEdit={isEdit}
            selectedUploadSize={selectedUploadSize}
            setSelectedUploadSize={setSelectedUploadSize}
            setIsEdit={setIsEdit}
          />
          <SubCancel
            resetAll={resetAll}
            setSelectedUploadSize={setSelectedUploadSize}
            submitHandler={submitHandler}
            checkAnyNewDataAdded={checkAnyNewDataAdded}
            previousUploadSize={previousUploadSize}
          />
        </FormControl>
      </Grid>
    </>
  );
};

export default InputForm;