import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Button,
  Typography,
} from "@mui/material";
import "./AlertPopup.css";

interface AlertDialog {
  iconType?: string;
  divider?: boolean;
  open: boolean;
  title?: string | undefined;
  contentText: string | undefined;
  okBtn?: string;
  cancelBtn?: string;
  okClick?: any;
  resetForm?: any;
  cancelClick?: () => {};
  closeButtonClick?: () => {};
  closeBtn?: boolean;
  cancelButtonClass?: string;
  okayButtonClass?: string;
  cancelVariant?: string;
  okayVariant?: string;
  okayTestId?: string;
  cancelTestId?: string;
  closeTestId?: string;
}

const AlertPopup = ({
  divider,
  contentText,
  title,
  closeBtn,
  closeButtonClick,
  closeTestId,
  cancelBtn,
  cancelTestId,
  cancelVariant = "outlined",
  cancelClick,
  cancelButtonClass,
  open,
  okBtn,
  okClick,
  resetForm,
  okayButtonClass,
  okayTestId,
  okayVariant,
  iconType,
}: AlertDialog) => {
  const renderButton = () => (
    <>
      <Button
        data-testid={okayTestId}
        variant="contained"
        size="medium"
        onClick={okClick}
        className={okayButtonClass}
      >
        {okBtn}
      </Button>
    </>
  );

  return (
    <div data-testid="AlertPopup">
      <Dialog open={open} classes={{ paper: "wrapper" }}>
        {divider === true && <Divider light />}
        <DialogContent>
          <Typography className="contentStyle">
            {contentText}
          </Typography>
        </DialogContent>
        <DialogActions className="buttonStyle">
          {renderButton()}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertPopup;
