import { Button, Typography } from "@mui/material";
interface Props {
  isHistoryLogs: any
  isUploadReport: any
  handleRefresh: () => void
}
const TitleText = (props: Props) => {
  return (
    <Typography
      display="flex"
      variant="h5"
      justifyContent="center"
      marginTop={2}
      data-testid="TitleText"
    >
      {props.isUploadReport ? "Pine S2S Bulk Upload Report" : props.isHistoryLogs ? "Error Logs" : "History Logs"}
      {props.isUploadReport &&
        <Button
          variant="outlined"
          className="loadMore"
          style={{
            width: "fit-content",
            borderColor: "#006684",
            color: "#006684",
            position: 'absolute',
            left: '90%'
          }}
          onClick={()=>{props.handleRefresh();}}
          disabled={false}
        >
          {'REFRESH'}
        </Button>}
    </Typography>
  )
}

export default TitleText